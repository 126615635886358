import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import queryString from "query-string";

class FormularioListaResposta extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);

    this.state = {
      id: null,
      respostas: [],
      nomeForm: "",
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;
    if (id !== undefined) {
      this.load(id);
    } else {
      this.props.history.push("/formularios");
      return false;
    }
  }
  async load(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios-lista-respostas/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let respostas = response.data.respostas;
        let nomeForm = "";
        if (respostas.length) {
          nomeForm = respostas[0].nomeForm;
        }
        this.setState({ respostas, nomeForm, id });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    return this.props.history.push(`/formularios-resposta?id=${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="formularios" />
          <div className="main-panel">
            <NavBar title="Respostas do Formulário" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> {this.state.nomeForm}</h4>
                      <div className="content-btn-novo-item">
                        {" "}
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.props.history.push(
                              `/formularios-responder?id=${this.state.id}`
                            );
                          }}
                        >
                          Responder
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th
                              style={{
                                maxWidth: "165px",
                                width: "165px",
                                minWidth: "165px",
                              }}
                            >
                              ID
                            </th>
                            <th className="text-center">Data Resposta</th>
                            <th className="text-center">Tipo</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.respostas.length > 0 &&
                              this.state.respostas.map((form, key) => (
                                <tr key={key}>
                                  <td
                                    style={{
                                      maxWidth: "165px",
                                      width: "165px",
                                      minWidth: "165px",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {form.id}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {moment(form.dataCadastro).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {form.tipo === 0 ? 'Portal' : 'Paciente/Externo'}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justifyContentCenter">
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-warning btn-round"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/formularios-resposta?id=${form.id}`
                                            );
                                          }}
                                          id={`tooltip-editar-convenios-${form.id}`}
                                        >
                                          <i className="fa fa-eye" style={{color: "white"}}></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar-convenios-${form.id}`}
                                        >
                                          Visualizar
                                        </UncontrolledTooltip>
                                      </div>
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-info btn-round"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/formularios-responder-editar?id=${form.idForm}&resposta=${form.id}`
                                            );
                                          }}
                                          id={`tooltip-editar2-convenios-${form.id}`}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar2-convenios-${form.id}`}
                                        >
                                          Editar Resposta
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioListaResposta;
