import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, CurrencyInput } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { formatarReal } from "../../utils";
import Select from "react-select";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  UncontrolledCollapse,
} from "reactstrap";
import { uniqueId } from "lodash";
import filesize from "filesize";
import Upload from "../../components/Upload";
import FileList from "../../components/FileList";
import FileDownload from "js-file-download";

class AtendimentoVisualizar extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleAba = this.toggleAba.bind(this);
    this.buscarAtendimento = this.buscarAtendimento.bind(this);
    this.handleClickIniciarAtendimento =
      this.handleClickIniciarAtendimento.bind(this);
    this.handleClickFinalizar = this.handleClickFinalizar.bind(this);
    this.buscarMotivosCancelamento = this.buscarMotivosCancelamento.bind(this);
    this.toggleModalCancelar = this.toggleModalCancelar.bind(this);
    this.handleClickCancelar = this.handleClickCancelar.bind(this);
    this.handleConfirmCancelar = this.handleConfirmCancelar.bind(this);
    this.buscarLogsAtendimento = this.buscarLogsAtendimento.bind(this);
    this.buscarFormasPagamento = this.buscarFormasPagamento.bind(this);
    this.toggleModalFormaPagamento = this.toggleModalFormaPagamento.bind(this);
    this.handleClickMarcarComoPago = this.handleClickMarcarComoPago.bind(this);
    this.handleConfirmMarcarComoPago =
      this.handleConfirmMarcarComoPago.bind(this);
    this.buscarAtendimentos = this.buscarAtendimentos.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.processUpload = this.processUpload.bind(this);
    this.buscarArquivos = this.buscarArquivos.bind(this);
    this.toggleModalDeletarArquivo = this.toggleModalDeletarArquivo.bind(this);
    this.handleClickDeletarArquivo = this.handleClickDeletarArquivo.bind(this);
    this.handleConfirmDeletarArquivo =
      this.handleConfirmDeletarArquivo.bind(this);
    this.handleClickBaixarArquivo = this.handleClickBaixarArquivo.bind(this);
    this.handleClickReagendar = this.handleClickReagendar.bind(this);
    this.atualizarObs = this.atualizarObs.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadFormularios = this.loadFormularios.bind(this);
    this.abrirWhatsapp = this.abrirWhatsapp.bind(this);
    this.finalizarAtendimento = this.finalizarAtendimento.bind(this);
    this.toggleModalAvisoPagamento = this.toggleModalAvisoPagamento.bind(this);
    this.handleClickInformarPagamento =
      this.handleClickInformarPagamento.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.transformAtendimentoTemplate =
      this.transformAtendimentoTemplate.bind(this);
    this.toggleModalTemplate = this.toggleModalTemplate.bind(this);
    this.onClickImpressaoTemplate = this.onClickImpressaoTemplate.bind(this);
    this.handleChangeInputModalTemplate =
      this.handleChangeInputModalTemplate.bind(this);
    this.impressaoTemplate = this.impressaoTemplate.bind(this);
    this.handleClickVisualizarRespostaTemplate =
      this.handleClickVisualizarRespostaTemplate.bind(this);
    this.atualizarRecomendacao = this.atualizarRecomendacao.bind(this);
    this.handleChangeInputAtendimento =
      this.handleChangeInputAtendimento.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);
    this.toggleModalEditarValor = this.toggleModalEditarValor.bind(this);
    this.handleClickEditarValor = this.handleClickEditarValor.bind(this);
    this.handleClickSalvarValor = this.handleClickSalvarValor.bind(this);
    this.toggleModalEditarTempo = this.toggleModalEditarTempo.bind(this);
    this.handleClickEditarTempo = this.handleClickEditarTempo.bind(this);
    this.handleClickSalvarTempo = this.handleClickSalvarTempo.bind(this);
    this.gerarLinkFormularioExterno =
      this.gerarLinkFormularioExterno.bind(this);
    this.toggleModalGerarLinkFormulario =
      this.toggleModalGerarLinkFormulario.bind(this);
    this.onClickAbrirModalGerarLinkFormulario =
      this.onClickAbrirModalGerarLinkFormulario.bind(this);

    this.state = {
      abaAtiva: "dados", // dados, historicoPaciente, logsAtendimento, arquivos, formularios, templates
      idAtendimento: "",
      id: null,
      atendimento: null,
      motivosCancelamento: [],
      motivosCancelamentoSelecionado: null,
      modalCancelar: false,
      errors: {
        motivoCancelamento: false,
        formaPagamento: false,
      },
      logsAtendimento: [],
      formasPagamento: [],
      formaPagamentoSelecionada: null,
      modalFormaPagamento: false,
      atendimentosConsultante: [],
      uploadedFiles: [],
      arquivos: [],
      idArquivoDeletar: null,
      modalDeletarArquivo: false,
      tema: props.getTema(),
      obsAtendimento: "",
      desconto: "0,00",
      acrescimo: "0,00",
      formularios: [],
      modalAvisoPagamento: false,
      isFinalizarAtendimentoModalPag: false,
      templates: [],
      atendimentoTemplate: null,
      modalTemplate: false,
      variaveisResponder: [],
      templateSelecionado: null,
      idTemplateRespostaDeletar: null,
      modalDeletar: false,
      valorEditar: 0,
      modalEditarValor: false,
      tempoEditar: "",
      modalEditarTempo: false,
      modalGerarLinkFormulario: false,
      formularioSelecionado: null,
    };
  }

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => {
      let tipoArquivo = file.name.split(".").pop();
      let previewClass = null;

      if (tipoArquivo === "pdf") {
        previewClass = "fa fa-file-pdf-o";
      }

      if (
        tipoArquivo === "xls" ||
        tipoArquivo === "xlt" ||
        tipoArquivo === "xlsx"
      ) {
        previewClass = "fa fa-file-excel-o";
      }
      if (
        tipoArquivo === "doc" ||
        tipoArquivo === "docx" ||
        tipoArquivo === "docm"
      ) {
        previewClass = "fa fa-file-excel-o";
      }

      return {
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        previewClass,
      };
    });

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    let params = {
      onUploadProgress: (e) => {
        const progress = parseInt(Math.round((e.loaded * 100) / e.total));

        this.updateFile(uploadedFile.id, {
          progress,
        });
      },
      headers: null,
    };

    let propsHeaders = this.props.parameters();
    params.headers = propsHeaders.headers;

    axios
      .post(
        `${this.props.getBaseUrlApi()}/arquivos/${this.state.id}`,
        data,
        params
      )
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url,
        });
        this.buscarArquivos();
        this.buscarLogsAtendimento();
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach((file) =>
      URL.revokeObjectURL(file.preview)
    );
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarAtendimento(id);
      await this.buscarArquivos(id);
      this.loadFormularios(id);
      this.loadTemplates(id);
    } else {
      this.props.history.push("/home");
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  toggleAba(abaNova) {
    this.setState({ abaAtiva: abaNova }, async () => {
      if (
        abaNova === "logsAtendimento" &&
        this.state.logsAtendimento.length === 0
      ) {
        await this.buscarLogsAtendimento();
      }
      if (
        abaNova === "historicoPaciente" &&
        this.state.atendimentosConsultante.length === 0
      ) {
        await this.buscarAtendimentos();
      }
    });
  }
  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value });
  }

  async buscarAtendimento(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendimentos/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let atendimento = response.data.atendimento;

        if (atendimento === null) {
          this.props.history.push("/atendimentos");
          return false;
        }

        atendimento.data = atendimento.data.split("T")[0];

        let dataAtendimento = moment(atendimento.data).startOf("day");

        let dataDescritivo = dataAtendimento.isSame(moment().startOf("day"))
          ? `Hoje às ${atendimento.horario}`
          : `${dataAtendimento.format("DD/MM/YYYY")} às ${atendimento.horario}`;

        atendimento.dataDescritivo = dataDescritivo;

        let atendimentoTemplate =
          this.transformAtendimentoTemplate(atendimento);

        this.setState(
          {
            atendimento,
            id,
            idAtendimento: id,
            obsAtendimento: atendimento.obsAtendimento,
            atendimentoTemplate,
          },
          async () => {
            if (atendimento.status === "NOVO") {
              await this.buscarMotivosCancelamento();
            }

            if (atendimento.pago === 0) {
              await this.buscarFormasPagamento();
            }
          }
        );
        //this.setState({ atendimento, id, idAtendimento: '5847' });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleClickIniciarAtendimento() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-status/${this.state.id}`,
        { novoStatus: "ATENDENDO" },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento iniciado com sucesso!");
        await this.buscarAtendimento(this.state.id);
        await this.buscarLogsAtendimento();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleClickFinalizar() {
    let atendimento = this.state.atendimento;

    if (atendimento.pago === 1) {
      this.finalizarAtendimento();
    } else {
      this.toggleModalAvisoPagamento();
    }
  }

  async finalizarAtendimento() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-status/${this.state.id}`,
        { novoStatus: "FINALIZADO" },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento finalizado com sucesso!");
        await this.buscarAtendimento(this.state.id);
        await this.buscarLogsAtendimento();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarMotivosCancelamento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/motivos-cancelamento`,
        this.props.parameters()
      )
      .then(async (response) => {
        let motivosCancelamento = response.data.motivosCancelamento;

        motivosCancelamento = motivosCancelamento.map((motivo) => {
          motivo.value = motivo.id;
          motivo.label = motivo.desc;
          return motivo;
        });
        this.setState({
          motivosCancelamento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalCancelar() {
    this.setState({ modalCancelar: !this.state.modalCancelar });
  }

  handleClickCancelar() {
    this.toggleModalCancelar();
  }

  async handleConfirmCancelar() {
    let motivoCancelamento = this.state.motivosCancelamentoSelecionado;

    if (motivoCancelamento === null) {
      this.notificar("warning", "Selecione o motivo de cancelamento");
      this.setState({
        errors: {
          ...this.state.errors,
          motivoCancelamento: true,
        },
      });
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-status/${this.state.id}`,
        {
          novoStatus: "CANCELADO",
          idMotivoCancelamento: motivoCancelamento.id,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento cancelado");
        this.toggleModalCancelar();
        this.buscarAtendimento(this.state.id);
        await this.buscarLogsAtendimento();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarLogsAtendimento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendimentos-log-status/${
          this.state.id
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let logsAtendimento = response.data.logsAtendimento;

        this.setState({
          logsAtendimento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarFormasPagamento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formas-pagamento`,
        this.props.parameters()
      )
      .then(async (response) => {
        let formasPagamento = response.data.formasPagamento;

        formasPagamento = formasPagamento.map((formaPagamento) => {
          formaPagamento.value = formaPagamento.id;
          formaPagamento.label = formaPagamento.desc;
          return formaPagamento;
        });
        this.setState({
          formasPagamento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalFormaPagamento() {
    this.setState({ modalFormaPagamento: !this.state.modalFormaPagamento });
  }

  handleClickMarcarComoPago() {
    this.toggleModalFormaPagamento();
  }

  async handleConfirmMarcarComoPago() {
    let formaPagamento = this.state.formaPagamentoSelecionada;

    if (formaPagamento === null) {
      this.notificar("warning", "Selecione a forma de pagamento");
      this.setState({
        errors: {
          ...this.state.errors,
          formaPagamento: true,
        },
      });
      return false;
    }

    let desconto = this.state.desconto;
    desconto = desconto.replace("R$", "");
    desconto = desconto.replace(",", ".");
    desconto = parseFloat(desconto);

    if (desconto === 0) {
      desconto = null;
    }

    let acrescimo = this.state.acrescimo;
    acrescimo = acrescimo.replace("R$", "");
    acrescimo = acrescimo.replace(",", ".");
    acrescimo = parseFloat(acrescimo);

    if (acrescimo === 0) {
      acrescimo = null;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-pagamento/${this.state.id}`,
        {
          idFormaPagamento: formaPagamento.id,
          desconto,
          acrescimo,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento marcado como pago");
        this.toggleModalFormaPagamento();
        if (this.state.isFinalizarAtendimentoModalPag) {
          await this.finalizarAtendimento();
        } else {
          this.buscarAtendimento(this.state.id);
          await this.buscarLogsAtendimento();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarAtendimentos() {
    let consultante = this.state.atendimento.idConsultante;

    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendimentos?consultante=${consultante}&ordenar=desc`,

        this.props.parameters()
      )
      .then((response) => {
        let atendimentosConsultante = response.data.atendimentos;

        atendimentosConsultante = atendimentosConsultante.filter(
          (atendimento) => atendimento.id !== this.state.atendimento.id
        );

        this.setState({ atendimentosConsultante });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarArquivos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/arquivos/${this.state.id}`,

        this.props.parameters()
      )
      .then((response) => {
        let arquivos = response.data.arquivos;

        arquivos = arquivos.map((arquivo) => {
          arquivo.tipoClass = "fa fa-file-text";

          if (arquivo.tipo === "pdf") {
            arquivo.tipoClass = "fa fa-file-pdf-o";
          }

          if (
            arquivo.tipo === "xls" ||
            arquivo.tipo === "xlt" ||
            arquivo.tipo === "xlsx"
          ) {
            arquivo.tipoClass = "fa fa-file-excel-o";
          }
          if (
            arquivo.tipo === "doc" ||
            arquivo.tipo === "docx" ||
            arquivo.tipo === "docm"
          ) {
            arquivo.tipoClass = "fa fa-file-excel-o";
          }

          if (
            arquivo.tipo === "jpeg" ||
            arquivo.tipo === "pjpeg" ||
            arquivo.tipo === "png" ||
            arquivo.tipo === "gif"
          ) {
            arquivo.tipoClass = "fa fa-file-image-o";
          }
          return arquivo;
        });

        this.setState({ arquivos });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalDeletarArquivo() {
    this.setState({ modalDeletarArquivo: !this.state.modalDeletarArquivo });
  }

  handleClickDeletarArquivo(idArquivoDeletar) {
    this.setState({ idArquivoDeletar }, () => {
      this.toggleModalDeletarArquivo();
    });
  }

  async handleConfirmDeletarArquivo() {
    let idArquivo = this.state.idArquivoDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/arquivos/${this.state.id}/${idArquivo}`,
        this.props.parameters()
      )
      .then(async (response) => {
        this.setState(
          {
            idArquivoDeletar: null,
          },
          () => {
            this.notificar("success", "Arquivo deletado com sucesso!");
            this.buscarArquivos();
            this.toggleModalDeletarArquivo();
          }
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleClickBaixarArquivo(id, nome) {
    let params = this.props.parameters();
    params.responseType = "blob";

    await axios
      .get(`${this.props.getBaseUrlApi()}/arquivos-download/${id}`, params)
      .then(async (response) => {
        FileDownload(response.data, nome);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickReagendar() {
    this.props.history.push(
      `/atendimentos-novo?consultante=${this.state.atendimento.idConsultante}&atendente=${this.state.atendimento.idAtendente}&atividade=${this.state.atendimento.idAtividade}&convenio=${this.state.atendimento.idConvenio}&atendimento=${this.state.atendimento.id}`
    );
  }

  async atualizarObs() {
    let obs = this.state.obsAtendimento;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-obs/${this.state.id}`,
        { obs },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Descrição do atendimento atualizada!");
        this.buscarAtendimento(this.state.id);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadFormularios(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios-atendimento/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let formularios = response.data.formularios;
        let formularioSelecionado = this.state.formularioSelecionado;
        if (formularioSelecionado !== null) {
          let formFind = formularios.find(
            (it) => it.id === formularioSelecionado.id
          );
          if (formFind !== undefined) {
            formularioSelecionado = formFind;
          }
        }
        this.setState({ formularios, formularioSelecionado });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async loadTemplates(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/templates-atendimento/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templates: response.data.templates });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  abrirWhatsapp(numero) {
    window
      .open(`https://api.whatsapp.com/send?phone=${numero}`, "_blank")
      .focus();
  }

  toggleModalAvisoPagamento() {
    this.setState({ modalAvisoPagamento: !this.state.modalAvisoPagamento });
  }

  handleClickInformarPagamento(informarPagamento) {
    if (informarPagamento) {
      this.setState({ isFinalizarAtendimentoModalPag: true }, () => {
        this.toggleModalAvisoPagamento();
        this.toggleModalFormaPagamento();
      });
    } else {
      this.toggleModalAvisoPagamento();
      this.finalizarAtendimento();
    }
  }

  transformAtendimentoTemplate(atendimento) {
    let dataFinalizacao = "";
    let horaFinalizacao = "";
    let horaInicioAtendimento = "";

    if (atendimento.dataFinalizacao !== null) {
      dataFinalizacao = moment(atendimento.dataFinalizacao).format(
        "DD/MM/YYYY"
      );
      horaFinalizacao = moment(atendimento.dataFinalizacao).format("HH:mm");
    }

    if (atendimento.dataInicioAtendimento !== null) {
      horaInicioAtendimento = moment(atendimento.dataInicioAtendimento).format(
        "HH:mm"
      );
    }

    let obsAtendimento = "";

    if (atendimento.obsAtendimento !== null) {
      obsAtendimento = atendimento.obsAtendimento;
    }

    let dataPagamento = "";

    if (atendimento.dataPagamento !== null) {
      dataPagamento = moment(atendimento.dataPagamento).format("DD/MM/YYYY");
    }

    let convenio = "";

    if (atendimento.descConvenio !== null) {
      convenio = atendimento.descConvenio;
    }

    let atendimentoTemplate = {
      codigo: atendimento.id.toString().padStart(4, "00"),
      dataAtendimento: moment(atendimento.data).format("DD/MM/YYYY"),
      status: atendimento.status,
      pago: atendimento.pago === 1 ? "Sim" : "Não",
      horario: atendimento.horario,
      obsAgendamento: atendimento.obs,
      obsAtendimento,
      valor: `R$ ${formatarReal(atendimento.valorOriginal)}`,
      valorPago: `R$ ${formatarReal(atendimento.valor)}`,
      dataCadastro: moment(atendimento.dataCadastro).format("DD/MM/YYYY"),
      dataFinalizacao,
      horaFinalizacao,
      dataPagamento,
      atendente: atendimento.descAtendente,
      consultante: atendimento.descConsultante,
      cpfConsultante: atendimento.cpfConsultante,
      atividade: atendimento.descAtividade,
      convenio,
      formaPagamento: atendimento.descFormaPagamento,
      dataNascimento: moment(atendimento.dataNascimentoConsultante).format(
        "DD/MM/YYYY"
      ),
      idadeConsultante: atendimento.idadeConsultante,
      horaInicioAtendimento,
      recomendacao: atendimento.recomendacao,
    };

    return atendimentoTemplate;
  }

  toggleModalTemplate() {
    this.setState({ modalTemplate: !this.state.modalTemplate });
  }

  async impressaoTemplate() {
    let atendimentoTemplate = this.state.atendimentoTemplate;
    let variaveisResponder = this.state.variaveisResponder;
    let template = this.state.templateSelecionado;
    let substituicoes = [];

    for (let variavel2 of template.variaveis) {
      if (variavel2.tipo === 0) {
        substituicoes[variavel2.nome] = atendimentoTemplate[variavel2.nome];
      } else {
        let valueFind = variaveisResponder.find(
          (it) => it.label === variavel2.nome
        );

        substituicoes[variavel2.nome] = valueFind.value;
      }
    }

    template.html = template.html.replace(
      /\$\{([^}]+)\}/g,
      (match, variavel) => substituicoes[variavel]
    );

    let templateResposta = {
      idTemplate: template.id,
      idAtendimento: parseInt(this.state.idAtendimento),
      idConsultante: this.state.atendimento.idConsultante,
      html: template.html,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/templates-resposta`,
        { templateResposta },
        this.props.parameters()
      )
      .then(async (response) => {
        this.setState(
          {
            variaveisResponder: [],
            templateSelecionado: null,
            modalTemplate: false,
          },
          () => {
            this.loadTemplates(this.state.idAtendimento);
            localStorage.setItem("html", template.html);
            localStorage.setItem("tituloTemplate", template.titulo);
            window.open("/templates-impressao", "_blank");
          }
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInputModalTemplate(value, label) {
    let variaveisResponder = this.state.variaveisResponder;

    for (let variavel of variaveisResponder) {
      if (variavel.label === label) {
        variavel.value = value;
      }
    }

    this.setState({ variaveisResponder });
  }

  async onClickImpressaoTemplate(template) {
    let variaveis = template.variaveis;
    let variaveisResponder = variaveis.filter((it) => it.tipo === 1);

    let variaveisResponderObj = [];

    for (let variavelResponder of variaveisResponder) {
      variaveisResponderObj.push({
        label: variavelResponder.nome,
        value: "",
      });
    }

    this.setState(
      {
        variaveisResponder: variaveisResponderObj,
        templateSelecionado: template,
      },
      () => {
        if (variaveisResponder.length > 0) {
          this.toggleModalTemplate();
        } else {
          this.impressaoTemplate();
        }
      }
    );
  }

  handleClickVisualizarRespostaTemplate(template) {
    localStorage.setItem("html", template.htmlResposta);
    localStorage.setItem("tituloTemplate", template.titulo);
    window.open("/templates-impressao", "_blank");
  }

  async atualizarRecomendacao() {
    let recomendacao = this.state.atendimento.recomendacao;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-recomendacao/${
          this.state.id
        }`,
        { recomendacao },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Recomendação do atendimento atualizada!");
        this.buscarAtendimento(this.state.id);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInputAtendimento(value, tipo) {
    let atendimento = this.state.atendimento;
    atendimento[tipo] = value;
    this.setState({ atendimento });
  }

  handleClickDeletar(id) {
    this.setState({ idTemplateRespostaDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  async handleConfirmDeletar() {
    let id = this.state.idTemplateRespostaDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/templates-resposta/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ idTemplateRespostaDeletar: null }, async () => {
          this.toggleModalDeletar();
          await this.loadTemplates(this.state.idAtendimento);
          this.notificar("success", "Resposta deletada com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickEditarValor(valor) {
    valor = valor.toString();
    valor = valor.replace(".", ",");
    this.setState({ valorEditar: valor }, () => {
      this.toggleModalEditarValor();
    });
  }

  toggleModalEditarValor() {
    this.setState({ modalEditarValor: !this.state.modalEditarValor });
  }

  async handleClickSalvarValor() {
    let valor = this.state.valorEditar;

    if (valor === "") {
      this.notificar("warning", "Favor informar um valor!");
      return false;
    }

    valor = valor.toString();
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-pagamento-alterar/${
          this.state.id
        }`,
        {
          valor,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar(
          "success",
          "Valor do atendimento atualizado com sucesso!"
        );
        this.toggleModalEditarValor();
        this.setState({
          atendimento: { ...this.state.atendimento, valorOriginal: valor },
        });
        this.buscarLogsAtendimento();
        this.buscarAtendimento(this.state.id);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickEditarTempo(tempo) {
    let horas = 0;
    let minutos = tempo;

    if (tempo > 59) {
      horas = parseInt(tempo / 60);
      minutos = minutos - horas * 60;
    }

    let horaFormatada = `${horas.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;

    this.setState({ tempoEditar: horaFormatada }, () => {
      this.toggleModalEditarTempo();
    });
  }

  toggleModalEditarTempo() {
    this.setState({ modalEditarTempo: !this.state.modalEditarTempo });
  }

  async handleClickSalvarTempo() {
    let tempo = this.state.tempoEditar;
    tempo = tempo.toString();

    if (tempo === "") {
      this.notificar("warning", "Favor informar um tempo!");
      return false;
    }

    let horas = tempo.split(":")[0];
    let minutos = tempo.split(":")[1];
    horas = parseInt(horas);
    minutos = parseInt(minutos);

    if (horas > 0) {
      minutos += horas * 60;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-tempo-alterar/${
          this.state.id
        }`,
        {
          tempo: minutos,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar(
          "success",
          "Tempo do atendimento atualizado com sucesso!"
        );
        this.toggleModalEditarTempo();
        this.buscarLogsAtendimento();
        this.buscarAtendimento(this.state.id);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async gerarLinkFormularioExterno() {
    let formulario = this.state.formularioSelecionado;
    let idAtendimento = this.state.id;
    let idConsultante = this.state.atendimento.idConsultante;

    let url = window.location.href;
    let urlSemParametros = url.split("?")[0];
    let partesUrl = urlSemParametros.split("/");
    partesUrl.pop(); // Remove a última parte da URL

    let urlFinal = partesUrl.join("/");

    let data = {
      idForm: formulario.id,
      idAtendimento,
      idConsultante,
      url: urlFinal,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-externo`,
        data,
        this.props.parameters()
      )
      .then(async (response) => {
        this.loadFormularios(this.state.id);
        this.notificar("success", "Link gerado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalGerarLinkFormulario() {
    this.setState({
      modalGerarLinkFormulario: !this.state.modalGerarLinkFormulario,
    });
  }

  onClickAbrirModalGerarLinkFormulario(formularioSelecionado) {
    this.setState({ formularioSelecionado }, () => {
      this.toggleModalGerarLinkFormulario();
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atendimentos" />
          <div className="main-panel">
            <NavBar title="Atendimentos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        Detalhes do Atendimento -{" "}
                        {this.state.idAtendimento.toString().padStart(4, "00")}
                      </h5>
                    </div>

                    <div className="row padding-0-15">
                      <div className="col-md-12 pr-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "dados" ? "active" : ""
                              }`}
                              onClick={() => this.toggleAba("dados")}
                              style={{ cursor: "pointer" }}
                            >
                              Dados
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "historicoPaciente"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.toggleAba("historicoPaciente")
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Histórico Paciente
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "logsAtendimento"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("logsAtendimento")}
                              style={{ cursor: "pointer" }}
                            >
                              Logs Atendimento
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "arquivos"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("arquivos")}
                              style={{ cursor: "pointer" }}
                            >
                              Arquivos
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "formularios"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("formularios")}
                              style={{ cursor: "pointer" }}
                            >
                              Formulários
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "templates"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("templates")}
                              style={{ cursor: "pointer" }}
                            >
                              Templates
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {this.state.abaAtiva === "dados" &&
                      this.state.atendimento !== null && (
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-md-5 pr-1">
                                <div className="form-group">
                                  <label>Paciente: &nbsp;</label>
                                  {this.state.atendimento.descConsultante}{" "}
                                  {this.state.atendimento.celularConsultante !==
                                    "" && (
                                    <i
                                      className="fa fa-whatsapp"
                                      style={{
                                        cursor: "pointer",
                                        color: "#47c757",
                                      }}
                                      onClick={() =>
                                        this.abrirWhatsapp(
                                          this.state.atendimento
                                            .celularConsultante
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Idade: &nbsp;</label>
                                  {this.state.atendimento.idadeConsultante}
                                </div>
                              </div>
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>CPF: &nbsp;</label>
                                  {this.state.atendimento.cpfConsultante}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-5 pr-1">
                                <div className="form-group">
                                  <label for="exampleInputEmail1">
                                    Atendente:&nbsp;
                                  </label>
                                  {this.state.atendimento.descAtendente}
                                </div>
                              </div>{" "}
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Atividade: &nbsp;</label>
                                  {this.state.atendimento.descAtividade}
                                </div>
                              </div>
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Data: &nbsp;</label>
                                  {this.state.atendimento.dataDescritivo} <br />
                                  até {
                                    this.state.atendimento.previsaoHoraFinal
                                  }{" "}
                                  (Previsão)&nbsp;&nbsp;
                                  {this.state.atendimento.status !==
                                    "CANCELADO" &&
                                    this.state.atendimento.status !==
                                      "FINALIZADO" && (
                                      <span>
                                        <i
                                          className="fa fa-edit icon-editar"
                                          onClick={() =>
                                            this.handleClickEditarTempo(
                                              this.state.atendimento.tempo
                                            )
                                          }
                                          id="tooltip-marcar-pago22"
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-marcar-pago22`}
                                        >
                                          Editar Tempo
                                        </UncontrolledTooltip>
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-5 pr-1">
                                <div style={{ display: "flex" }}>
                                  <label>Valor: &nbsp;</label>
                                  R${" "}
                                  {formatarReal(
                                    this.state.atendimento.valorOriginal
                                  )}{" "}
                                  &nbsp;
                                  {this.state.atendimento.pago === 0 &&
                                    this.state.atendimento.status !==
                                      "CANCELADO" && (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ margin: "0 3px" }}>
                                          <i
                                            className="fa fa-edit icon-editar"
                                            onClick={() =>
                                              this.handleClickEditarValor(
                                                this.state.atendimento
                                                  .valorOriginal
                                              )
                                            }
                                            id="tooltip-marcar-pago2"
                                          ></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-marcar-pago2`}
                                          >
                                            Editar Valor
                                          </UncontrolledTooltip>
                                        </div>

                                        <div style={{ margin: "0 3px" }}>
                                          <i
                                            className="fa fa-money icon-pagamento"
                                            onClick={() =>
                                              this.handleClickMarcarComoPago()
                                            }
                                            id="tooltip-marcar-pago"
                                          ></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-marcar-pago`}
                                          >
                                            Marcar como pago
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {this.state.atendimento.pago === 1 && (
                                  <div style={{ marginBottom: "20px" }}>
                                    <label>Valor Pago: &nbsp;</label>
                                    R${" "}
                                    {formatarReal(this.state.atendimento.valor)}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Pago: &nbsp;</label>
                                  {this.state.atendimento.pago === 0
                                    ? "Não"
                                    : `Sim (${this.state.atendimento.descFormaPagamento})`}
                                </div>
                              </div>
                              {this.state.atendimento.pago === 1 && (
                                <div className="col-md-3 pr-1">
                                  <div>
                                    <label>Desconto: &nbsp;</label>
                                    {this.state.atendimento.valorDesconto !==
                                    null ? (
                                      <span>
                                        R$&nbsp;
                                        {formatarReal(
                                          this.state.atendimento.valorDesconto
                                        )}
                                      </span>
                                    ) : (
                                      "Não"
                                    )}
                                  </div>
                                  <div>
                                    <label>Acréscimo: &nbsp;</label>
                                    {this.state.atendimento.valorAcrescimo !==
                                    null ? (
                                      <span>
                                        R$&nbsp;
                                        {formatarReal(
                                          this.state.atendimento.valorAcrescimo
                                        )}
                                      </span>
                                    ) : (
                                      "Não"
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-md-5 pr-1">
                                <div className="form-group">
                                  <label>Profissão: &nbsp;</label>
                                  {this.state.atendimento.profissao}
                                </div>
                              </div>
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Escolaridade: &nbsp;</label>
                                  {this.state.atendimento.escolaridade}
                                </div>
                              </div>
                              <div className="col-md-4 pr-1">
                                <div className="form-group">
                                  <label>Religião: &nbsp;</label>
                                  {this.state.atendimento.religiao}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Convênio: &nbsp;</label>
                                  {this.state.atendimento.descConvenio === null
                                    ? "Particular"
                                    : this.state.atendimento.descConvenio}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Status: &nbsp;</label>

                                  <span
                                    style={{
                                      width: "100%",
                                      fontWeight: "bold",
                                      paddingLeft: "3px",
                                    }}
                                  >
                                    <span
                                      className={`tag ${this.state.atendimento.status}`}
                                    >
                                      {this.state.atendimento.status}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3 pr-1">
                                <div className="form-group">
                                  <label>Observação agendamento: &nbsp;</label>
                                  <br />

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        this.state.atendimento.obs.replace(
                                          "\n",
                                          "<br />"
                                        ),
                                    }}
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 pr-1">
                                <div className="form-group">
                                  <label>
                                    Descrição do atendimento: &nbsp;
                                  </label>
                                  <textarea
                                    className="form-control text-area-form"
                                    rows={8}
                                    value={this.state.obsAtendimento}
                                    onChange={(e) =>
                                      this.handleChangeInput(
                                        e.target.value,
                                        "obsAtendimento"
                                      )
                                    }
                                  ></textarea>
                                  {this.state.atendimento.status !==
                                    "CANCELADO" && (
                                    <div className="float-left w-100 text-right">
                                      {/* <small
                                      onClick={this.atualizarObs}
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Salvar Descrição
                                    </small> */}

                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={this.atualizarObs}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Recomendação: &nbsp;</label>
                                  <textarea
                                    className="form-control text-area-form"
                                    rows={8}
                                    value={this.state.atendimento.recomendacao}
                                    onChange={(e) =>
                                      this.handleChangeInputAtendimento(
                                        e.target.value,
                                        "recomendacao"
                                      )
                                    }
                                  ></textarea>
                                  {this.state.atendimento.status !==
                                    "CANCELADO" && (
                                    <div className="float-left w-100 text-right">
                                      {/* <small
                                      onClick={this.atualizarRecomendacao}
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      Salvar Recomendação
                                    </small> */}

                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={this.atualizarRecomendacao}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 update">
                                {this.state.atendimento.status === "NOVO" && (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-round btn-iniciar-atend"
                                      onClick={
                                        this.handleClickIniciarAtendimento
                                      }
                                    >
                                      Iniciar
                                    </button>
                                    {this.state.atendimento.status ===
                                      "NOVO" && (
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-round"
                                        onClick={this.handleClickReagendar}
                                      >
                                        Reagendar
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-round"
                                      onClick={this.handleClickCancelar}
                                    >
                                      Cancelar
                                    </button>
                                  </React.Fragment>
                                )}

                                {this.state.atendimento.status ===
                                  "ATENDENDO" && (
                                  <button
                                    type="button"
                                    className="btn btn-success btn-round btn-finalizar-atend"
                                    onClick={this.handleClickFinalizar}
                                  >
                                    Finalizar Atendimento
                                  </button>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                              <div className="form-group">
                                <label>Atividade</label>
                                <Select
                                  options={this.state.atividades}
                                  value={this.state.atividadeSelecionada}
                                  placeholder="Selecione"
                                  onChange={(item) => {
                                    this.handleChangeInput(
                                      item,
                                      "atividadeSelecionada"
                                    );
                                  }}
                                  className={`${
                                    this.state.errors.atividade
                                      ? "select-error"
                                      : ""
                                  }`}
                                  isDisabled={
                                    this.state.atendenteSelecionado === null
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Data</label>
                                <DatePicker
                                  selected={this.state.data}
                                  withPortal
                                  customInput={
                                    <input className="form-control font-size-13" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.setState({ data: date });
                                  }}
                                  className={`${
                                    this.state.errors.data ? "input-error" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Convênio</label>
                                <Select
                                  options={this.state.convenios}
                                  value={this.state.convenio}
                                  placeholder="Selecione"
                                  onChange={(item) => {
                                    this.handleChangeInput(
                                      item,
                                      "convenioSelecionado"
                                    );
                                  }}
                                  className={`${
                                    this.state.errors.convenio
                                      ? "select-error"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Horário</label>
                                <select
                                  className="form-control form-control-select"
                                  value={this.state.horario}
                                  onChange={(e) =>
                                    this.handleChangeInput(
                                      e.target.value,
                                      "horario"
                                    )
                                  }
                                >
                                  {this.state.horariosDisponiveis.map(
                                    (horario, key) => {
                                      return (
                                        <option value={horario} key={key}>
                                          {horario}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Observação</label>
                                <textarea
                                  className="form-control textarea"
                                  onChange={(e) =>
                                    this.handleChangeInput(
                                      e.target.value,
                                      "obs"
                                    )
                                  }
                                >
                                  {this.state.obs}
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Editar"}
                              </button>
                            </div>
                          </div> */}
                          </form>
                        </div>
                      )}

                    {this.state.atendimento !== null &&
                      this.state.abaAtiva === "historicoPaciente" && (
                        <React.Fragment>
                          <div className="card-body">
                            {this.state.atendimentosConsultante.length ===
                              0 && (
                              <div
                                className="text-center w-100"
                                style={{ float: "left" }}
                              >
                                Este consultante não possuí histórico.
                              </div>
                            )}
                            {this.state.atendimentosConsultante.length > 0 &&
                              this.state.atendimentosConsultante.map(
                                (atendimento, key) => (
                                  <Card>
                                    <CardHeader
                                      id={`toggler-${atendimento.id}`}
                                      style={{
                                        marginBottom: "1rem",
                                        cursor: "pointer",
                                      }}
                                      className="card-header-portal"
                                    >
                                      {atendimento.id
                                        .toString()
                                        .padStart(4, "0")}{" "}
                                      - {atendimento.descAtividade} -{" "}
                                      {atendimento.dataFormatada} às{" "}
                                      {atendimento.horario}
                                    </CardHeader>
                                    <UncontrolledCollapse
                                      toggler={`toggler-${atendimento.id}`}
                                    >
                                      <CardBody className="card-body-portal">
                                        <div className="row">
                                          <div className="col-md-4 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Atendente:&nbsp;
                                              </label>
                                              {atendimento.descAtendente}
                                            </div>
                                          </div>
                                          <div className="col-md-4 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Paciente:&nbsp;
                                              </label>
                                              {atendimento.descConsultante}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Obs Atendimento:&nbsp;
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-3 pr-1">
                                            <div className="form-group">
                                              <label>Status: &nbsp;</label>
                                              <span
                                                style={{
                                                  width: "100%",
                                                  fontWeight: "bold",
                                                  paddingLeft: "3px",
                                                }}
                                              >
                                                <span
                                                  className={`tag ${atendimento.status}`}
                                                >
                                                  {atendimento.status}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </CardBody>
                                      <CardFooter>
                                        <React.Fragment>
                                          <span
                                            onClick={() => {
                                              window.location.href = `${this.props.getDomain()}/atendimentos-visualizar?id=${
                                                atendimento.id
                                              }`;
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Visualizar
                                          </span>
                                        </React.Fragment>
                                      </CardFooter>
                                    </UncontrolledCollapse>
                                  </Card>
                                )
                              )}
                          </div>
                        </React.Fragment>
                      )}

                    {this.state.atendimento !== null &&
                      this.state.abaAtiva === "logsAtendimento" && (
                        <React.Fragment>
                          <div className="card-body">
                            <div className="table-responsive1">
                              <table className="table">
                                <thead
                                  className="text-primary"
                                  ref={(node) => {
                                    if (node) {
                                      node.style.setProperty(
                                        "color",
                                        this.state.tema.textoPrincipal,
                                        "important"
                                      );
                                    }
                                  }}
                                >
                                  <th style={{ borderTop: "none" }}>
                                    Atendente
                                  </th>
                                  <th
                                    style={{
                                      borderTop: "none",
                                    }}
                                  >
                                    Descrição
                                  </th>
                                </thead>
                                <tbody>
                                  {this.state.logsAtendimento.length > 0 &&
                                    this.state.logsAtendimento.map(
                                      (log, key) => (
                                        <tr key={key}>
                                          <td>{log.descAtendente}</td>
                                          <td>{log.obs}</td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                    {this.state.atendimento !== null &&
                      this.state.abaAtiva === "arquivos" && (
                        <React.Fragment>
                          <div className="card-body">
                            <div className="row">
                              {this.state.atendimento.status !== "CANCELADO" ? (
                                <div
                                  className="col-md-5"
                                  style={{ borderRight: "1px solid #6666" }}
                                >
                                  <div className="content-drop">
                                    <Upload onUpload={this.handleUpload} />
                                    {!!this.state.uploadedFiles.length && (
                                      <FileList
                                        files={this.state.uploadedFiles}
                                        onDelete={this.handleDelete}
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              <div
                                className={`${
                                  this.state.atendimento.status !== "CANCELADO"
                                    ? "col-md-7"
                                    : "col-md-12"
                                }`}
                              >
                                <table className="table">
                                  <thead
                                    className="text-primary"
                                    ref={(node) => {
                                      if (node) {
                                        node.style.setProperty(
                                          "color",
                                          this.state.tema.textoPrincipal,
                                          "important"
                                        );
                                      }
                                    }}
                                  >
                                    <th style={{ borderTop: "none" }}>
                                      Arquivo
                                    </th>
                                    <th
                                      className="text-right"
                                      style={{ borderTop: "none" }}
                                    >
                                      Data
                                    </th>
                                    <th
                                      style={{
                                        borderTop: "none",
                                      }}
                                    >
                                      Ação
                                    </th>
                                  </thead>
                                  <tbody>
                                    {this.state.arquivos.length === 0 && (
                                      <td colSpan="3">
                                        <div
                                          className="text-center w-100"
                                          style={{ float: "left" }}
                                        >
                                          Nenhum arquivo neste atendimento.
                                        </div>
                                      </td>
                                    )}

                                    {this.state.arquivos.length > 0 &&
                                      this.state.arquivos.map(
                                        (arquivo, key) => (
                                          <tr key={key}>
                                            <td>
                                              <i
                                                className={arquivo.tipoClass}
                                              ></i>
                                              &nbsp; {arquivo.nomeOriginal}
                                            </td>
                                            <td className="text-right">
                                              {moment(
                                                arquivo.dataCadastro
                                              ).format("DD/MM/YYYY")}{" "}
                                              <br />
                                              {moment(
                                                arquivo.dataCadastro
                                              ).format("HH:mm")}
                                            </td>

                                            <td>
                                              {" "}
                                              <button
                                                className="btn btn-secondary btn-sm"
                                                style={{
                                                  padding: "0.275rem 0.55rem",
                                                  margin: "0 3px",
                                                }}
                                                onClick={() =>
                                                  this.handleClickBaixarArquivo(
                                                    arquivo.id,
                                                    arquivo.nomeOriginal
                                                  )
                                                }
                                                // id={`tooltip-filtro-atendimentos`}
                                                id={`tooltip-baixar-arquivos-${arquivo.id}`}
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`tooltip-baixar-arquivos-${arquivo.id}`}
                                              >
                                                Download
                                              </UncontrolledTooltip>
                                              <button
                                                className="btn btn-danger btn-round"
                                                onClick={() =>
                                                  this.handleClickDeletarArquivo(
                                                    arquivo.id
                                                  )
                                                }
                                                id={`tooltip-deletar-arquivos-${arquivo.id}`}
                                                style={{
                                                  padding: "0.275rem 0.55rem",
                                                  margin: "0 3px",
                                                }}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`tooltip-deletar-arquivos-${arquivo.id}`}
                                              >
                                                Deletar
                                              </UncontrolledTooltip>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* <div className="table-responsive1">
                          
                            </div>{" "} */}
                          </div>
                        </React.Fragment>
                      )}
                    {this.state.atendimento !== null &&
                      this.state.abaAtiva === "formularios" && (
                        <React.Fragment>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead
                                  className="text-primary"
                                  ref={(node) => {
                                    if (node) {
                                      node.style.setProperty(
                                        "color",
                                        this.state.tema.textoPrincipal,
                                        "important"
                                      );
                                    }
                                  }}
                                >
                                  <th style={{ borderTop: "none" }}>ID</th>
                                  <th style={{ borderTop: "none" }}>Nome</th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Data Cadastro
                                  </th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Data Resposta
                                  </th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Tipo Resposta
                                  </th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Ações
                                  </th>
                                </thead>
                                <tbody>
                                  {this.state.formularios.length > 0 &&
                                    this.state.formularios.map((form, key) => (
                                      <tr key={key}>
                                        <td>{form.id}</td>
                                        <td>{form.nome}</td>
                                        <td className="text-center">
                                          {moment(form.dataCadastro).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {form.respondido && (
                                            <span>
                                              {" "}
                                              {moment(form.dataResposta).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </span>
                                          )}
                                          {!form.respondido && (
                                            <span className="text-center">
                                              -
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {form.tipo}
                                          {form.respondido && (
                                            <span>
                                              {" "}
                                              {form.tipoResposta === 0
                                                ? "Portal"
                                                : "Paciente/Externo"}
                                            </span>
                                          )}
                                          {!form.respondido && (
                                            <span className="text-center">
                                              -
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <div className="d-flex justifyContentCenter">
                                            {!form.respondido && (
                                              <React.Fragment>
                                                <div
                                                  style={{ margin: "0 3px" }}
                                                  className="text-center"
                                                >
                                                  <button
                                                    className="btn btn-secondary btn-round"
                                                    onClick={() =>
                                                      this.props.history.push(
                                                        `/formularios-responder?id=${form.id}&atendimento=${this.state.id}&consultante=${this.state.atendimento.idConsultante}`
                                                      )
                                                    }
                                                    id={`tooltip-responder-convenios-${form.id}`}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                  </button>
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={`tooltip-responder-convenios-${form.id}`}
                                                  >
                                                    Responder
                                                  </UncontrolledTooltip>
                                                </div>
                                                <div
                                                  style={{ margin: "0 3px" }}
                                                  className="text-center"
                                                >
                                                  <button
                                                    className="btn btn-success btn-round"
                                                    onClick={() =>
                                                      this.onClickAbrirModalGerarLinkFormulario(
                                                        form
                                                      )
                                                    }
                                                    id={`tooltip-link-formularios-${form.id}`}
                                                  >
                                                    <i className="fa fa-external-link"></i>
                                                  </button>
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={`tooltip-link-formularios-${form.id}`}
                                                  >
                                                    Gerar Link para resposta
                                                    externa
                                                  </UncontrolledTooltip>
                                                </div>
                                              </React.Fragment>
                                            )}
                                            {form.respondido && (
                                              <React.Fragment>
                                                <div
                                                  style={{ margin: "0 3px" }}
                                                  className="text-center"
                                                >
                                                  <button
                                                    className="btn btn-warning btn-round"
                                                    onClick={() => {
                                                      this.props.history.push(
                                                        `/formularios-resposta?id=${form.idResposta}`
                                                      );
                                                    }}
                                                    id={`tooltip-visualizar-convenios-${form.id}`}
                                                    style={{ color: "white" }}
                                                  >
                                                    <i className="fa fa-eye"></i>
                                                  </button>
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={`tooltip-visualizar-convenios-${form.id}`}
                                                  >
                                                    Visualizar Resposta
                                                  </UncontrolledTooltip>
                                                </div>
                                                <div
                                                  style={{ margin: "0 3px" }}
                                                  className="text-center"
                                                >
                                                  <button
                                                    className="btn btn-info btn-round"
                                                    onClick={() =>
                                                      this.props.history.push(
                                                        `/formularios-responder-editar?id=${form.id}&atendimento=${this.state.id}&consultante=${this.state.atendimento.idConsultante}&resposta=${form.idResposta}`
                                                      )
                                                    }
                                                    id={`tooltip-editar-convenios-${form.id}`}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                  </button>
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={`tooltip-editar-convenios-${form.id}`}
                                                  >
                                                    Editar Resposta
                                                  </UncontrolledTooltip>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    {this.state.atendimento !== null &&
                      this.state.abaAtiva === "templates" && (
                        <React.Fragment>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead
                                  className="text-primary"
                                  ref={(node) => {
                                    if (node) {
                                      node.style.setProperty(
                                        "color",
                                        this.state.tema.textoPrincipal,
                                        "important"
                                      );
                                    }
                                  }}
                                >
                                  <th style={{ borderTop: "none" }}>ID</th>
                                  <th style={{ borderTop: "none" }}>Nome</th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Data Cadastro
                                  </th>
                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Data Resposta
                                  </th>

                                  <th
                                    style={{ borderTop: "none" }}
                                    className="text-center"
                                  >
                                    Ações
                                  </th>
                                </thead>
                                <tbody>
                                  {this.state.templates.length > 0 &&
                                    this.state.templates.map(
                                      (template, key) => (
                                        <tr key={key}>
                                          <td>{template.id}</td>
                                          <td>{template.titulo}</td>
                                          <td className="text-center">
                                            {moment(
                                              template.dataCadastro
                                            ).format("DD/MM/YYYY")}
                                          </td>

                                          <td className="text-center">
                                            {template.respondido && (
                                              <span>
                                                {" "}
                                                {moment(
                                                  template.dataResposta
                                                ).format("DD/MM/YYYY HH:mm")}
                                              </span>
                                            )}
                                            {!template.respondido && (
                                              <span className="text-center">
                                                -
                                              </span>
                                            )}
                                          </td>

                                          <td className="text-center">
                                            <div className="d-flex justifyContentCenter">
                                              {!template.respondido && (
                                                <div
                                                  style={{ margin: "0 3px" }}
                                                  className="text-center"
                                                >
                                                  <button
                                                    className="btn btn-secondary btn-round"
                                                    onClick={() => {
                                                      this.onClickImpressaoTemplate(
                                                        template
                                                      );
                                                    }}
                                                    id={`tooltip-responder-convenios2-${template.id}`}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                  </button>
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={`tooltip-responder-convenios2-${template.id}`}
                                                  >
                                                    Impressão
                                                  </UncontrolledTooltip>
                                                </div>
                                              )}
                                              {template.respondido && (
                                                <React.Fragment>
                                                  <div
                                                    style={{ margin: "0 3px" }}
                                                    className="text-center"
                                                  >
                                                    <button
                                                      className="btn btn-warning btn-round"
                                                      onClick={() => {
                                                        this.handleClickVisualizarRespostaTemplate(
                                                          template
                                                        );
                                                      }}
                                                      id={`tooltip-visualizar-convenios3-${template.id}`}
                                                      style={{ color: "white" }}
                                                    >
                                                      <i className="fa fa-eye"></i>
                                                    </button>
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target={`tooltip-visualizar-convenios3-${template.id}`}
                                                    >
                                                      Visualizar Resposta
                                                    </UncontrolledTooltip>
                                                  </div>

                                                  <div
                                                    style={{ margin: "0 3px" }}
                                                  >
                                                    <button
                                                      className="btn btn-danger btn-round"
                                                      onClick={() => {
                                                        this.handleClickDeletar(
                                                          template.idResposta
                                                        );
                                                      }}
                                                      id={`tooltip-deletar-atividade-${template.id}`}
                                                    >
                                                      <i className="fa fa-times"></i>
                                                    </button>
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target={`tooltip-deletar-atividade-${template.id}`}
                                                    >
                                                      Deletar
                                                    </UncontrolledTooltip>
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalCancelar}
          toggle={this.toggleModalCancelar}
        >
          <ModalHeader toggle={this.toggleModalCancelar}>
            Você tem certeza que deseja cancelar?
          </ModalHeader>
          <ModalBody>
            Informe um motivo de cancelamento
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Motivo</label>
                  <Select
                    options={this.state.motivosCancelamento}
                    value={this.state.motivosCancelamentoSelecionado}
                    placeholder="Selecione"
                    onChange={(item) => {
                      this.handleChangeInput(
                        item,
                        "motivosCancelamentoSelecionado"
                      );
                    }}
                    className={`${
                      this.state.errors.motivoCancelamento ? "select-error" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalCancelar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmCancelar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalFormaPagamento}
          toggle={this.toggleModalFormaPagamento}
        >
          <ModalHeader toggle={this.toggleModalFormaPagamento}>
            Marcar como pago
          </ModalHeader>
          <ModalBody>
            {this.state.atendimento !== null && (
              <span className="float-left w-100 text-center">
                Valor Total R${" "}
                {formatarReal(this.state.atendimento.valorOriginal)}
              </span>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Forma de pagamento</label>
                  <Select
                    options={this.state.formasPagamento}
                    value={this.state.formaPagamentoSelecionada}
                    placeholder="Selecione"
                    onChange={(item) => {
                      this.handleChangeInput(item, "formaPagamentoSelecionada");
                    }}
                    className={`${
                      this.state.errors.formaPagamento ? "select-error" : ""
                    }`}
                  />
                </div>
                <div className="form-group">
                  <label>Desconto</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.desconto}
                    onChange={(e) => {
                      this.handleChangeInput(e.target.value, "desconto");
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
                <div className="form-group">
                  <label>Acréscimo</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.acrescimo}
                    onChange={(e) => {
                      this.handleChangeInput(e.target.value, "acrescimo");
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {!this.state.isFinalizarAtendimentoModalPag && (
              <React.Fragment>
                <Button
                  className="btn-modal-recuperar"
                  color="secondary"
                  onClick={this.toggleModalFormaPagamento}
                >
                  Não
                </Button>
                <Button
                  className="btn-modal-recuperar"
                  color="primary"
                  onClick={this.handleConfirmMarcarComoPago}
                >
                  Sim
                </Button>{" "}
              </React.Fragment>
            )}

            {this.state.isFinalizarAtendimentoModalPag && (
              <Button
                className="btn-modal-recuperar"
                color="success"
                onClick={this.handleConfirmMarcarComoPago}
              >
                FINALIZAR
              </Button>
            )}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalDeletarArquivo}
          toggle={this.toggleModalDeletarArquivo}
        >
          <ModalHeader toggle={this.toggleModalDeletarArquivo}>
            Deletar arquivo
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este arquivo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletarArquivo}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletarArquivo}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalAvisoPagamento}
          toggle={this.toggleModalAvisoPagamento}
        >
          <ModalHeader toggle={this.toggleModalAvisoPagamento}>
            Deseja informar a forma de pagamento?
          </ModalHeader>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.handleClickInformarPagamento(false)}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={() => this.handleClickInformarPagamento(true)}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalTemplate}
          toggle={this.toggleModalTemplate}
        >
          <ModalHeader toggle={this.toggleModalTemplate}>
            Impressão de Template
          </ModalHeader>
          <ModalBody>
            {this.state.atendimento !== null && (
              <span className="float-left w-100 text-center">
                Digite um valor para as variáveis
              </span>
            )}
            <div className="row">
              {this.state.variaveisResponder.length > 0 &&
                this.state.variaveisResponder.map((item, key) => (
                  <div className="col-md-12" key={key}>
                    <div className="form-group">
                      <label>{item.label}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={item.value}
                        onChange={(e) =>
                          this.handleChangeInputModalTemplate(
                            e.target.value,
                            item.label
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <Button
                className="btn-modal-recuperar"
                color="secondary"
                onClick={this.toggleModalTemplate}
              >
                Cancelar
              </Button>
              <Button
                className="btn-modal-recuperar"
                color="primary"
                onClick={this.impressaoTemplate}
              >
                Impressão
              </Button>{" "}
            </React.Fragment>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalDeletar}
          toggle={this.toggleModalDeletar}
        >
          <ModalHeader toggle={this.toggleModalDeletar}>
            Deletar Resposta
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar esta resposta?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalEditarValor}
          toggle={this.toggleModalEditarValor}
        >
          <ModalHeader toggle={this.toggleModalEditarValor}>
            Editar Valor
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Valor</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.valorEditar}
                    onChange={(e) => {
                      this.handleChangeInput(e.target.value, "valorEditar");
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalEditarValor}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickSalvarValor}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalEditarTempo}
          toggle={this.toggleModalEditarTempo}
        >
          <ModalHeader toggle={this.toggleModalEditarTempo}>
            Editar Tempo
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Tempo</label>
                  <input
                    type="time"
                    className={`form-control`}
                    placeholder="Tempo"
                    value={this.state.tempoEditar}
                    onChange={(e) =>
                      this.handleChangeInput(e.target.value, "tempoEditar")
                    }
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalEditarTempo}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickSalvarTempo}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {this.state.formularioSelecionado !== null && (
          <Modal
            isOpen={this.state.modalGerarLinkFormulario}
            toggle={this.toggleModalGerarLinkFormulario}
            className="modal-consulta"
          >
            <ModalHeader toggle={this.toggleModalDeletarArquivo}>
              Link Formulário
            </ModalHeader>
            <ModalBody>
              Link para responder externamente o formulário
              <div style={{ paddingTop: "15px" }}>
                URL:{" "}
                {this.state.formularioSelecionado.linkExterno === null && (
                  <span
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    NÃO GERADO
                  </span>
                )}
                {this.state.formularioSelecionado.linkExterno !== null && (
                  <React.Fragment>
                    <a
                      href={this.state.formularioSelecionado.linkExterno}
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        color: "#000",
                      }}
                      target="_blank"
                    >
                      {this.state.formularioSelecionado?.linkExterno}
                    </a>
                    &nbsp;&nbsp;
                    <span>
                      <i
                        id="link-formulario"
                        className="fa fa-clipboard"
                        style={{
                          fontSize: "24px",
                          color: "#d1a111",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            this.state.formularioSelecionado.linkExterno
                          );
                          this.notificar(
                            "success",
                            "Link copiado com sucesso!"
                          );
                        }}
                      />
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target={`link-formulario`}
                    >
                      Copiar Link
                    </UncontrolledTooltip>
                  </React.Fragment>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn-modal-recuperar"
                color="secondary"
                onClick={this.toggleModalGerarLinkFormulario}
              >
                Fechar
              </Button>

              {this.state.formularioSelecionado.linkExterno === null && (
                <Button
                  className="btn-modal-recuperar"
                  color="primary"
                  onClick={this.gerarLinkFormularioExterno}
                >
                  Gerar
                </Button>
              )}
            </ModalFooter>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default AtendimentoVisualizar;
