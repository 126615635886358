import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import { cpfMask, phoneMask, cepMask } from "../../mask";
import queryString from "query-string";
import { validaCpfCnpj, validaEmail } from "../../utils";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

class ConsultanteNovo extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.getDefaultConsultante = this.getDefaultConsultante.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleAba = this.toggleAba.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.buscarEndereco = this.buscarEndereco.bind(this);
    this.buscarConsultante = this.buscarConsultante.bind(this);
    this.handleClickAbrirAtendimento =
      this.handleClickAbrirAtendimento.bind(this);
    this.buscarAtendimentos = this.buscarAtendimentos.bind(this);
    this.validarCadastroInputs = this.validarCadastroInputs.bind(this);
    this.handleChangeDataNascimento =
      this.handleChangeDataNascimento.bind(this);
    this.loadFormularios = this.loadFormularios.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.handleClickVisualizarTemplate =
      this.handleClickVisualizarTemplate.bind(this);

    this.state = {
      id: null,
      consultante: this.getDefaultConsultante(),
      abaAtiva: "dados", // dados, contatoEndereco, historicoPaciente, formularios, templates
      exibirLoadingCep: false,
      actionPage: "CADASTRAR",
      errors: {
        desc: false,
        cpf: false,
        email: false,
        rua: false,
        numero: false,
        bairro: false,
        cidade: false,
        uf: false,
        sexo: false,
        estadoCivil: false,
        cep: false,
      },
      atendimentos: [],
      formularios: [],
      tema: props.getTema(),
      permitirAlterarCpf: false,
      templates: [],
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarConsultante(id);
      await this.buscarAtendimentos(id);
      await this.loadFormularios(id);
      await this.loadTemplates(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  getDefaultConsultante() {
    return {
      desc: "",
      cpf: "",
      telefone: "",
      celular: "",
      email: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "SC",
      sexo: "M", //M, F
      estadoCivil: "Solteiro(a)", //Solteiro(a), Casado(a), Divorciado(a), Viúvo(a)
      cep: "",
      obs: "",
      dataNascimento: moment().toDate(),
      isEstrangeiro: 0,
      pais: "",
      profissao: "",
      escolaridade: "",
      religiao: ""
    };
  }

  handleChangeInput(e, tipo) {
    let consultante = this.state.consultante;
    let value = e.target.value;

    if(tipo === 'isEstrangeiro'){
      value = value === '0' ? 0 : 1;
      consultante.pais = "";
      consultante.uf = "";
    }

    if(consultante.isEstrangeiro === 0){
      if (tipo === "cpf") {
        value = cpfMask(value);
      } else if (tipo === "telefone") {
        value = phoneMask(value);
      } else if (tipo === "celular") {
        value = phoneMask(value);
      } else if (tipo === "cep") {
        value = cepMask(value);
      }
    }
    
    consultante[tipo] = value;
    this.setState({ consultante });
  }

  handleChangeDataNascimento(value) {
    let consultante = this.state.consultante;
    consultante.dataNascimento = value;
    this.setState({ consultante });
  }

  toggleAba(abaNova) {
    this.setState({ abaAtiva: abaNova });
  }

  async handleClickCadastrar() {
    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os campos corretamente!");
      return false;
    }

    if (!(await this.validarCadastroInputs())) {
      return false;
    }

    let consultante = this.state.consultante;

    if (this.state.consultante.cpf !== "" && consultante.isEstrangeiro === 0 && this.state.actionPage === 'CADASTRAR') {
      if (!validaCpfCnpj(this.state.consultante.cpf)) {
        this.notificar("warning", "CPF inválido.");
        return false;
      }
    }

    if (this.state.consultante.email !== "") {
      if (!validaEmail(this.state.consultante.email)) {
        this.notificar("warning", "Email inválido.");
        return false;
      }
    }

    if (this.state.actionPage === "CADASTRAR") {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/consultantes`,
          consultante,
          this.props.parameters()
        )
        .then((response) => {
          this.notificar("success", "Paciente cadastrado com sucesso!");
          this.setState({ consultante: this.getDefaultConsultante() }, () => {
            let urlReturn = queryString.parse(
              this.props.location.search
            ).return;

            if (urlReturn !== undefined) {
              let atendente = queryString.parse(
                this.props.location.search
              ).atendente;

              let atividade = queryString.parse(
                this.props.location.search
              ).atividade;

              let convenio = queryString.parse(
                this.props.location.search
              ).convenio;

              let url = `/atendimentos-novo?consultante=${response.data.id}`;

              if (atendente !== undefined) {
                url = url + `&atendente=${atendente}`;
              }
              if (atividade !== undefined) {
                url = url + `&atividade=${atividade}`;
              }
              if (convenio !== undefined) {
                url = url + `&convenio=${convenio}`;
              }
              return this.props.history.push(url);
            } else {
              setTimeout(() => {
                window.location.href = `/consultantes-visualizar?id=${response.data.id}`;
                return true;
              }, 1500);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    } else {
      await axios
        .put(
          `${this.props.getBaseUrlApi()}/consultantes/${this.state.id}`,
          consultante,
          this.props.parameters()
        )
        .then((response) => {
          this.notificar("success", "Paciente editado com sucesso!");
          this.buscarConsultante(this.state.id);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    }
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.consultante.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    // if (this.state.consultante.email === "") {
    //   errors.email = true;
    //   valido = false;
    // } else {
    //   errors.email = false;
    // }

    // if (this.state.consultante.cpf === "") {
    //   errors.cpf = true;
    //   valido = false;
    // } else {
    //   errors.cpf = false;
    // }

    // if (this.state.consultante.cep === "") {
    //   errors.cep = true;
    //   valido = false;
    // } else {
    //   errors.cep = false;
    // }

    // if (this.state.consultante.rua === "") {
    //   errors.rua = true;
    //   valido = false;
    // } else {
    //   errors.rua = false;
    // }

    // if (this.state.consultante.numero === "") {
    //   errors.numero = true;
    //   valido = false;
    // } else {
    //   errors.numero = false;
    // }

    // if (this.state.consultante.bairro === "") {
    //   errors.bairro = true;
    //   valido = false;
    // } else {
    //   errors.bairro = false;
    // }

    // if (this.state.consultante.cidade === "") {
    //   errors.cidade = true;
    //   valido = false;
    // } else {
    //   errors.cidade = false;
    // }

    this.setState({ errors });
    return valido;
  }

  async buscarEndereco() {
    let cep = this.state.consultante.cep;
    if(this.state.consultante.isEstrangeiro === 1){
      return false;
    }

    if (cep === "") return false;

    cep = cep.replace(".", "");
    cep = cep.replace("-", "");

    if (cep.length < 8) {
      this.notificar("warning", "Cep digitado é inválido");
      return false;
    }

    this.setState({ exibirLoadingCep: true }, async () => {
      await axios
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
          let data = response.data;

          if (data.erro) {
            this.notificar(
              "warning",
              "Não foi possível localizar informação com base no CEP digitado!"
            );
            this.setState({ exibirLoadingCep: false });
            return false;
          }

          let consultante = this.state.consultante;
          consultante.rua = data.logradouro;
          consultante.bairro = data.bairro;
          consultante.cidade = data.localidade;
          consultante.uf = data.uf;

          this.setState({ consultante, exibirLoadingCep: false });
        })
        .catch((error) => {
          this.notificar(
            "warning",
            "Ocorreu um erro ao tentar buscar as informações."
          );
          this.setState({ exibirLoadingCep: false });
        });
    });
  }

  async buscarConsultante(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/consultantes/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let consultante = this.state.consultante;

        let data = response.data.consultante;

        if (data === null) {
          this.props.history.push("/consultantes");
          return false;
        }

        consultante = {
          ...consultante,
          desc: data.desc,
          cpf: data.cpf,
          telefone: data.telefone,
          celular: data.celular,
          email: data.email,
          rua: data.rua,
          numero: data.numero,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.cidade,
          uf: data.uf,
          sexo: data.sexo,
          estadoCivil: data.estadoCivil,
          cep: data.cep,
          obs: data.obs,
          dataNascimento: moment(data.dataNascimento).toDate(),
          isEstrangeiro: data.isEstrangeiro,
          pais: data.pais,
          profissao: data.profissao,
          escolaridade: data.escolaridade,
          religiao: data.religiao          
        };

        let permitirAlterarCpf = this.state.permitirAlterarCpf;

        if (consultante.cpf === "") {
          permitirAlterarCpf = true;
        }

        this.setState({
          consultante,
          actionPage: "EDITAR",
          id,
          permitirAlterarCpf,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickAbrirAtendimento() {
    return this.props.history.push(
      `/atendimentos-novo?consultante=${this.state.id}`
    );
  }

  async buscarAtendimentos(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendimentos?consultante=${id}&ordenar=desc`,

        this.props.parameters()
      )
      .then((response) => {
        let atendimentos = response.data.atendimentos;

        this.setState({ atendimentos });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async validarCadastroInputs() {
    let valido = true;

    let consultante = this.state.consultante;

    if (consultante.desc.length > 50) {
      valido = false;
      this.notificar("warning", "A Descrição deve ter no máximo 50 caracteres");
    }

    if (consultante.telefone.length > 20) {
      valido = false;
      this.notificar("warning", "O Telefone deve ter no máximo 20 caracteres");
    }

    if (consultante.celular.length > 20) {
      valido = false;
      this.notificar("warning", "O Celular deve ter no máximo 20 caracteres");
    }

    if (consultante.email.length > 50) {
      valido = false;
      this.notificar("warning", "O Email deve ter no máximo 50 caracteres");
    }

    if (consultante.rua.length > 100) {
      valido = false;
      this.notificar("warning", "A Rua deve ter no máximo 100 caracteres");
    }

    if (consultante.numero.length > 20) {
      valido = false;
      this.notificar("warning", "A Número deve ter no máximo 20 caracteres");
    }

    if (consultante.complemento.length > 100) {
      valido = false;
      this.notificar(
        "warning",
        "O Complemento deve ter no máximo 100 caracteres"
      );
    }

    if (consultante.bairro.length > 60) {
      valido = false;
      this.notificar("warning", "O Bairro deve ter no máximo 60 caracteres");
    }

    if (consultante.cidade.length > 60) {
      valido = false;
      this.notificar("warning", "A Cidade deve ter no máximo 60 caracteres");
    }

    if (consultante.cep.length > 20) {
      valido = false;
      this.notificar("warning", "O CEP deve ter no máximo 20 caracteres");
    }

    if (consultante.obs.length > 200) {
      valido = false;
      this.notificar(
        "warning",
        "A observação deve ter no máximo 200 caracteres"
      );
    }

    return valido;
  }

  async loadFormularios(idConsultante) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios-consultante/${idConsultante}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ formularios: response.data.respostas });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async loadTemplates(idConsultante) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/templates-consultante/${idConsultante}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templates: response.data.respostas });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickVisualizarTemplate(template) {
    localStorage.setItem("html", template.html);
    localStorage.setItem("tituloTemplate", template.tituloTemplate);
    window.open("/templates-impressao", "_blank");
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="consultantes" />
          <div className="main-panel">
            <NavBar title="Pacientes" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Cadastrar"
                          : "Editar"}{" "}
                        Paciente
                      </h5>
                    </div>

                    <div className="row padding-0-15">
                      <div className="col-md-12 pr-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "dados" ? "active" : ""
                              }`}
                              onClick={() => this.toggleAba("dados")}
                              style={{ cursor: "pointer" }}
                            >
                              Dados
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "contatoEndereco"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("contatoEndereco")}
                              style={{ cursor: "pointer" }}
                            >
                              Contato e Endereço
                            </div>
                          </li>
                          {this.state.actionPage === "EDITAR" && (
                            <React.Fragment>
                              <li class="nav-item">
                                <div
                                  class={`nav-link portal ${
                                    this.state.abaAtiva === "historicoPaciente"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.toggleAba("historicoPaciente")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Histórico Paciente
                                </div>
                              </li>

                              <li class="nav-item">
                                <div
                                  class={`nav-link portal ${
                                    this.state.abaAtiva === "formularios"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => this.toggleAba("formularios")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Formulários
                                </div>
                              </li>
                              <li class="nav-item">
                                <div
                                  class={`nav-link portal ${
                                    this.state.abaAtiva === "templates"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => this.toggleAba("templates")}
                                  style={{ cursor: "pointer" }}
                                >
                                  Templates
                                </div>
                              </li>
                            </React.Fragment>
                          )}
                        </ul>
                      </div>
                    </div>
                    {this.state.abaAtiva === "dados" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-5 pr-1">
                              <div className="form-group">
                                <label>Nome completo</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.desc ? "input-error" : ""
                                  }`}
                                  placeholder="Descrição"
                                  value={this.state.consultante.desc}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "desc")
                                  }
                                  // disabled={
                                  //   this.state.actionPage === "EDITAR"
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                            </div>

                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input
                                  type="email"
                                  className={`form-control ${
                                    this.state.errors.email ? "input-error" : ""
                                  }`}
                                  placeholder="Email"
                                  value={this.state.consultante.email}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "email")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>CPF</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cpf ? "input-error" : ""
                                  }`}
                                  placeholder="CPF"
                                  value={this.state.consultante.cpf}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cpf")
                                  }
                                  disabled={
                                    this.state.actionPage === "EDITAR"
                                      ? this.state.permitirAlterarCpf
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Sexo</label>
                                <select
                                  className={`form-control form-control-select ${
                                    this.state.errors.sexo ? "input-error" : ""
                                  }`}
                                  value={this.state.consultante.sexo}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "sexo")
                                  }
                                  // disabled={
                                  //   this.state.actionPage === "EDITAR"
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  <option value="M">Masculino</option>
                                  <option value="F">Feminino</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Estado civil</label>
                                <select
                                  className={`form-control form-control-select ${
                                    this.state.errors.estadoCivil
                                      ? "input-error"
                                      : ""
                                  }`}
                                  value={this.state.consultante.estadoCivil}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "estadoCivil")
                                  }
                                >
                                  <option value="Solteiro(a)">
                                    Solteiro(a)
                                  </option>
                                  <option value="Casado(a)">Casado(a)</option>
                                  <option value="Divorciado(a)">
                                    Divorciado(a)
                                  </option>
                                  <option value="Viúvo(a)">Viúvo(a)</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Data nascimento</label>
                                <DatePicker
                                  selected={
                                    this.state.consultante.dataNascimento
                                  }
                                  //withPortal
                                  customInput={
                                    <input className="form-control" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.handleChangeDataNascimento(date);
                                  }}
                                  locale="pt-BR"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Paciente Estrangeiro</label>
                                <select
                                  className="form-control form-control-select"
                                  value={this.state.consultante.isEstrangeiro}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "isEstrangeiro")
                                  }
                                >
                                  <option value="0">Não</option>
                                  <option value="1">Sim</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">

                          <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Profissão</label>
                                <input
                                  className={`form-control`}
                                  value={this.state.consultante.profissao}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "profissao")
                                  }
                                />
                              </div>
                            </div>
                          <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Escolaridade</label>
                                <input
                                  className={`form-control`}
                                  value={this.state.consultante.escolaridade}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "escolaridade")
                                  }
                                />
                              </div>
                            </div>
                          <div className="col-md-4">
                              <div className="form-group">
                                <label>Religião</label>
                                <input
                                  className={`form-control`}
                                  value={this.state.consultante.religiao}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "religiao")
                                  }
                                />
                              </div>
                            </div>


                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Observação</label>
                                <textarea
                                  className="form-control textarea"
                                  value={this.state.consultante.obs}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "obs")
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Salvar"}
                              </button>

                              {this.state.actionPage === "EDITAR" && (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-round"
                                  onClick={this.handleClickAbrirAtendimento}
                                >
                                  Abrir Atendimento
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {this.state.abaAtiva === "contatoEndereco" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>CEP</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cep ? "input-error" : ""
                                  }`}
                                  placeholder="CEP"
                                  value={this.state.consultante.cep}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cep")
                                  }
                                  onBlur={this.buscarEndereco}
                                />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "35px",
                                  top: "35px",
                                }}
                              >
                                {this.state.exibirLoadingCep && (
                                  <div
                                    id="loading-bar-spinner"
                                    className="spinner"
                                  >
                                    <div className="spinner-icon"></div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Rua</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.rua ? "input-error" : ""
                                  }`}
                                  placeholder="Rua"
                                  value={this.state.consultante.rua}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "rua")
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Número</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.numero
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Número"
                                  value={this.state.consultante.numero}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "numero")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Complemento</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="Complemento"
                                  value={this.state.consultante.complemento}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "complemento")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Bairro</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.bairro
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Bairro"
                                  value={this.state.consultante.bairro}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "bairro")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Cidade</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cidade
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Cidade"
                                  value={this.state.consultante.cidade}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cidade")
                                  }
                                />
                              </div>
                            </div>
                           {this.state.consultante.isEstrangeiro === 0 && (
                             <div className="col-md-4">
                             <div className="form-group">
                               <label>Estado</label>
                               <select
                                 type="number"
                                 className={`form-control form-control-select ${
                                   this.state.errors.uf ? "input-error" : ""
                                 }`}
                                 value={this.state.consultante.uf}
                                 onChange={(e) =>
                                   this.handleChangeInput(e, "uf")
                                 }
                               >
                                 <option value="AC">AC</option>
                                 <option value="AL">AL</option>
                                 <option value="AP">AP</option>
                                 <option value="AM">AM</option>
                                 <option value="BA">BA</option>
                                 <option value="CE">CE</option>
                                 <option value="DF">DF</option>
                                 <option value="ES">ES</option>
                                 <option value="GO">GO</option>
                                 <option value="MA">MA</option>
                                 <option value="MS">MS</option>
                                 <option value="MT">MT</option>
                                 <option value="MG">MG</option>
                                 <option value="PA">PA</option>
                                 <option value="PB">PB</option>
                                 <option value="PR">PR</option>
                                 <option value="PE">PE</option>
                                 <option value="PI">PI</option>
                                 <option value="RJ">RJ</option>
                                 <option value="RN">RN</option>
                                 <option value="RS">RS</option>
                                 <option value="RO">RO</option>
                                 <option value="RR">RR</option>
                                 <option value="SC">SC</option>
                                 <option value="SP">SP</option>
                                 <option value="SE">SE</option>
                                 <option value="TO">TO</option>
                               </select>
                             </div>
                           </div>
                           )}

                           {this.state.consultante.isEstrangeiro === 1 && (
                             <div className="col-md-4">
                             <div className="form-group">
                               <label>Estado</label>
                               <input
                                 className={`form-control ${
                                   this.state.errors.uf ? "input-error" : ""
                                 }`}
                                 value={this.state.consultante.uf}
                                 onChange={(e) =>
                                   this.handleChangeInput(e, "uf")
                                 }
                               />
                             </div>
                           </div>
                           )}
                          </div>

                          <div className="row">
                            {this.state.consultante.isEstrangeiro === 1 && (
                              <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>País</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="País"
                                  value={this.state.consultante.pais}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "pais")
                                  }
                                />
                              </div>
                            </div>
                            )}
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Telefone</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="Telefone"
                                  value={this.state.consultante.telefone}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "telefone")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Celular</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="Celular"
                                  value={this.state.consultante.celular}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "celular")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "CADASTRAR"
                                  : "SALVAR"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}

                    {this.state.consultante.id !== null &&
                      this.state.abaAtiva === "historicoPaciente" && (
                        <React.Fragment>
                          <div className="card-body">
                            {this.state.atendimentos.length === 0 && (
                              <div
                                className="text-center w-100"
                                style={{ float: "left" }}
                              >
                                Este consultante não possuí histórico.
                              </div>
                            )}
                            {this.state.atendimentos.length > 0 &&
                              this.state.atendimentos.map(
                                (atendimento, key) => (
                                  <Card>
                                    <CardHeader
                                      id={`toggler-${atendimento.id}`}
                                      style={{
                                        marginBottom: "1rem",
                                        cursor: "pointer",
                                      }}
                                      className="card-header-portal"
                                    >
                                      {atendimento.id
                                        .toString()
                                        .padStart(4, "0")}{" "}
                                      - {atendimento.descAtividade} -{" "}
                                      {atendimento.dataFormatada} às{" "}
                                      {atendimento.horario}
                                    </CardHeader>
                                    <UncontrolledCollapse
                                      toggler={`toggler-${atendimento.id}`}
                                    >
                                      <CardBody className="card-body-portal">
                                        <div className="row">
                                          <div className="col-md-4 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Atendente:&nbsp;
                                              </label>
                                              {atendimento.descAtendente}
                                            </div>
                                          </div>
                                          <div className="col-md-4 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Paciente:&nbsp;
                                              </label>
                                              {atendimento.descConsultante}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 pr-1">
                                            <div className="form-group">
                                              <label for="exampleInputEmail1">
                                                Obs Atendimento:&nbsp;
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-3 pr-1">
                                            <div className="form-group">
                                              <label>Status: &nbsp;</label>
                                              <span
                                                style={{
                                                  width: "100%",
                                                  fontWeight: "bold",
                                                  paddingLeft: "3px",
                                                }}
                                              >
                                                <span
                                                  className={`tag ${atendimento.status}`}
                                                >
                                                  {atendimento.status}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </CardBody>
                                      <CardFooter>
                                        <React.Fragment>
                                          <span
                                            onClick={() => {
                                              window.location.href = `${this.props.getDomain()}/atendimentos-visualizar?id=${
                                                atendimento.id
                                              }`;
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Visualizar
                                          </span>
                                        </React.Fragment>
                                      </CardFooter>
                                    </UncontrolledCollapse>
                                  </Card>
                                )
                              )}
                          </div>
                        </React.Fragment>
                      )}

                    {this.state.abaAtiva === "formularios" && (
                      <React.Fragment>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead
                                className="text-primary"
                                ref={(node) => {
                                  if (node) {
                                    node.style.setProperty(
                                      "color",
                                      this.state.tema.textoPrincipal,
                                      "important"
                                    );
                                  }
                                }}
                              >
                                <th style={{ borderTop: "none" }}>ID</th>
                                <th style={{ borderTop: "none" }}>Nome</th>
                                <th
                                  style={{ borderTop: "none" }}
                                  className="text-center"
                                >
                                  Data Resposta
                                </th>
                                <th
                                  style={{ borderTop: "none" }}
                                  className="text-center"
                                >
                                  Tipo Resposta
                                </th>
                                <th
                                  style={{ borderTop: "none" }}
                                  className="text-center"
                                >
                                  Ações
                                </th>
                              </thead>
                              <tbody>
                                {this.state.formularios.length > 0 &&
                                  this.state.formularios.map((form, key) => (
                                    <tr key={key}>
                                      <td>{form.id}</td>
                                      <td>{form.nomeForm}</td>
                                      <td className="text-center">
                                        <span>
                                          {" "}
                                          {moment(form.dataCadastro).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <span>
                                          {form.tipo === 0 ? 'Portal' : 'Paciente/Externo'}
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <div className="d-flex justifyContentCenter">
                                          <div
                                            style={{ margin: "0 3px" }}
                                            className="text-center"
                                          >
                                            <button
                                              className="btn btn-warning btn-round"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/formularios-resposta?id=${form.id}`
                                                );
                                              }}
                                              id={`tooltip-visualizar-convenios-${form.id}`}
                                              style={{ color: "white" }}
                                            >
                                              <i className="fa fa-eye"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`tooltip-visualizar-convenios-${form.id}`}
                                            >
                                              Visualizar Resposta
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{ margin: "0 3px" }}
                                            className="text-center"
                                          >
                                            <button
                                              className="btn btn-secondary btn-round"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/atendimentos-visualizar?id=${form.idAtendimento}`
                                                );
                                              }}
                                              id={`tooltip-visualizar-atendimentos-${form.id}`}
                                              style={{ color: "white" }}
                                            >
                                              <i className="fa fa-briefcase"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`tooltip-visualizar-atendimentos-${form.id}`}
                                            >
                                              Visualizar Atendimento
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{ margin: "0 3px" }}
                                            className="text-center"
                                          >
                                            <button
                                              className="btn btn-info btn-round"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/formularios-responder-editar?id=${form.idForm}&resposta=${form.id}`
                                                );
                                              }}
                                              id={`tooltip-visualizar3-convenios-${form.id}`}
                                              style={{ color: "white" }}
                                            >
                                              <i className="fa fa-edit"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`tooltip-visualizar3-convenios-${form.id}`}
                                            >
                                              Editar Resposta
                                            </UncontrolledTooltip>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {this.state.abaAtiva === "templates" && (
                      <React.Fragment>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead
                                className="text-primary"
                                ref={(node) => {
                                  if (node) {
                                    node.style.setProperty(
                                      "color",
                                      this.state.tema.textoPrincipal,
                                      "important"
                                    );
                                  }
                                }}
                              >
                                <th style={{ borderTop: "none" }}>ID</th>
                                <th style={{ borderTop: "none" }}>Título</th>
                                <th
                                  style={{ borderTop: "none" }}
                                  className="text-center"
                                >
                                  Data Resposta
                                </th>
                                <th
                                  style={{ borderTop: "none" }}
                                  className="text-center"
                                >
                                  Ações
                                </th>
                              </thead>
                              <tbody>
                                {this.state.templates.length > 0 &&
                                  this.state.templates.map((template, key) => (
                                    <tr key={key}>
                                      <td>{template.id}</td>
                                      <td>{template.tituloTemplate}</td>
                                      <td className="text-center">
                                        <span>
                                          {" "}
                                          {moment(template.dataCadastro).format(
                                            "DD/MM/YYYY HH:mm"
                                          )}
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <div className="d-flex justifyContentCenter">
                                          <div
                                            style={{ margin: "0 3px" }}
                                            className="text-center"
                                          >
                                            <button
                                              className="btn btn-warning btn-round"
                                              onClick={() => {
                                                this.handleClickVisualizarTemplate(
                                                  template
                                                );
                                              }}
                                              id={`tooltip-visualizar-convenios2-${template.id}`}
                                              style={{ color: "white" }}
                                            >
                                              <i className="fa fa-eye"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`tooltip-visualizar-convenios2-${template.id}`}
                                            >
                                              Visualizar Resposta
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{ margin: "0 3px" }}
                                            className="text-center"
                                          >
                                            <button
                                              className="btn btn-secondary btn-round"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/atendimentos-visualizar?id=${template.idAtendimento}`
                                                );
                                              }}
                                              id={`tooltip-visualizar-atendimentos3-${template.id}`}
                                              style={{ color: "white" }}
                                            >
                                              <i className="fa fa-briefcase"></i>
                                            </button>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`tooltip-visualizar-atendimentos3-${template.id}`}
                                            >
                                              Visualizar Atendimento
                                            </UncontrolledTooltip>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConsultanteNovo;
