import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import { Doughnut, Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { getListaCores } from "../../utils";

let listaCores = getListaCores();

const dataOptions = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#514C90", "#EF3F5C", "#309A85"],
      hoverBackgroundColor: ["#514C90", "#EF3F5C", "#309A85"],
    },
  ],
};
const dataOptions2 = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
    },
  ],
};
const dataOptions5 = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
    },
  ],
};
const dataOptions6 = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
    },
  ],
};

const dataGrafico3 = {
  labels: [],
  datasets: [
    {
      label: "Valor Total",
      backgroundColor: "#309A85",
      borderColor: "#309A85",
      borderWidth: 1,
      hoverBackgroundColor: "#309A85",
      hoverBorderColor: "#309A85",
      data: [],
    },
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
const dataGrafico4 = {
  labels: [],
  datasets: [
    {
      label: "Valor Total",
      backgroundColor: "#4fb9a4",
      borderColor: "#4fb9a4",
      borderWidth: 1,
      hoverBackgroundColor: "#309A85",
      hoverBorderColor: "#309A85",
      data: [],
    },
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

class Home extends Component {
  constructor() {
    super();
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

    this.state = {
      formasPagamento: [],
      modalDeletar: false,
      idFormaPagamentoDeletar: null,
      data: dataOptions,
      data2: dataOptions2,
      data3: dataGrafico3,
      data4: dataGrafico4,
      data5: dataOptions5,
      data6: dataOptions6,
      dataInicial: new Date(moment().startOf("month").format()),
      dataFinal: new Date(moment().endOf("month").format()),
    };
  }

  async componentDidMount() {
    this.load();
  }
  async load() {
    let dataInicial = moment(this.state.dataInicial).format("YYYY-MM-DD");
    let dataFinal = moment(this.state.dataFinal).format("YYYY-MM-DD");

    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard?datainicial=${dataInicial}&datafinal=${dataFinal}`,
        this.props.parameters()
      )
      .then((response) => {
        ///GRAFICO ATENDIMENTOS
        let graficoAtendimentos = this.state.data;

        graficoAtendimentos.datasets[0].data = [
          response.data.totalizadores.totalAtendimentosPendentes,
          response.data.totalizadores.totalAtendimentosCancelados,
          response.data.totalizadores.totalAtendimentosFinalizados,
        ];
        graficoAtendimentos.labels = [];
        graficoAtendimentos.labels.push(`Pendentes`);
        graficoAtendimentos.labels.push(`Cancelados`);
        graficoAtendimentos.labels.push(`Finalizados`);

        ///GRAFICO ATENDIMENTOS

        ///GRAFICO ATENDIMENTOS

        let graficosMotivos = this.state.data2;
        graficosMotivos.labels = [];
        graficosMotivos.datasets[0].data = [];
        graficosMotivos.datasets[0].backgroundColor = [];
        graficosMotivos.datasets[0].hoverBackgroundColor = [];
        let motivos = response.data.motivos;

        for (let i = 0; i < motivos.length; i++) {
          let motivo = motivos[i];
          graficosMotivos.datasets[0].data.push(motivo.qtd);
          graficosMotivos.datasets[0].backgroundColor.push(listaCores[i]);
          graficosMotivos.datasets[0].hoverBackgroundColor.push(listaCores[i]);

          graficosMotivos.labels.push(`${motivo.desc}`);
        }

        ///GRAFICO ATENDIMENTOS

        let graficoValorAtividades = this.state.data3;

        graficoValorAtividades.labels = [];
        graficoValorAtividades.datasets[0].data = [];

        for (let valorAtividade of response.data.valorAtividades) {
          graficoValorAtividades.labels.push(`${valorAtividade.desc}`);
          graficoValorAtividades.datasets[0].data.push(valorAtividade.qtd);
        }

        ///GRAFICO ATENDIMENTOS

        let graficoValorMeioPagmento = this.state.data4;

        graficoValorMeioPagmento.labels = [];
        graficoValorMeioPagmento.datasets[0].data = [];

        for (let valorMeio of response.data.valorMeiosPagamentos) {
          graficoValorMeioPagmento.labels.push(`${valorMeio.desc}`);
          graficoValorMeioPagmento.datasets[0].data.push(valorMeio.qtd);
        }

        ///GRAFICO ATENDIMENTOS

        let graficoAtendimentosAtividades = this.state.data5;
        graficoAtendimentosAtividades.labels = [];
        graficoAtendimentosAtividades.datasets[0].data = [];
        graficoAtendimentosAtividades.datasets[0].backgroundColor = [];
        graficoAtendimentosAtividades.datasets[0].hoverBackgroundColor = [];

        let atendimentosAtividades = response.data.atendimentosAtividades;

        for (let i = 0; i < atendimentosAtividades.length; i++) {
          let atendimentoAtividade = atendimentosAtividades[i];
          graficoAtendimentosAtividades.datasets[0].data.push(
            atendimentoAtividade.qtd
          );

          graficoAtendimentosAtividades.datasets[0].backgroundColor.push(
            listaCores[i]
          );
          graficoAtendimentosAtividades.datasets[0].hoverBackgroundColor.push(
            listaCores[i]
          );

          graficoAtendimentosAtividades.labels.push(
            `${atendimentoAtividade.desc}`
          );
        }

        ///GRAFICO ATENDIMENTOS

        let graficoAtividadeTempo = this.state.data6;
        graficoAtividadeTempo.labels = [];
        graficoAtividadeTempo.datasets[0].data = [];
        graficoAtividadeTempo.datasets[0].backgroundColor = [];
        graficoAtividadeTempo.datasets[0].hoverBackgroundColor = [];

        let atividadesTempo = response.data.atividadesTempo;

        for (let i = 0; i < atividadesTempo.length; i++) {
          let atividadeTempo = atividadesTempo[i];
          graficoAtividadeTempo.datasets[0].data.push(atividadeTempo.qtd);
          graficoAtividadeTempo.labels.push(`${atividadeTempo.desc}`);
        }

        for (let i = atividadesTempo.length; i > 0; i--) {
          graficoAtividadeTempo.datasets[0].backgroundColor.push(listaCores[i]);
          graficoAtividadeTempo.datasets[0].hoverBackgroundColor.push(
            listaCores[i]
          );
        }

        this.setState({
          data: graficoAtendimentos,
          data2: graficosMotivos,
          data3: graficoValorAtividades,
          data4: graficoValorMeioPagmento,
          data5: graficoAtendimentosAtividades,
          data6: graficoAtividadeTempo,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value }, () => {
      let dataInicial = moment(this.state.dataInicial);
      let dataFinal = moment(this.state.dataFinal);

      if (!dataInicial.isSameOrBefore(dataFinal)) {
        this.notificar("warning", "Data inicial deve ser menor que data final");
        return false;
      } else {
        this.load();
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="dashboard" />
          <div className="main-panel">
            <NavBar title="Home" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      {/* <h4 className="card-title"> Home</h4> */}
                    </div>
                    <div className="card-body d-flex flex-wrap">
                      <div
                        className="float-left w-100 d-flex flex-wrap"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="col-md-12 pr-1">Filtros</div>
                        <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 pr-1">
                          <div className="form-group">
                            <label style={{ display: "block" }}>
                              Data inicial
                            </label>
                            <DatePicker
                              selected={this.state.dataInicial}
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataInicial");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-3 col-xl-2 pr-1">
                          <div className="form-group">
                            <label style={{ display: "block" }}>
                              Data final
                            </label>
                            <DatePicker
                              selected={this.state.dataFinal}
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataFinal");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Atendimentos
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Atendimentos por status
                          </span>
                          <Doughnut
                            data={this.state.data}
                            width={95}
                            height={45}
                            options={{
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Atendimentos
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Motivos de cancelamento
                          </span>
                          <Doughnut
                            data={this.state.data2}
                            width={95}
                            height={45}
                            options={{
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body d-flex flex-wrap">
                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Valor
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Valor total por atividade
                          </span>
                          <div style={{ float: "left", width: "100%" }}>
                            <Bar
                              data={this.state.data3}
                              width={100}
                              height={250}
                              options={{
                                maintainAspectRatio: false,
                                legend: {
                                  position: "bottom",
                                },
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Valor
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Valor por forma de pagamento
                          </span>

                          <div style={{ float: "left", width: "100%" }}>
                            <Bar
                              data={this.state.data4}
                              width={100}
                              height={250}
                              options={{
                                maintainAspectRatio: false,
                                legend: {
                                  position: "bottom",
                                },
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body d-flex flex-wrap">
                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Atividades
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Atendimentos por atividade
                          </span>
                          <Doughnut
                            data={this.state.data5}
                            width={95}
                            height={45}
                            options={{
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-home">
                          <div
                            style={{
                              float: "left",
                              width: "100%",
                              position: "relative",
                              minHeight: "20px",
                            }}
                          >
                            <span
                              className="title-dashboard"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                position: "absolute",
                                fontWeight: "bold",
                              }}
                            >
                              Atividades
                            </span>
                            <div
                              style={{ position: "absolute", right: "10px" }}
                            >
                              {/* <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="."
                                id={`tooltip-grafico_atendimento`}
                              ></i> */}
                            </div>{" "}
                          </div>
                          <span
                            className="subtitle-dashboard"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              paddingBottom: "12px",
                            }}
                          >
                            Tempo por atividade
                          </span>
                          <Doughnut
                            data={this.state.data6}
                            width={95}
                            height={45}
                            options={{
                              legend: {
                                display: true,
                                position: "bottom",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
