import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import moment from "moment";

class FormularioResponderExterno extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.buscarFormulario = this.buscarFormulario.bind(this);
    this.handleChangeInputTexto = this.handleChangeInputTexto.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeInputRadio = this.handleChangeInputRadio.bind(this);
    this.cadastrarResposta = this.cadastrarResposta.bind(this);

    this.state = {
      hash: null,
      formulario: null,
      formularioExterno: null,
      exibirMensagemBaixado: false,
    };
  }

  async componentDidMount() {
    let hash = queryString.parse(this.props.location.search).hash;

    if (hash !== undefined) {
      await this.buscarFormulario(hash);
    } else {
      return false;
    }
  }
  handleChangeInputTexto(idPergunta, e) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas)
      if (pergunta.id === idPergunta) {
        pergunta.resposta.resposta = e.target.value;
      }

    this.setState({ formulario });
  }

  handleCheck(idPergunta, idOpcao) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas) {
      if (pergunta.id === idPergunta) {
        for (let opcao of pergunta.opcoes) {
          if (opcao.id === idOpcao) {
            opcao.selecionado = !opcao.selecionado;
          }
        }
      }
    }

    this.setState({ formulario });
  }

  handleChangeInputRadio(idOpcao, idPergunta) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas) {
      if (pergunta.id === idPergunta) {
        pergunta.resposta = {
          id: Math.random(),
          idOpcao: idOpcao,
          resposta: "",
        };
      }
    }

    this.setState({ formulario });
  }

  async buscarFormulario(hash) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-externo-buscar`,
        { hash },
        this.props.parameters()
      )
      .then((response) => {
        let formulario = response.data.formulario;
        let formularioExterno = response.data.formularioExterno;

        if (formularioExterno.status === "B") {
          this.setState({ exibirMensagemBaixado: true });
        }

        for (let pergunta of formulario.perguntas) {
          let respostaNova = null;

          if (pergunta.tipo === 0 || pergunta.tipo === 1) {
            respostaNova = {
              id: Math.random(),
              idOpcao: null,
              resposta: "",
            };

            pergunta.resposta = respostaNova;
          } else {
            if (pergunta.tipo === 2) {
              respostaNova = {
                id: Math.random(),
                idOpcao: null,
                resposta: "",
              };

              pergunta.resposta = respostaNova;
            }
            if (pergunta.tipo === 3) {
              for (let opcao of pergunta.opcoes) {
                opcao.selecionado = false;
              }
            }
          }
        }

        this.setState({ hash, formulario, formularioExterno });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  async cadastrarResposta() {
    let formulario = this.state.formulario;
    let perguntas = formulario.perguntas;

    for (let pergunta of perguntas) {
      if (pergunta.tipo === 0 || pergunta.tipo === 1) {
        if (pergunta.obrigatoria) {
          if (pergunta.resposta.resposta === "") {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }

          if (pergunta.resposta.resposta.length > 1000) {
            this.notificar(
              "warning",
              "A resposta deve ter no máximo 1000 caracteres"
            );
            return false;
          }
        }
      } else if (pergunta.tipo === 2) {
        if (pergunta.obrigatoria) {
          if (pergunta.resposta.idOpcao === null) {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }
        }
      } else if (pergunta.tipo === 3) {
        if (pergunta.obrigatoria) {
          if (pergunta.opcoes.filter((op) => op.selecionado).length === 0) {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }
        }
      }
    }

    let data = {
      idForm: formulario.id,
      hash: this.state.hash,
      perguntas,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-externo-resposta`,
        data,
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Resposta salva com sucesso!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            // this.props.logout();
          }
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <div className="main-panel2">
            <div className="content2">
              <div className="row">
                <div className="col-md-12">
                  {this.state.formulario !== null && (
                    <div
                      className="card card-user"
                      style={{ borderTop: "8px solid #837878" }}
                    >
                      <div className="card-header card-header-pergunta d-flex">
                        <div className="col-12 no-padding">
                          <h3 className="card-title">
                            <React.Fragment>
                              <span>{this.state.formulario.nome}</span>
                            </React.Fragment>
                          </h3>
                          <div className="">
                            <div>{this.state.formulario.descricao}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {this.state.exibirMensagemBaixado && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-user card-form">
                      <div
                        className="card-header d-flex"
                        style={{
                          alignItems: "center",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          padding: "20px",
                        }}
                      >
                        <div style={{ display: "100%" }}>
                          <i
                            className="fa fa-check"
                            style={{ fontSize: "35px", color: "#28dd28" }}
                          />
                        </div>

                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <h3>Formulário respondido com sucesso! </h3>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            color: "gray",
                          }}
                        >
                          Data da resposta:{" "}
                          {moment(
                            this.state.formularioExterno?.dataResposta
                          ).format("DD/MM HH:mm")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.exibirMensagemBaixado &&
                this.state.formulario !== null &&
                this.state.formulario.perguntas.map((pergunta) => (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-user card-form">
                          <div
                            className="card-header d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="col-12 no-padding">
                              <h5 className="card-title">
                                {pergunta.titulo}{" "}
                                {pergunta.obrigatoria && (
                                  <span
                                    style={{
                                      color: "#dc0c0c",
                                      fontSize: "18px",
                                    }}
                                  >
                                    *
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>

                          <div className="card-body card-form">
                            {pergunta.tipo == 0 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  onChange={(e) => {
                                    this.handleChangeInputTexto(pergunta.id, e);
                                  }}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 1 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  onChange={(e) => {
                                    this.handleChangeInputTexto(pergunta.id, e);
                                  }}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 2 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="radio"
                                      value={op.id}
                                      checked={
                                        pergunta.resposta.idOpcao === op.id
                                      }
                                      name={`radio-${pergunta.id}`}
                                      onChange={(e) => {
                                        this.handleChangeInputRadio(
                                          op.id,
                                          pergunta.id
                                        );
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    {op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {pergunta.tipo == 3 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={op.opcao}
                                      checked={op.selecionado}
                                      onChange={() => {
                                        this.handleCheck(pergunta.id, op.id);
                                      }}
                                    />
                                    &nbsp;&nbsp;{op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

              {!this.state.exibirMensagemBaixado &&
                this.state.formulario !== null && (
                  <div>
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        this.cadastrarResposta();
                      }}
                    >
                      RESPONDER
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioResponderExterno;
