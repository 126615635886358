import React from 'react';
import ReactDOM from 'react-dom';
//import './style.css';
import './global.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";
registerLocale("pt-BR", ptBR);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
