import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import { cpfMask, phoneMask, cepMask } from "../../mask";
import { getDia, validaCpfCnpj, validaEmail, validarSenha } from "../../utils";
import queryString from "query-string";
import moment from "moment";

class AtendenteNovo extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.getDefaultAtendente = this.getDefaultAtendente.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleAba = this.toggleAba.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.buscarEndereco = this.buscarEndereco.bind(this);
    this.buscarAtendente = this.buscarAtendente.bind(this);
    this.handleChangeInputHorario = this.handleChangeInputHorario.bind(this);
    this.handleChangeHorarioAtivo = this.handleChangeHorarioAtivo.bind(this);
    this.handleClickCadastrarHorarios =
      this.handleClickCadastrarHorarios.bind(this);
    this.verificarTotalAtendentes = this.verificarTotalAtendentes.bind(this);
    this.buscarHorarios = this.buscarHorarios.bind(this);
    this.handleChangeInputHorarioPadrao =
      this.handleChangeInputHorarioPadrao.bind(this);
    this.handleClickAbrirAtendimento =
      this.handleClickAbrirAtendimento.bind(this);
    this.validarCadastroInputs = this.validarCadastroInputs.bind(this);
    this.handleClickReativar = this.handleClickReativar.bind(this);

    this.state = {
      id: null,
      atendente: this.getDefaultAtendente(),
      abaAtiva: "dados", // dados, contatoEndereco, horarios
      exibirLoadingCep: false,
      actionPage: "CADASTRAR",
      horarios: [],
      cadastroPermitido: true,
      exibirTabelaHorarios: false,
      horarioPadrao: {
        horarioInicio: "",
        horarioFim: "",
        intervaloInicio: "",
        intervaloFim: "",
      },
      errors: {
        desc: false,
        login: false,
        senha: false,
        senhaRepetir: false,
        cpf: false,
        telefone: false,
        celular: false,
        email: false,
        rua: false,
        numero: false,
        bairro: false,
        cidade: false,
        uf: false,
        sexo: false,
        estadoCivil: false,
        cep: false,
      },
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarAtendente(id);
      await this.buscarHorarios(id);
      let abaAtiva = queryString.parse(this.props.location.search).aba;
      if (abaAtiva !== undefined) {
        this.toggleAba(abaAtiva);
      }
    } else {
      await this.verificarTotalAtendentes();
    }

    // await this.load();
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  getDefaultAtendente() {
    return {
      desc: "",
      login: "",
      senha: "",
      senhaRepetir: "",
      cpf: "",
      telefone: "",
      celular: "",
      email: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "SC",
      sexo: "M", //M, F
      estadoCivil: "Solteiro(a)", //Solteiro(a), Casado(a), Divorciado(a), Viúvo(a)
      cep: "",
      isMaster: "0",
      obs: "",
    };
  }

  handleChangeInput(e, tipo) {
    let atendente = this.state.atendente;
    let value = e.target.value;

    if (tipo === "cpf") {
      value = cpfMask(value);
    } else if (tipo === "telefone") {
      value = phoneMask(value);
    } else if (tipo === "celular") {
      value = phoneMask(value);
    } else if (tipo === "cep") {
      value = cepMask(value);
    }

    atendente[tipo] = value;
    this.setState({ atendente });
  }

  toggleAba(abaNova) {
    this.setState({ abaAtiva: abaNova });
  }

  async handleClickCadastrar() {
    if (!this.state.cadastroPermitido) {
      this.notificar("warning", "Limite total de cadastros atingidos");
      return false;
    }

    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os campos corretamente!");
      return false;
    }

    if (!(await this.validarCadastroInputs())) {
      return false;
    }

    if (this.state.atendente.senha !== this.state.atendente.senhaRepetir) {
      this.notificar("warning", "As senhas não conferem!");
      return false;
    }

    if (this.state.atendente.senha !== "") {
      if (!validarSenha(this.state.atendente.senha)) {
        this.notificar(
          "warning",
          "A senha deve ter no mínimo 6 caracteres, deve ter um carácter especial, uma letra maiúscula e pelo menos uma minúscula."
        );
        return false;
      }
    }

    if (!validaCpfCnpj(this.state.atendente.cpf)) {
      this.notificar("warning", "CPF inválido.");
      return false;
    }

    if (!validaEmail(this.state.atendente.email)) {
      this.notificar("warning", "Email inválido.");
      return false;
    }

    let atendente = this.state.atendente;
    atendente.isMaster = atendente.isMaster === "1" ? true : false;

    if (this.state.actionPage === "CADASTRAR") {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atendentes`,
          atendente,
          this.props.parameters()
        )
        .then((response) => {
          this.setState({ atendente: this.getDefaultAtendente() }, () => {
            this.notificar("success", "Atendente cadastrado com sucesso!");
            this.toggleAba("dados");
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    } else {
      await axios
        .put(
          `${this.props.getBaseUrlApi()}/atendentes/${this.state.id}`,
          atendente,
          this.props.parameters()
        )
        .then((response) => {
          this.notificar("success", "Atendente editado com sucesso!");
          this.buscarAtendente(this.state.id);
          this.toggleAba("dados");
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    }
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.atendente.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    if (this.state.atendente.login === "") {
      errors.login = true;
      valido = false;
    } else {
      errors.login = false;
    }

    if (this.state.atendente.email === "") {
      errors.email = true;
      valido = false;
    } else {
      errors.email = false;
    }

    if (this.state.actionPage === "CADASTRAR") {
      if (this.state.atendente.senha === "") {
        errors.senha = true;
        valido = false;
      } else {
        errors.senha = false;
      }

      if (this.state.atendente.senhaRepetir === "") {
        errors.senhaRepetir = true;
        valido = false;
      } else {
        errors.senhaRepetir = false;
      }
    }

    if (this.state.atendente.cpf === "") {
      errors.cpf = true;
      valido = false;
    } else {
      errors.cpf = false;
    }

    if (this.state.atendente.cep === "") {
      errors.cep = true;
      valido = false;
    } else {
      errors.cep = false;
    }

    if (this.state.atendente.rua === "") {
      errors.rua = true;
      valido = false;
    } else {
      errors.rua = false;
    }

    if (this.state.atendente.numero === "") {
      errors.numero = true;
      valido = false;
    } else {
      errors.numero = false;
    }

    if (this.state.atendente.bairro === "") {
      errors.bairro = true;
      valido = false;
    } else {
      errors.bairro = false;
    }

    if (this.state.atendente.cidade === "") {
      errors.cidade = true;
      valido = false;
    } else {
      errors.cidade = false;
    }

    // if (this.state.atendente.telefone === "") {
    //   errors.telefone = true;
    //   valido = false;
    // } else {
    //   errors.telefone = false;
    // }

    // if (this.state.atendente.celular === "") {
    //   errors.celular = true;
    //   valido = false;
    // } else {
    //   errors.celular = false;
    // }

    this.setState({ errors });
    return valido;
  }

  async validarCadastroInputs() {
    let valido = true;

    let atendente = this.state.atendente;

    if (atendente.desc.length > 50) {
      valido = false;
      this.notificar("warning", "A Descrição deve ter no máximo 50 caracteres");
    }

    if (atendente.login.length > 50) {
      valido = false;
      this.notificar("warning", "O Login deve ter no máximo 50 caracteres");
    }

    if (atendente.telefone.length > 20) {
      valido = false;
      this.notificar("warning", "O Telefone deve ter no máximo 20 caracteres");
    }

    if (atendente.celular.length > 20) {
      valido = false;
      this.notificar("warning", "O Celular deve ter no máximo 20 caracteres");
    }

    if (atendente.email.length > 50) {
      valido = false;
      this.notificar("warning", "O Email deve ter no máximo 50 caracteres");
    }

    if (atendente.rua.length > 100) {
      valido = false;
      this.notificar("warning", "A Rua deve ter no máximo 100 caracteres");
    }

    if (atendente.numero.length > 20) {
      valido = false;
      this.notificar("warning", "A Número deve ter no máximo 20 caracteres");
    }

    if (atendente.complemento.length > 100) {
      valido = false;
      this.notificar(
        "warning",
        "O Complemento deve ter no máximo 100 caracteres"
      );
    }

    if (atendente.bairro.length > 60) {
      valido = false;
      this.notificar("warning", "O Bairro deve ter no máximo 60 caracteres");
    }

    if (atendente.cidade.length > 60) {
      valido = false;
      this.notificar("warning", "A Cidade deve ter no máximo 60 caracteres");
    }

    if (atendente.cep.length > 20) {
      valido = false;
      this.notificar("warning", "O CEP deve ter no máximo 20 caracteres");
    }

    if (atendente.obs.length > 200) {
      valido = false;
      this.notificar(
        "warning",
        "A observação deve ter no máximo 200 caracteres"
      );
    }

    return valido;
  }

  async buscarEndereco() {
    let cep = this.state.atendente.cep;

    if (cep === "") return false;

    cep = cep.replace(".", "");
    cep = cep.replace("-", "");

    if (cep.length < 8) {
      this.notificar("warning", "Cep digitado é inválido");
      return false;
    }

    this.setState({ exibirLoadingCep: true }, async () => {
      await axios
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
          let data = response.data;

          if (data.erro) {
            this.notificar(
              "warning",
              "Não foi possível localizar informação com base no CEP digitado!"
            );
            this.setState({ exibirLoadingCep: false });
            return false;
          }

          let atendente = this.state.atendente;
          atendente.rua = data.logradouro;
          atendente.bairro = data.bairro;
          atendente.cidade = data.localidade;
          atendente.uf = data.uf;

          this.setState({ atendente, exibirLoadingCep: false });
        })
        .catch((error) => {
          this.notificar(
            "warning",
            "Ocorreu um erro ao tentar buscar as informações."
          );
          this.setState({ exibirLoadingCep: false });
        });
    });
  }

  async buscarAtendente(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendentes/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let atendente = this.state.atendente;

        let data = response.data.atendente;

        if (data === null) {
          this.props.history.push("/atendentes");
          return false;
        }

        atendente = {
          ...atendente,
          desc: data.desc,
          login: data.login,
          cpf: data.cpf,
          telefone: data.telefone,
          celular: data.celular,
          email: data.email,
          rua: data.rua,
          numero: data.numero,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.cidade,
          uf: data.uf,
          sexo: data.sexo,
          estadoCivil: data.estadoCivil,
          cep: data.cep,
          isMaster: data.isMaster,
          status: data.status,
          obs: data.obs,
        };

        this.setState({ atendente, actionPage: "EDITAR", id });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarHorarios(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendentes-horarios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let horarios = response.data.horarios;
        this.setState({
          horarios,
          exibirTabelaHorarios: response.data.exibirTabelaHorarios,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInputHorario(value, dia, tipo) {
    let horarios = this.state.horarios;

    for (let horario of horarios) {
      if (horario.dia === dia) {
        horario[tipo] = value;
      }
    }

    this.setState({ horarios });
  }

  handleChangeHorarioAtivo(value, dia) {
    let horarios = this.state.horarios;

    for (let horario of horarios) {
      if (horario.dia === dia) {
        horario.ativo = parseInt(value);
      }
    }

    this.setState({ horarios });
  }

  async handleClickCadastrarHorarios() {
    let data = this.state.horarios;

    if (!this.state.exibirTabelaHorarios) {
      let horarioPadrao = this.state.horarioPadrao;

      if (
        horarioPadrao.horarioInicio === "" ||
        horarioPadrao.horarioFim === ""
        // || horarioPadrao.intervaloInicio === "" ||
        // horarioPadrao.intervaloFim === ""
      ) {
        this.notificar("warning", "Preencha todos os campos do horário");
        return false;
      }

      for (let horario of data) {
        if (horario.dia !== "sab" && horario.dia !== "dom") {
          horario.horarioInicio = horarioPadrao.horarioInicio;
          horario.horarioFim = horarioPadrao.horarioFim;
          horario.intervaloInicio = horarioPadrao.intervaloInicio;
          horario.intervaloFim = horarioPadrao.intervaloFim;
          horario.ativo = 1;
        }
      }
    }

    for (let horario of data) {
      if (horario.ativo === 1) {
        let diaDescritivo = getDia(horario.dia);
        if (horario.horarioInicio === "" || horario.horarioInicio === null) {
          this.notificar(
            "warning",
            `Informar os horários para o dia: ${diaDescritivo}`
          );
          return false;
        }
        if (horario.horarioFim === "" || horario.horarioFim === null) {
          this.notificar(
            "warning",
            `Informar os horários para o dia: ${diaDescritivo}`
          );
          return false;
        }

        if (horario.intervaloInicio === "") {
          horario.intervaloInicio = null;
        }

        if (horario.intervaloFim === "") {
          horario.intervaloFim = null;
        }

        if (horario.intervaloInicio === null || horario.intervaloFim === null) {
          horario.intervaloInicio = null;
          horario.intervaloFim = null;
        }

        // if (
        //   horario.intervaloInicio === "" ||
        //   horario.intervaloInicio === null
        // ) {
        //   this.notificar(
        //     "warning",
        //     `Informar os horários para o dia: ${diaDescritivo}`
        //   );
        //   return false;
        // }
        // if (horario.intervaloFim === "" || horario.intervaloFim === null) {
        //   this.notificar(
        //     "warning",
        //     `Informar os horários para o dia: ${diaDescritivo}`
        //   );
        //   return false;
        // }
      }
    }

    for (let horario of data) {
      if (horario.ativo === 1) {
        let horarioInicioValidar = moment()
          .set("hours", horario.horarioInicio.split(":")[0])
          .set("minutes", horario.horarioInicio.split(":")[1])
          .set("seconds", 0);

        let horarioFimValidar = moment()
          .set("hours", horario.horarioFim.split(":")[0])
          .set("minutes", horario.horarioFim.split(":")[1])
          .set("seconds", 0);

        let intervaloInicioValidar = null;
        let intervaloFimValidar = null;
        let validarIntervalo = false;

        if (
          horario.intervaloInicio !== "" &&
          horario.intervaloInicio !== null
        ) {
          validarIntervalo = true;
          intervaloInicioValidar = moment()
            .set("hours", horario.intervaloInicio.split(":")[0])
            .set("minutes", horario.intervaloInicio.split(":")[1])
            .set("seconds", 0);
        }

        if (horario.intervaloFim !== "" && horario.intervaloFim !== null) {
          intervaloFimValidar = moment()
            .set("hours", horario.intervaloFim.split(":")[0])
            .set("minutes", horario.intervaloFim.split(":")[1])
            .set("seconds", 0);
        }

        let diaDescritivo = getDia(horario.dia);

        if (!horarioInicioValidar.isBefore(horarioFimValidar)) {
          this.notificar(
            "warning",
            `Hora inicial deve ser menor que a hora final ${
              this.state.exibirTabelaHorarios
                ? "para o dia: " + diaDescritivo + ""
                : ""
            }`
          );
          return false;
        }

        if (validarIntervalo) {
          if (!intervaloInicioValidar.isBefore(intervaloFimValidar)) {
            this.notificar(
              "warning",
              `Hora do intervalo inicial deve ser menor que a hora final do intervado ${
                this.state.exibirTabelaHorarios
                  ? "para o dia: " + diaDescritivo + ""
                  : ""
              }`
            );

            return false;
          }

          if (!intervaloFimValidar.isBefore(horarioFimValidar)) {
            this.notificar(
              "warning",
              `Hora final deve ser maior que intervalo ${
                this.state.exibirTabelaHorarios
                  ? "para o dia: " + diaDescritivo + ""
                  : ""
              }`
            );

            return false;
          }

          if (!horarioInicioValidar.isBefore(intervaloInicioValidar)) {
            this.notificar(
              "warning",
              `Hora inicial deve ser menor que o intervalo ${
                this.state.exibirTabelaHorarios
                  ? "para o dia: " + diaDescritivo + ""
                  : ""
              }`
            );

            return false;
          }
        }
      }

      if (horario.horarioInicio === "") {
        horario.horarioInicio = null;
      }
      if (horario.horarioFim === "") {
        horario.horarioFim = null;
      }
      if (horario.intervaloInicio === "") {
        horario.intervaloInicio = null;
      }
      if (horario.intervaloFim === "") {
        horario.intervaloFim = null;
      }
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-horarios/${this.state.id}`,
        { horarios: data },
        this.props.parameters()
      )
      .then((response) => {
        let horarios = response.data.horarios;
        this.setState({ horarios }, () => {
          this.buscarHorarios(this.state.id);
          this.notificar("success", "Horário de atendimento atualizado");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async verificarTotalAtendentes(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/verificar-total-atendentes`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ cadastroPermitido: response.data.cadastroPermitido });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInputHorarioPadrao(value, tipo) {
    let horario = this.state.horarioPadrao;
    horario[tipo] = value;
    this.setState({ horarioPadrao: horario });
  }

  handleClickAbrirAtendimento() {
    return this.props.history.push(
      `/atendimentos-novo?atendente=${this.state.id}`
    );
  }

  async handleClickReativar() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-reativar/${this.state.id}`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        this.buscarAtendente(this.state.id);
        this.notificar("success", "Atendente reativado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atendentes" />

          <div className="main-panel">
            <NavBar title="Atendentes" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Cadastrar"
                          : "Editar"}{" "}
                        Atendente
                      </h5>
                    </div>

                    {this.state.actionPage === "CADASTRAR" &&
                      !this.state.cadastroPermitido && (
                        <div className="row padding-0-15">
                          <div className="col-md-12">
                            <p className="alerta-limite-cadastros">
                              Limite de cadastros atingidos
                            </p>
                          </div>
                        </div>
                      )}

                    <div className="row padding-0-15">
                      <div className="col-md-12 pr-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "dados" ? "active" : ""
                              }`}
                              onClick={() => this.toggleAba("dados")}
                              style={{ cursor: "pointer" }}
                            >
                              Dados
                            </div>
                          </li>
                          <li class="nav-item">
                            <div
                              class={`nav-link portal ${
                                this.state.abaAtiva === "contatoEndereco"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.toggleAba("contatoEndereco")}
                              style={{ cursor: "pointer" }}
                            >
                              Contato e Endereço
                            </div>
                          </li>
                          {this.state.actionPage === "EDITAR" && (
                            <li class="nav-item">
                              <div
                                class={`nav-link portal ${
                                  this.state.abaAtiva === "horarios"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.toggleAba("horarios")}
                                style={{ cursor: "pointer" }}
                              >
                                Horários
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {this.state.abaAtiva === "dados" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-5 pr-1">
                              <div className="form-group">
                                <label>Nome completo</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.desc ? "input-error" : ""
                                  }`}
                                  placeholder="Descrição"
                                  value={this.state.atendente.desc}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "desc")
                                  }
                                  disabled={
                                    this.state.actionPage === "EDITAR"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Login</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.login ? "input-error" : ""
                                  }`}
                                  placeholder="Login"
                                  value={this.state.atendente.login}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "login")
                                  }
                                  disabled={
                                    this.state.actionPage === "EDITAR"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input
                                  type="email"
                                  className={`form-control ${
                                    this.state.errors.email ? "input-error" : ""
                                  }`}
                                  placeholder="Email"
                                  value={this.state.atendente.email}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "email")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Senha</label>
                                <input
                                  type="password"
                                  className={`form-control ${
                                    this.state.errors.senha ? "input-error" : ""
                                  }`}
                                  placeholder="Senha"
                                  value={this.state.atendente.senha}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "senha")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Confirmar Senha</label>
                                <input
                                  type="password"
                                  className={`form-control ${
                                    this.state.errors.senhaRepetir
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Confirmar Senha"
                                  value={this.state.atendente.senhaRepetir}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "senhaRepetir")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>CPF</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cpf ? "input-error" : ""
                                  }`}
                                  placeholder="CPF"
                                  value={this.state.atendente.cpf}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cpf")
                                  }
                                  disabled={
                                    this.state.actionPage === "EDITAR"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Sexo</label>
                                <select
                                  className={`form-control form-control-select ${
                                    this.state.errors.sexo ? "input-error" : ""
                                  }`}
                                  value={this.state.atendente.sexo}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "sexo")
                                  }
                                  disabled={
                                    this.state.actionPage === "EDITAR"
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="M">Masculino</option>
                                  <option value="F">Feminino</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>Estado civil</label>
                                <select
                                  className={`form-control form-control-select ${
                                    this.state.errors.estadoCivil
                                      ? "input-error"
                                      : ""
                                  }`}
                                  value={this.state.atendente.estadoCivil}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "estadoCivil")
                                  }
                                >
                                  <option value="Solteiro(a)">
                                    Solteiro(a)
                                  </option>
                                  <option value="Casado(a)">Casado(a)</option>
                                  <option value="Divorciado(a)">
                                    Divorciado(a)
                                  </option>
                                  <option value="Viúvo(a)">Viúvo(a)</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Usuário Master?</label>
                                <select
                                  className={`form-control form-control-select`}
                                  value={this.state.atendente.isMaster}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "isMaster")
                                  }
                                >
                                  <option value="0">Não</option>
                                  <option value="1">Sim</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Observação</label>
                                <textarea
                                  className="form-control textarea"
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "obs")
                                  }
                                  value={this.state.atendente.obs}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                                disabled={
                                  this.state.atendente.status === "I"
                                    ? true
                                    : false
                                }
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Salvar"}
                              </button>

                              {this.state.actionPage === "EDITAR" &&
                                this.state.atendente.status !== "I" && (
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-round"
                                    onClick={this.handleClickAbrirAtendimento}
                                  >
                                    Abrir Atendimento
                                  </button>
                                )}
                              {this.state.atendente.status === "I" && (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-round"
                                  onClick={this.handleClickReativar}
                                >
                                  Reativar
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {this.state.abaAtiva === "contatoEndereco" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-3 pr-1">
                              <div className="form-group">
                                <label>CEP</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cep ? "input-error" : ""
                                  }`}
                                  placeholder="CEP"
                                  value={this.state.atendente.cep}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cep")
                                  }
                                  onBlur={this.buscarEndereco}
                                />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "35px",
                                  top: "35px",
                                }}
                              >
                                {this.state.exibirLoadingCep && (
                                  <div
                                    id="loading-bar-spinner"
                                    className="spinner"
                                  >
                                    <div className="spinner-icon"></div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Rua</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.rua ? "input-error" : ""
                                  }`}
                                  placeholder="Rua"
                                  value={this.state.atendente.rua}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "rua")
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Número</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.numero
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Número"
                                  value={this.state.atendente.numero}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "numero")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Complemento</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="Complemento"
                                  value={this.state.atendente.complemento}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "complemento")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Bairro</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.bairro
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Bairro"
                                  value={this.state.atendente.bairro}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "bairro")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Cidade</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.cidade
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Cidade"
                                  value={this.state.atendente.cidade}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "cidade")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Estado</label>
                                <select
                                  type="number"
                                  className={`form-control form-control-select ${
                                    this.state.errors.uf ? "input-error" : ""
                                  }`}
                                  value={this.state.atendente.uf}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "uf")
                                  }
                                >
                                  <option value="AC">AC</option>
                                  <option value="AL">AL</option>
                                  <option value="AP">AP</option>
                                  <option value="AM">AM</option>
                                  <option value="BA">BA</option>
                                  <option value="CE">CE</option>
                                  <option value="DF">DF</option>
                                  <option value="ES">ES</option>
                                  <option value="GO">GO</option>
                                  <option value="MA">MA</option>
                                  <option value="MS">MS</option>
                                  <option value="MT">MT</option>
                                  <option value="MG">MG</option>
                                  <option value="PA">PA</option>
                                  <option value="PB">PB</option>
                                  <option value="PR">PR</option>
                                  <option value="PE">PE</option>
                                  <option value="PI">PI</option>
                                  <option value="RJ">RJ</option>
                                  <option value="RN">RN</option>
                                  <option value="RS">RS</option>
                                  <option value="RO">RO</option>
                                  <option value="RR">RR</option>
                                  <option value="SC">SC</option>
                                  <option value="SP">SP</option>
                                  <option value="SE">SE</option>
                                  <option value="TO">TO</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Telefone</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.telefone
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Telefone"
                                  value={this.state.atendente.telefone}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "telefone")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Celular</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.celular
                                      ? "input-error"
                                      : ""
                                  }`}
                                  placeholder="Celular"
                                  value={this.state.atendente.celular}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "celular")
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                                disabled={
                                  this.state.atendente.status === "I"
                                    ? true
                                    : false
                                }
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "CADASTRAR"
                                  : "SALVAR"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}

                    {this.state.abaAtiva === "horarios" &&
                      this.state.atendente.desc !== "" && (
                        <div className="card-body">
                          {!this.state.exibirTabelaHorarios && (
                            <React.Fragment>
                              {" "}
                              <div className="title-horarios">
                                Horário Padrão
                              </div>
                              <div className="row">
                                <div className="col-md-3 pr-1">
                                  <div className="form-group">
                                    <label>Hora Início</label>
                                    <input
                                      type="time"
                                      max="23:59"
                                      className={`form-control`}
                                      placeholder="Hora início"
                                      value={
                                        this.state.horarioPadrao.horarioInicio
                                      }
                                      onChange={(e) =>
                                        this.handleChangeInputHorarioPadrao(
                                          e.target.value,
                                          "horarioInicio"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-3 pr-1">
                                  <div className="form-group">
                                    <label>Hora Fim</label>
                                    <input
                                      type="time"
                                      max="23:59"
                                      className={`form-control`}
                                      placeholder="Hora fim"
                                      value={
                                        this.state.horarioPadrao.horarioFim
                                      }
                                      onChange={(e) =>
                                        this.handleChangeInputHorarioPadrao(
                                          e.target.value,
                                          "horarioFim"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-3 pr-1">
                                  <div className="form-group">
                                    <label>Intervalo Início</label>
                                    <input
                                      type="time"
                                      max="23:59"
                                      className={`form-control`}
                                      placeholder="Intervalo início"
                                      value={
                                        this.state.horarioPadrao.intervaloInicio
                                      }
                                      onChange={(e) =>
                                        this.handleChangeInputHorarioPadrao(
                                          e.target.value,
                                          "intervaloInicio"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Intervalo Fim</label>
                                    <input
                                      type="time"
                                      max="23:59"
                                      className={`form-control`}
                                      placeholder="Intervalo fim"
                                      value={
                                        this.state.horarioPadrao.intervaloFim
                                      }
                                      onChange={(e) =>
                                        this.handleChangeInputHorarioPadrao(
                                          e.target.value,
                                          "intervaloFim"
                                        )
                                      }
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="float-left w-100 text-center">
                                Após definir o horário padrão, você poderá
                                editar manualmente nos dias da semana.
                              </div>
                            </React.Fragment>
                          )}
                          {this.state.exibirTabelaHorarios && (
                            <div className="table-responsive">
                              <table className="table">
                                <thead
                                  className="text-primary"
                                  ref={(node) => {
                                    if (node) {
                                      node.style.setProperty(
                                        "color",
                                        this.state.tema.textoPrincipal,
                                        "important"
                                      );
                                    }
                                  }}
                                >
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Dia
                                  </th>
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Ativo
                                  </th>
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Hora Início
                                  </th>
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Hora Fim
                                  </th>
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Intervalo Início
                                  </th>
                                  <th
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      borderTop: "none",
                                    }}
                                  >
                                    Intervalo Fim
                                  </th>
                                </thead>
                                <tbody>
                                  {this.state.horarios.length &&
                                    this.state.horarios.map((horario, key) => (
                                      <tr key={key}>
                                        <td>{getDia(horario.dia)}</td>
                                        <td>
                                          <select
                                            className="form-control form-control-select"
                                            value={horario.ativo}
                                            onChange={(e) =>
                                              this.handleChangeHorarioAtivo(
                                                e.target.value,
                                                horario.dia
                                              )
                                            }
                                          >
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            type="time"
                                            max="23:59"
                                            className="form-control"
                                            value={horario.horarioInicio}
                                            onChange={(e) =>
                                              this.handleChangeInputHorario(
                                                e.target.value,
                                                horario.dia,
                                                "horarioInicio"
                                              )
                                            }
                                            disabled={
                                              horario.ativo === 0 ? true : false
                                            }
                                          />{" "}
                                        </td>
                                        <td>
                                          <input
                                            type="time"
                                            max="23:59"
                                            className="form-control"
                                            value={horario.horarioFim}
                                            onChange={(e) =>
                                              this.handleChangeInputHorario(
                                                e.target.value,
                                                horario.dia,
                                                "horarioFim"
                                              )
                                            }
                                            disabled={
                                              horario.ativo === 0 ? true : false
                                            }
                                          />{" "}
                                        </td>
                                        <td>
                                          <input
                                            type="time"
                                            max="23:59"
                                            className="form-control"
                                            value={horario.intervaloInicio}
                                            onChange={(e) =>
                                              this.handleChangeInputHorario(
                                                e.target.value,
                                                horario.dia,
                                                "intervaloInicio"
                                              )
                                            }
                                            disabled={
                                              horario.ativo === 0 ? true : false
                                            }
                                          />{" "}
                                        </td>
                                        <td>
                                          <input
                                            type="time"
                                            max="23:59"
                                            className="form-control"
                                            value={horario.intervaloFim}
                                            onChange={(e) =>
                                              this.handleChangeInputHorario(
                                                e.target.value,
                                                horario.dia,
                                                "intervaloFim"
                                              )
                                            }
                                            disabled={
                                              horario.ativo === 0 ? true : false
                                            }
                                          />{" "}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrarHorarios}
                                disabled={
                                  this.state.atendente.status === "I"
                                    ? true
                                    : false
                                }
                              >
                                Salvar Horários
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AtendenteNovo;
