import React, { Component } from "react";
import Dropzone from "react-dropzone";

export default class Upload extends Component {

  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <p className="upload-message">Arraste arquivos aqui...</p>;
    }

    if (isDragReject) {
      return <p className="upload-message-error">Arquivo não suportado</p>;
    }

    return <p className="upload-message-success">Solte os arquivos aqui</p>;
  };

  render() {
    const { onUpload } = this.props;

    return (
      <Dropzone accept="image/*, application/pdf, .doc, .docx, .xlt, .xls, .xlsx, .txt, .csv, " onDropAccepted={onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            className={`dropzone drop-container ${
              isDragActive ? "drop-active" : ""
            } ${isDragReject ? "drop-reject" : ""}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </div>
        )}
      </Dropzone>
    );
  }
}
