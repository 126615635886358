import React, { Component } from "react";
import { SideBar, NavBar, CurrencyInput } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { formatarReal } from "../../utils";

class Atendimento extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.buscarAtividades = this.buscarAtividades.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.buscarAtendimentos = this.buscarAtendimentos.bind(this);
    this.buscarAtendentes = this.buscarAtendentes.bind(this);
    this.promiseOptionsAtendentes = this.promiseOptionsAtendentes.bind(this);
    this.promiseOptionsConsultantes =
      this.promiseOptionsConsultantes.bind(this);
    this.buscarConsultantes = this.buscarConsultantes.bind(this);
    this.buscarMotivosCancelamento = this.buscarMotivosCancelamento.bind(this);
    this.toggleModalCancelar = this.toggleModalCancelar.bind(this);
    this.handleClickCancelar = this.handleClickCancelar.bind(this);
    this.handleConfirmCancelar = this.handleConfirmCancelar.bind(this);
    this.abrirWhatsapp = this.abrirWhatsapp.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.buscarFormasPagamento = this.buscarFormasPagamento.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.toggleModal2 = this.toggleModal2.bind(this);
    this.handleConfirmMarcarComoPago =
      this.handleConfirmMarcarComoPago.bind(this);
    this.abrirModalPagamento = this.abrirModalPagamento.bind(this);

    this.state = {
      atendentes: [],
      atendenteSelecionado: null,
      consultantes: [],
      consultanteSelecionado: null,
      statusBuscar: "TODOS",
      atividades: [],
      atividadeSelecionada: null,

      dataInicial: moment().startOf("month").toDate(),
      dataFinal: new Date(),

      atendimentos: [],

      idAtendenteDesbloquear: null,
      modalDeletar: false,
      idAtendenteDeletar: null,
      modalDesbloquear: false,
      motivosCancelamento: [],
      motivosCancelamentoSelecionado: null,
      modalCancelar: false,
      errors: {
        motivoCancelamento: false,
      },
      idAtendimentoCancelar: null,
      user: {
        nivel: 2,
      },
      tema: props.getTema(),
      modal: false,
      formasPagamento: [],
      formaPagamentoSelecionada: null,
      atendimentoSelecionado: null,
      desconto: "0,00",
      acrescimo: "0,00",
      modal2: false,
      totalizadores: {
        valorTotal: 0,
        valorPendente: 0,
        valorRecebido: 0,
      },
      tipoTotalizador: "TODOS", //TODOS, PAGO, PENDENTE
    };
  }

  async componentDidMount() {
    await this.buscarAtendimentos();
    await this.buscarMotivosCancelamento();
    await this.buscarFormasPagamento();
    let user = this.props.user();
    this.setState({ user });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/atendimentos-visualizar?id=${id}`);
  }

  handleClickDeletar(id) {
    this.setState({ idAtendenteDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value });
  }

  async buscarAtividades(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-atividades-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.atividades);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }
  async buscarAtendentes(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-atendentes-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.atendentes);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }
  async buscarConsultantes(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-consultantes-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.consultantes);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }

  promiseOptions(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atividades = await this.buscarAtividades(inputValue);

          atividades = atividades.map((atividade) => {
            atividade.label = atividade.desc;
            atividade.value = atividade.id;
            return atividade;
          });

          this.setState({ atividades });

          resolve(atividades);
        } else {
          if (inputValue.length >= 1) {
            let atividades = await this.buscarAtividades(inputValue);

            atividades = atividades.map((atividade) => {
              atividade.label = atividade.desc;
              atividade.value = atividade.id;
              return atividade;
            });
            this.setState({ atividades });
            resolve(atividades);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  promiseOptionsAtendentes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atendentes = await this.buscarAtendentes(inputValue);

          atendentes = atendentes.map((atendente) => {
            atendente.label = atendente.desc;
            atendente.value = atendente.id;
            return atendente;
          });

          this.setState({ atendentes });

          resolve(atendentes);
        } else {
          if (inputValue.length >= 1) {
            let atendentes = await this.buscarAtendentes(inputValue);

            atendentes = atendentes.map((atendente) => {
              atendente.label = atendente.desc;
              atendente.value = atendente.id;
              return atendente;
            });
            this.setState({ atendentes });
            resolve(atendentes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  promiseOptionsConsultantes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let consultantes = await this.buscarConsultantes(inputValue);

          consultantes = consultantes.map((consultante) => {
            consultante.label = consultante.desc;
            consultante.value = consultante.id;
            return consultante;
          });

          this.setState({ consultantes });

          resolve(consultantes);
        } else {
          if (inputValue.length >= 1) {
            let consultantes = await this.buscarConsultantes(inputValue);

            consultantes = consultantes.map((consultante) => {
              consultante.label = consultante.desc;
              consultante.value = consultante.id;
              return consultante;
            });
            this.setState({ consultantes });
            resolve(consultantes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  limparFiltros() {
    this.setState(
      {
        atendenteSelecionado: null,
        consultanteSelecionado: null,
        atividadeSelecionada: null,
        statusBuscar: "TODOS",
        dataInicial: moment().startOf("month").toDate(),
        dataFinal: new Date(),
      },
      () => {
        this.buscarAtendimentos();
      }
    );
  }

  async buscarAtendimentos() {
    let status = "";
    let atendente = "";
    let consultante = "";
    let atividade = "";
    let dataInicial = moment(this.state.dataInicial);
    let dataFinal = moment(this.state.dataFinal);

    if (this.state.atendenteSelecionado !== null) {
      atendente = this.state.atendenteSelecionado.id;
    }
    if (this.state.consultanteSelecionado !== null) {
      consultante = this.state.consultanteSelecionado.id;
    }
    if (this.state.atividadeSelecionada !== null) {
      atividade = this.state.atividadeSelecionada.id;
    }

    status = this.state.statusBuscar;

    if (!dataInicial.isSameOrBefore(dataFinal)) {
      this.notificar("warning", "Data inicial deve ser menor que data final");
      return false;
    } else {
      dataInicial = dataInicial.format("YYYY-MM-DD");
      dataFinal = dataFinal.format("YYYY-MM-DD");
    }
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/relatorios/financeiro?status=${status}&atendente=${atendente}&consultante=${consultante}&atividade=${atividade}&datainicial=${dataInicial}&datafinal=${dataFinal}`,

        this.props.parameters()
      )
      .then((response) => {
        const atendimentos = response.data.atendimentos;
        const totalizadores = response.data.totalizadores;
        const tipoTotalizador = status;
        this.setState({ atendimentos, totalizadores, tipoTotalizador });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarMotivosCancelamento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/motivos-cancelamento`,
        this.props.parameters()
      )
      .then(async (response) => {
        let motivosCancelamento = response.data.motivosCancelamento;

        motivosCancelamento = motivosCancelamento.map((motivo) => {
          motivo.value = motivo.id;
          motivo.label = motivo.desc;
          return motivo;
        });
        this.setState({
          motivosCancelamento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalCancelar() {
    this.setState({ modalCancelar: !this.state.modalCancelar });
  }

  handleClickCancelar(id) {
    this.setState({ idAtendimentoCancelar: id }, () => {
      this.toggleModalCancelar();
    });
  }

  async handleConfirmCancelar() {
    let motivoCancelamento = this.state.motivosCancelamentoSelecionado;

    if (motivoCancelamento === null) {
      this.notificar("warning", "Selecione o motivo de cancelamento");
      this.setState({
        errors: {
          ...this.state.errors,
          motivoCancelamento: true,
        },
      });
      return false;
    }

    let id = this.state.idAtendimentoCancelar;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-status/${id}`,
        {
          novoStatus: "CANCELADO",
          idMotivoCancelamento: motivoCancelamento.id,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento cancelado");
        this.toggleModalCancelar();
        this.buscarAtendimentos();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  abrirWhatsapp(numero) {
    numero = numero.replace(/\D/g, "");
    window
      .open(`https://api.whatsapp.com/send?phone=${numero}`, "_blank")
      .focus();
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  async buscarFormasPagamento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formas-pagamento`,
        this.props.parameters()
      )
      .then(async (response) => {
        let formasPagamento = response.data.formasPagamento;

        formasPagamento = formasPagamento.map((formaPagamento) => {
          formaPagamento.value = formaPagamento.id;
          formaPagamento.label = formaPagamento.desc;
          return formaPagamento;
        });
        this.setState({
          formasPagamento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  abrirModalVisualizarPagamento(atendimentoSelecionado) {
    let valorDesconto = 0;
    let valorAcrescimo = 0;

    if (atendimentoSelecionado.valorDesconto !== null) {
      valorDesconto = atendimentoSelecionado.valorDesconto;
    }
    if (atendimentoSelecionado.valorAcrescimo !== null) {
      valorDesconto = atendimentoSelecionado.valorAcrescimo;
    }

    atendimentoSelecionado.valorDesconto = valorDesconto;
    atendimentoSelecionado.valorAcrescimo = valorAcrescimo;

    this.setState(
      { atendimentoSelecionado, desconto: (0.9).toString() },
      () => {
        this.toggleModal();
      }
    );
  }

  toggleModal2() {
    this.setState({ modal2: !this.state.modal2 });
  }

  async handleConfirmMarcarComoPago() {
    let formaPagamento = this.state.formaPagamentoSelecionada;

    if (formaPagamento === null) {
      this.notificar("warning", "Selecione a forma de pagamento");
      // this.setState({
      //   errors: {
      //     ...this.state.errors,
      //     formaPagamento: true,
      //   },
      // });
      return false;
    }

    let desconto = this.state.desconto;
    desconto = desconto.replace("R$", "");
    desconto = desconto.replace(",", ".");
    desconto = parseFloat(desconto);

    if (desconto === 0) {
      desconto = null;
    }

    let acrescimo = this.state.acrescimo;
    acrescimo = acrescimo.replace("R$", "");
    acrescimo = acrescimo.replace(",", ".");
    acrescimo = parseFloat(acrescimo);

    if (acrescimo === 0) {
      acrescimo = null;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-pagamento/${
          this.state.atendimentoSelecionado.id
        }`,
        {
          idFormaPagamento: formaPagamento.id,
          desconto,
          acrescimo,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento marcado como pago");
        this.toggleModal2();
        await this.buscarAtendimentos();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  abrirModalPagamento(atendimentoSelecionado) {
    this.setState(
      { atendimentoSelecionado, desconto: "0,00", acrescimo: "0,00" },
      () => {
        this.toggleModal2();
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="relatorios-financeiro" />
          <div className="main-panel">
            <NavBar title="Relatório Financeiro" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Atendimentos</h4>
                    </div>
                    <div className="card-body">
                      <div
                        className="row float-left w-100"
                        style={{ flexDirection: "row" }}
                      >
                        <div className="col-xs-12 col-md-2 pr-1">
                          <div className="form-group no-margin">
                            <label style={{ display: "block" }}>
                              Data Inicial
                            </label>
                            <DatePicker
                              selected={this.state.dataInicial}
                              //withPortal
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataInicial");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-md-2 pr-1">
                          <div className="form-group no-margin">
                            <label style={{ display: "block" }}>
                              Data Final
                            </label>
                            <DatePicker
                              selected={this.state.dataFinal}
                              //withPortal
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataFinal");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                        {/* 
                        {this.state.user.nivel !== 2 && (
                          <div className="col-md-3 pr-1">
                            <div className="form-group">
                              <label>Atendente</label>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={this.promiseOptionsAtendentes}
                                value={this.state.atendenteSelecionado}
                                onChange={(item) =>
                                  this.handleChangeInput(
                                    item,
                                    "atendenteSelecionado"
                                  )
                                }
                                placeholder="Selecione"
                              />
                            </div>
                          </div>
                        )} */}
                        <div className="col-md-3 pr-1">
                          <div className="form-group no-margin">
                            <label>Paciente</label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptionsConsultantes}
                              value={this.state.consultanteSelecionado}
                              onChange={(item) =>
                                this.handleChangeInput(
                                  item,
                                  "consultanteSelecionado"
                                )
                              }
                              placeholder="Selecione"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group no-margin">
                            <label>Status</label>
                            <select
                              className="form-control form-control-select"
                              value={this.state.statusBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "statusBuscar"
                                )
                              }
                            >
                              <option value="TODOS">TODOS</option>
                              <option value="PAGO">PAGO</option>
                              <option value="PENDENTE">PENDENTE</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 pr-1">
                          <div className="form-group no-margin">
                            <label>Atividade</label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptions}
                              value={this.state.atividadeSelecionada}
                              onChange={(item) =>
                                this.handleChangeInput(
                                  item,
                                  "atividadeSelecionada"
                                )
                              }
                              placeholder="Selecione"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row float-left w-100">
                        <div className="col-md-3" style={{ display: "flex" }}>
                          <div className="form-group" style={{ float: "left" }}>
                            <label></label>
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.buscarAtendimentos()}
                              id={`tooltip-filtro-atendimentos`}
                            >
                              <i
                                className="fa fa-filter"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-filtro-atendimentos`}
                            >
                              Filtrar
                            </UncontrolledTooltip>
                          </div>
                          <div className="form-group">
                            <label></label>
                            <button
                              className="btn btn-danger btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.limparFiltros()}
                              id={`tooltip-limpar-filtro-atendimentos`}
                            >
                              <i
                                className="fa fa-eraser"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-limpar-filtro-atendimentos`}
                            >
                              Limpar filtro
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>Código</th>
                            {/* {this.state.user.nivel !== 2 && <th>Atendente</th>} */}
                            <th>Paciente</th>
                            <th>Atividade</th>
                            <th className="text-right">Horário</th>
                            {/* <th className="text-right">Valor Total</th> */}
                            <th className="text-center">Pago ?</th>
                            <th className="text-center">Valor Pago</th>
                            <th className="text-center">Forma</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.atendimentos.length > 0 &&
                              this.state.atendimentos.map(
                                (atendimento, key) => (
                                  <tr key={key}>
                                    <td>
                                      {atendimento.id
                                        .toString()
                                        .padStart(4, "0")}
                                    </td>
                                    {/* <td>
                                      {" "}
                                      <span
                                        className={`tag ${atendimento.status}`}
                                      >
                                        {atendimento.status}
                                      </span>
                                    </td> */}

                                    {/* {this.state.user.nivel !== 2 && (
                                      <td>{atendimento.descAtendente}</td>
                                    )} */}
                                    <td>{atendimento.descConsultante}</td>
                                    <td>{atendimento.descAtividade}</td>
                                    <td className="text-right">
                                      {atendimento.dataFormatada} <br />{" "}
                                      {atendimento.horario}
                                    </td>
                                    {/* <td className="text-right">
                                      R${" "}
                                      {formatarReal(atendimento.valorOriginal)}
                                    </td> */}
                                    <td className="text-center">
                                      {atendimento.pago === 1 ? "Sim" : "Não"}
                                    </td>
                                    <td
                                      // className="text-right"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {atendimento.pago === 1 && (
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div>
                                            R$ {formatarReal(atendimento.valor)}
                                          </div>

                                          <div style={{ marginLeft: "8px" }}>
                                            <i
                                              className="fa fa-eye"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.abrirModalVisualizarPagamento(
                                                  atendimento
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {atendimento.pago === 0 && (
                                        <div
                                          className="text-center"
                                          style={{
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          -
                                        </div>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {atendimento.descFormaPagamento === null
                                        ? "-"
                                        : atendimento.descFormaPagamento}
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justifyContentCenter">
                                        <div
                                          style={{ margin: "0 3px" }}
                                          className="text-center"
                                        >
                                          <button
                                            className="btn btn-primary btn-round"
                                            onClick={() =>
                                              this.handleClickVisualizar(
                                                atendimento.id
                                              )
                                            }
                                            id={`tooltip-editar-atendimentos-${atendimento.id}`}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-editar-atendimentos-${atendimento.id}`}
                                          >
                                            Visualizar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{ margin: "0 3px" }}
                                          className="text-center"
                                        >
                                          <button
                                            className="btn btn-success btn-round"
                                            onClick={() =>
                                              this.abrirModalPagamento(
                                                atendimento
                                              )
                                            }
                                            id={`tooltip-editar-atendimentos2-${atendimento.id}`}
                                            disabled={
                                              atendimento.pago === 1
                                                ? true
                                                : false
                                            }
                                          >
                                            <i className="fa fa-money"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-editar-atendimentos2-${atendimento.id}`}
                                          >
                                            Marcar como pago
                                          </UncontrolledTooltip>
                                        </div>

                                        {/*
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-danger btn-round"
                                            onClick={() =>
                                              this.handleClickCancelar(
                                                atendimento.id
                                              )
                                            }
                                            id={`tooltip-deletar-atendimentos-${atendimento.id}`}
                                            disabled={
                                              atendimento.status !== "NOVO"
                                                ? true
                                                : false
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-deletar-atendimentos-${atendimento.id}`}
                                          >
                                            Deletar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-success btn-round"
                                            onClick={() =>
                                              this.abrirWhatsapp(
                                                atendimento.celularConsultante
                                              )
                                            }
                                            id={`tooltip-abrir-whatsapp-${atendimento.id}`}
                                            disabled={
                                              atendimento.celularConsultante ===
                                              ""
                                                ? true
                                                : false
                                            }
                                          >
                                            <i
                                              className="fa fa-whatsapp"
                                              style={{
                                                cursor: "pointer",
                                                color: "#FFF",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-abrir-whatsapp-${atendimento.id}`}
                                          >
                                            Abrir Whatsapp
                                          </UncontrolledTooltip>
                                        </div> */}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>

                      {this.state.atendimentos.length > 0 && (
                        <div>
                          {this.state.tipoTotalizador === "TODOS" && (
                            <div
                              className="d-flex flex-wrap flexDirectionColumn justifyContentCenter"
                              style={{ fontSize: "17px" }}
                            >
                              <div style={{ padding: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Valor Total:
                                </span>{" "}
                                R$
                                {formatarReal(
                                  this.state.totalizadores.valorTotal
                                )}
                              </div>
                              <div style={{ padding: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Valor Recebido:
                                </span>{" "}
                                R$
                                {formatarReal(
                                  this.state.totalizadores.valorRecebido
                                )}
                              </div>
                              <div style={{ padding: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Valor Pendente:
                                </span>{" "}
                                R$
                                {formatarReal(
                                  this.state.totalizadores.valorPendente
                                )}
                              </div>
                            </div>
                          )}
                          {this.state.tipoTotalizador === "PAGO" && (
                            <div
                              className="d-flex flex-wrap flexDirectionColumn justifyContentCenter"
                              style={{ fontSize: "17px" }}
                            >
                              <div style={{ padding: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Valor Recebido:
                                </span>{" "}
                                R$
                                {formatarReal(
                                  this.state.totalizadores.valorRecebido
                                )}
                              </div>
                            </div>
                          )}
                          {this.state.tipoTotalizador === "PENDENTE" && (
                            <div
                              className="d-flex flex-wrap flexDirectionColumn justifyContentCenter"
                              style={{ fontSize: "17px" }}
                            >
                              <div style={{ padding: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Valor Pendente:
                                </span>{" "}
                                R$
                                {formatarReal(
                                  this.state.totalizadores.valorPendente
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Detalhes do pagamento
          </ModalHeader>
          <ModalBody>
            {this.state.atendimentoSelecionado !== null && (
              <span className="float-left w-100 text-center">
                Valor Total R${" "}
                {formatarReal(this.state.atendimentoSelecionado.valorOriginal)}
              </span>
            )}

            {this.state.atendimentoSelecionado !== null && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Forma de pagamento</label>
                    <Select
                      options={this.state.formasPagamento}
                      value={{
                        label:
                          this.state.atendimentoSelecionado.descFormaPagamento,
                      }}
                      placeholder="Selecione"
                      onChange={(item) => {
                        this.handleChangeInput(
                          item,
                          "formaPagamentoSelecionada"
                        );
                      }}
                      isDisabled={true}
                      // className={`${
                      //   this.state.errors.formaPagamento ? "select-error" : ""
                      // }`}
                    />
                  </div>

                  <div className="form-group">
                    <label>Desconto</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`R$ ${formatarReal(
                        this.state.atendimentoSelecionado.valorDesconto
                      )}`}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Acréscimo</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`R$ ${formatarReal(
                        this.state.atendimentoSelecionado.valorAcrescimo
                      )}`}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Valor Pago</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`R$ ${formatarReal(
                        this.state.atendimentoSelecionado.valor
                      )}`}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <Button
                className="btn-modal-recuperar"
                color="secondary"
                onClick={this.toggleModal}
              >
                Fechar
              </Button>
            </React.Fragment>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modal2} toggle={this.toggleModal2}>
          <ModalHeader toggle={this.toggleModal2}>Marcar como pago</ModalHeader>
          <ModalBody>
            {this.state.atendimentoSelecionado !== null && (
              <span className="float-left w-100 text-center">
                Valor Total R${" "}
                {formatarReal(this.state.atendimentoSelecionado.valorOriginal)}
              </span>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Forma de pagamento</label>
                  <Select
                    options={this.state.formasPagamento}
                    value={this.state.formaPagamentoSelecionada}
                    placeholder="Selecione"
                    onChange={(item) => {
                      this.handleChangeInput(item, "formaPagamentoSelecionada");
                    }}
                    // className={`${
                    //   this.state.errors.formaPagamento ? "select-error" : ""
                    // }`}
                  />
                </div>
                <div className="form-group">
                  <label>Desconto</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.desconto}
                    onChange={(e) => {
                      this.handleChangeInput(e.target.value, "desconto");
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
                <div className="form-group">
                  <label>Acréscimo</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.acrescimo}
                    onChange={(e) => {
                      this.handleChangeInput(e.target.value, "acrescimo");
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <Button
                className="btn-modal-recuperar"
                color="secondary"
                onClick={this.toggleModal}
              >
                Não
              </Button>
              <Button
                className="btn-modal-recuperar"
                color="primary"
                // onClick={this.toggleModal}
                onClick={this.handleConfirmMarcarComoPago}
              >
                Sim
              </Button>{" "}
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Atendimento;
