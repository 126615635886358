import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class Convenio extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);

    this.state = {
      convenios: [],
      modalDeletar: false,
      idConvenioDeletar: null,
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    this.load();
  }
  async load() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/convenios`, this.props.parameters())
      .then((response) => {
        this.setState({ convenios: response.data.convenios });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/convenios-visualizar?id=${id}`);
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  handleClickDeletar(id) {
    this.setState({ idConvenioDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  async handleConfirmDeletar() {
    let id = this.state.idConvenioDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/convenios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ idConvenioDeletar: null }, async () => {
          this.toggleModalDeletar();
          await this.load();
          this.notificar("success", "Convênio deletado com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="convenios" />
          <div className="main-panel">
            <NavBar title="Convênios" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Convênios</h4>
                      <div className="content-btn-novo-item">
                        {" "}
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.props.history.push("/convenios-novo");
                          }}
                        >
                          Novo Convênio
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>Descrição</th>
                            <th className="text-center">Data Cadastro</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.convenios.length > 0 &&
                              this.state.convenios.map((convenio, key) => (
                                <tr key={key}>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(convenio.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {convenio.desc}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(convenio.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {moment(convenio.dataCadastro).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justifyContentCenter">
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-primary btn-round"
                                          onClick={() =>
                                            this.handleClickVisualizar(
                                              convenio.id
                                            )
                                          }
                                          id={`tooltip-visualizar-convenios-${convenio.id}`}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-visualizar-convenios-${convenio.id}`}
                                        >
                                          Visualizar
                                        </UncontrolledTooltip>
                                      </div>
                                      <div style={{ margin: "0 3px" }}>
                                        <button
                                          className="btn btn-danger btn-round"
                                          onClick={() =>
                                            this.handleClickDeletar(convenio.id)
                                          }
                                          id={`tooltip-deletar-convenios-${convenio.id}`}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-deletar-convenios-${convenio.id}`}
                                        >
                                          Deletar
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalDeletar}
          toggle={this.toggleModalDeletar}
        >
          <ModalHeader toggle={this.toggleModalDeletar}>
            Deletar Convênio
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este convênio?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Convenio;
