import React, { useState } from "react";
import { Link } from "react-router-dom";
import storage from "../../storage";
import { isMaster } from "../../auth";
require("dotenv").config();

const NavBar = (props) => {
  const [tema] = useState(JSON.parse(storage.getItem("tema")));
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <div
        id="myNav"
        className="overlay"
        style={{ width: menuOpen ? "100%" : "0%" }}
        ref={(node) => {
          if (node) {
            node.style.setProperty("background", tema.sidebar, "important");
          }
        }}
      >
        <a
          href="#"
          className="closebtn"
          id="closeNav"
          onClick={(e) => setMenuOpen(false)}
        >
          &times;
        </a>
        <div className="overlay-content">
          <Link to={"/atendimentos"} style={{ color: "#FFF" }}>
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "color",
                    tema.textoSidebar,
                    "important"
                  );
                }
              }}
            >
              {" "}
              Atendimentos
            </span>
          </Link>

          <Link to={"/dashboard"} style={{ color: "#FFF" }}>
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "color",
                    tema.textoSidebar,
                    "important"
                  );
                }
              }}
            >
              {" "}
              Dashboard
            </span>
          </Link>

          {isMaster() && (
            <React.Fragment>
              <Link to={"/atendentes"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Atendentes
                </span>
              </Link>

              <Link to={"/consultantes"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Pacientes
                </span>
              </Link>

              <Link to={"/atividades"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Atividades
                </span>
              </Link>

              <Link to={"/formas-pagamento"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Formas Pagamento
                </span>
              </Link>

              <Link to={"/motivos-cancelamento"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Motivos Cancelamento
                </span>
              </Link>

              <Link to={"/convenios"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Convênios
                </span>
              </Link>
              <Link to={"/formularios"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Formulários
                </span>
              </Link>
              <Link to={"/templates"} style={{ color: "#FFF" }}>
                <span
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  {" "}
                  Templates
                </span>
              </Link>
            </React.Fragment>
          )}

          <Link to={"/relatorios-financeiro"} style={{ color: "#FFF" }}>
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "color",
                    tema.textoSidebar,
                    "important"
                  );
                }
              }}
            >
              {" "}
              Relatório Financeiro
            </span>
          </Link>

          <Link to={"/configuracoes"} style={{ color: "#FFF" }}>
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "color",
                    tema.textoSidebar,
                    "important"
                  );
                }
              }}
            >
              {" "}
              Configurações
            </span>
          </Link>

          <Link to={"/logout"} style={{ color: "#FFF" }}>
            <span
              ref={(node) => {
                if (node) {
                  node.style.setProperty(
                    "color",
                    tema.textoSidebar,
                    "important"
                  );
                }
              }}
            >
              {" "}
              Sair
            </span>
          </Link>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent nav-portal">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                className="navbar-toggler"
                onClick={(e) => setMenuOpen(true)}
              >
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <div className="navbar-brand">{props.title}</div>
          </div>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navigation"
          >
            {/* <form>
            <div className="input-group no-border">
              <input
                type="text"
                value=""
                className="form-control"
                placeholder="Buscar..."
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="nc-icon nc-zoom-split"></i>
                </div>
              </div>
            </div>
          </form> */}
            <ul className="navbar-nav">
              <li
                className="nav-item"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_DEV_DOMAIN}/configuracoes`)
                }
              >
                <div className="nav-link btn-rotate">
                  <i className="fa fa-cogs"></i>
                  <p>
                    <span className="d-lg-none d-md-block">Account</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
