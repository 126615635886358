import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import queryString from "query-string";

class TemplateListaResposta extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizarRespostaTemplate =
      this.handleClickVisualizarRespostaTemplate.bind(this);
    this.handleClickVisualizarAtendimento =
      this.handleClickVisualizarAtendimento.bind(this);

    this.state = {
      id: null,
      respostas: [],
      tituloTemplate: "",
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;
    if (id !== undefined) {
      this.load(id);
    } else {
      this.props.history.push("/templates");
      return false;
    }
  }
  async load(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/templates-lista-respostas/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let respostas = response.data.respostas;
        let tituloTemplate = "";
        if (respostas.length) {
          tituloTemplate = respostas[0].tituloTemplate;
        }
        this.setState({ respostas, tituloTemplate, id });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizarRespostaTemplate(template) {
    localStorage.setItem("html", template.html);
    localStorage.setItem("tituloTemplate", this.state.tituloTemplate);
    window.open("/templates-impressao", "_blank");
  }

  handleClickVisualizarAtendimento(id) {
    this.props.history.push(`/atendimentos-visualizar?id=${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="templates" />
          <div className="main-panel">
            <NavBar title="Respostas do Template" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title">
                        {" "}
                        {this.state.tituloTemplate}
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th
                              style={{
                                maxWidth: "165px",
                                width: "165px",
                                minWidth: "165px",
                              }}
                            >
                              ID
                            </th>
                            <th className="text-center">Data Resposta</th>
                            <th className="text-center">Consultante</th>
                            <th className="text-center">Atividade</th>
                            <th className="text-center">Data Atendimento</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.respostas.length > 0 &&
                              this.state.respostas.map((template, key) => (
                                <tr key={key}>
                                  <td
                                    style={{
                                      maxWidth: "165px",
                                      width: "165px",
                                      minWidth: "165px",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizarRespostaTemplate(
                                          template
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {template.id}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizarRespostaTemplate(
                                          template
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {moment(template.dataCadastro).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </span>
                                  </td>

                                  <td className="text-center">
                                    {template.descConsultante}
                                  </td>
                                  <td className="text-center">
                                    {template.descAtividade}
                                  </td>
                                  <td className="text-center">
                                    {template.dataFormatada} -{" "}
                                    {template.horario}
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justifyContentCenter">
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-primary btn-round"
                                          onClick={() => {
                                            this.handleClickVisualizarRespostaTemplate(
                                              template
                                            );
                                          }}
                                          id={`tooltip-editar-convenios-${template.id}`}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar-convenios-${template.id}`}
                                        >
                                          Visualizar
                                        </UncontrolledTooltip>
                                      </div>
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-secondary btn-round"
                                          onClick={() => {
                                            this.handleClickVisualizarAtendimento(
                                              template.idAtendimento
                                            );
                                          }}
                                          id={`tooltip-editar-convenios2-${template.idAtendimento}`}
                                        >
                                          <i className="fa fa-briefcase"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar-convenios2-${template.idAtendimento}`}
                                        >
                                          Visualizar Atendimento
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateListaResposta;
