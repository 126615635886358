import React, { Component } from "react";

class TemplateImpressao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      html: "",
      titulo: "",
    };
  }

  async componentDidMount() {
    let html = localStorage.getItem("html");
    let tituloStorage = localStorage.getItem("tituloTemplate");
    let titulo = this.state.titulo;

    if (html === null) {
      return this.props.history.push("/templates");
    }

    if(tituloStorage !== null){
      titulo = tituloStorage;
    }

    this.setState({ html, titulo }, () => {
      window.print();
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div
            className="main-panel"
            style={{ width: "100%", backgroundColor: "transparent" }}
          >
            <div className="content" style={{ marginTop: 0 }}>
              <div className="row">
                {/* <div utilizar somente para portal est
                  className="col-md-12 d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div style={{marginTop:"30px"}}>
                    <img
                      src="/images/logo-est3.jpg"
                      // style={{ with: "200px", maxWidth: "200px" }} //110
                      style={{ with: "200px", maxWidth: "200px" }} //110
                    />
                  </div>
                </div> */}

                {/* <div nao utilizar
                  className="col-md-12 d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div style={{ fontSize: "22px" }}>{this.state.titulo}</div>
                </div> */}

                <div
                  className="col-md-12 d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className="ql-editor"
                    style={{
                      maxWidth: "1000px",
                      width: "1000px"
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.html,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TemplateImpressao;
