import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { validarSenha } from "../../utils";

class Atendente extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.toggleModalDesbloquear = this.toggleModalDesbloquear.bind(this);
    this.handleClickDesbloquear = this.handleClickDesbloquear.bind(this);
    this.handleClickConfirmDesbloquear =
      this.handleClickConfirmDesbloquear.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.buscarAtendentes = this.buscarAtendentes.bind(this);
    this.buscarAtividades = this.buscarAtividades.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);

    this.state = {
      atendentes: [],
      modalDeletar: false,
      idAtendenteDeletar: null,
      modalDesbloquear: false,
      senhaDesbloquear: "",
      senhaRepetirDesbloquear: "",
      idAtendenteDesbloquear: null,
      descBuscar: "",
      statusBuscar: "A",
      atividadeSelecionada: null,
      atividades: [],
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/atendentes`, this.props.parameters())
      .then((response) => {
        this.setState({ atendentes: response.data.atendentes });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/atendentes-visualizar?id=${id}`);
  }

  handleClickDeletar(id) {
    this.setState({ idAtendenteDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  async handleConfirmDeletar() {
    let id = this.state.idAtendenteDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/atendentes/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ idAtendenteDeletar: null }, async () => {
          this.toggleModalDeletar();
          await this.load();
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  toggleModalDesbloquear() {
    this.setState({ modalDesbloquear: !this.state.modalDesbloquear });
  }

  handleClickDesbloquear(id) {
    this.setState(
      {
        idAtendenteDesbloquear: id,
        senhaDesbloquear: "",
        senhaRepetirDesbloquear: "",
      },
      () => {
        this.toggleModalDesbloquear();
      }
    );
  }

  async handleClickConfirmDesbloquear() {
    let id = this.state.idAtendenteDesbloquear;

    let senha = this.state.senhaDesbloquear;
    let senhaRepetir = this.state.senhaRepetirDesbloquear;

    if (senha === "" || senha !== senhaRepetir) {
      this.notificar("warning", "As senhas não conferem");
      return false;
    }

    if (!validarSenha(senha)) {
      this.notificar(
        "warning",
        "A senha deve ter no mínimo 6 caracteres, deve ter um carácter especial, uma letra maiúscula e pelo menos uma minúscula."
      );
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-desbloquear/${id}`,
        { senha: this.state.senhaDesbloquear },
        this.props.parameters()
      )
      .then((response) => {
        this.setState(
          {
            idAtendenteDesbloquear: null,
            senhaDesbloquear: "",
            senhaRepetirDesbloquear: "",
          },
          async () => {
            this.toggleModalDesbloquear();
            await this.load();
            this.notificar("success", "Atendente desbloqueado com sucesso!");
          }
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value });
  }

  async buscarAtendentes() {
    let desc = this.state.descBuscar;
    let status = this.state.statusBuscar;
    let atividade = this.state.atividadeSelecionada;
    let idAtividade = null;

    if (atividade !== null) {
      idAtividade = atividade.id;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-buscar`,
        { desc, status, idAtividade },
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ atendentes: response.data.atendentes });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarAtividades(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-atividades-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.atividades);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }

  promiseOptions(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atividades = await this.buscarAtividades(inputValue);

          atividades = atividades.map((atividade) => {
            atividade.label = atividade.desc;
            atividade.value = atividade.id;
            return atividade;
          });

          this.setState({ atividades });

          resolve(atividades);
        } else {
          if (inputValue.length >= 1) {
            let atividades = await this.buscarAtividades(inputValue);

            atividades = atividades.map((atividade) => {
              atividade.label = atividade.desc;
              atividade.value = atividade.id;
              return atividade;
            });
            this.setState({ atividades });
            resolve(atividades);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  limparFiltros() {
    this.setState(
      {
        descBuscar: "",
        statusBuscar: "A",
        descAtividadeBuscar: "",
        atividadeSelecionada: null,
      },
      () => {
        this.buscarAtendentes();
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atendentes" />
          <div className="main-panel">
            <NavBar title="Atendentes" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Atendentes</h4>
                      <div className="content-btn-novo-item">
                        {" "}
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.props.history.push("/atendentes-novo");
                          }}
                        >
                          Novo Atendente
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row float-left w-100">
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>Nome</label>
                            <input
                              type="text"
                              className={`form-control`}
                              placeholder="Descrição"
                              value={this.state.descBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "descBuscar"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Status</label>
                            <select
                              className="form-control form-control-select"
                              value={this.state.statusBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "statusBuscar"
                                )
                              }
                            >
                              <option value="TODOS">TODOS</option>
                              <option value="A">ATIVO</option>
                              <option value="B">BLOQUEADO</option>
                              <option value="I">INATIVO</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>Atividade</label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptions}
                              value={this.state.atividadeSelecionada}
                              onChange={(item) =>
                                this.handleChangeInput(
                                  item,
                                  "atividadeSelecionada"
                                )
                              }
                              placeholder="Selecione"
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ display: "flex", marginTop: "20px" }}
                        >
                          <div className="form-group" style={{ float: "left" }}>
                            <label></label>
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.buscarAtendentes()}
                              id="tooltip-filtro"
                            >
                              <i
                                className="fa fa-filter"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target="tooltip-filtro"
                            >
                              Filtrar
                            </UncontrolledTooltip>
                          </div>
                          <div className="form-group">
                            <label></label>
                            <button
                              className="btn btn-danger btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.limparFiltros()}
                              id="tooltip-limpar-filtro"
                            >
                              <i
                                className="fa fa-eraser"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target="tooltip-limpar-filtro"
                            >
                              Limpar Filtro
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Login</th>
                            <th>Email</th>
                            <th>CPF</th>
                            <th>Status</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.atendentes.length > 0 &&
                              this.state.atendentes.map((atendente, key) => (
                                <tr key={key}>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.id}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.desc}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.login}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.email}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.cpf}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(atendente.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {atendente.status}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justifyContentCenter">
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-primary btn-round"
                                          onClick={() =>
                                            this.handleClickVisualizar(
                                              atendente.id
                                            )
                                          }
                                          id={`tooltip-editar-atendente-${atendente.id}`}
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar-atendente-${atendente.id}`}
                                        >
                                          Editar
                                        </UncontrolledTooltip>
                                      </div>
                                      <div style={{ margin: "0 3px" }}>
                                        <button
                                          className="btn btn-primary btn-round"
                                          onClick={() =>
                                            this.handleClickDesbloquear(
                                              atendente.id
                                            )
                                          }
                                          disabled={
                                            atendente.status !== "B"
                                              ? true
                                              : false
                                          }
                                          id={`tooltip-desbloquear-atendente-${atendente.id}`}
                                        >
                                          <i className="fa fa-unlock"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-desbloquear-atendente-${atendente.id}`}
                                        >
                                          Desbloquear
                                        </UncontrolledTooltip>
                                      </div>
                                      <div style={{ margin: "0 3px" }}>
                                        <button
                                          className="btn btn-danger btn-round"
                                          onClick={() =>
                                            this.handleClickDeletar(
                                              atendente.id
                                            )
                                          }
                                          id={`tooltip-deletar-atendente-${atendente.id}`}
                                          disabled={
                                            atendente.status === "I"
                                              ? true
                                              : false
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-deletar-atendente-${atendente.id}`}
                                        >
                                          Deletar
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalDeletar}
          toggle={this.toggleModalDeletar}
        >
          <ModalHeader toggle={this.toggleModalDeletar}>
            Deletar Atendente
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este atendente?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalDesbloquear}
          toggle={this.toggleModalDesbloquear}
        >
          <ModalHeader toggle={this.toggleModalDesbloquear}>
            Desbloquear Atendente
          </ModalHeader>
          <ModalBody>
            <div>Informe a nova senha para desbloquear este atendente.</div>

            <div className="row d-flex flex-wrap">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Senha</label>
                  <input
                    type="password"
                    className={`form-control`}
                    placeholder="Senha"
                    value={this.state.senhaDesbloquear}
                    onChange={(e) => {
                      this.handleChangeInput(
                        e.target.value,
                        "senhaDesbloquear"
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Repetir Senha</label>
                  <input
                    type="password"
                    className={`form-control`}
                    placeholder="Senha"
                    value={this.state.senhaRepetirDesbloquear}
                    onChange={(e) => {
                      this.handleChangeInput(
                        e.target.value,
                        "senhaRepetirDesbloquear"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDesbloquear}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmDesbloquear}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Atendente;
