import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  let className = "form-control"

  if(inputProps.className !== undefined){
    className = `${className} ${inputProps.className}`;
    delete inputProps.className;
  }
  return (
    <MaskedInput
      className={className}
      mask={currencyMask}
      {...inputProps}
    />
  );
};

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

export default CurrencyInput;
