import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { formatarReal } from "../../utils";

class Atendimento extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.buscarAtividades = this.buscarAtividades.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.buscarAtendimentos = this.buscarAtendimentos.bind(this);
    this.buscarAtendentes = this.buscarAtendentes.bind(this);
    this.promiseOptionsAtendentes = this.promiseOptionsAtendentes.bind(this);
    this.promiseOptionsConsultantes =
      this.promiseOptionsConsultantes.bind(this);
    this.buscarConsultantes = this.buscarConsultantes.bind(this);
    this.buscarMotivosCancelamento = this.buscarMotivosCancelamento.bind(this);
    this.toggleModalCancelar = this.toggleModalCancelar.bind(this);
    this.handleClickCancelar = this.handleClickCancelar.bind(this);
    this.handleConfirmCancelar = this.handleConfirmCancelar.bind(this);
    this.abrirWhatsapp = this.abrirWhatsapp.bind(this);

    this.state = {
      atendentes: [],
      atendenteSelecionado: null,
      consultantes: [],
      consultanteSelecionado: null,
      statusBuscar: "TODOS",
      atividades: [],
      atividadeSelecionada: null,

      dataInicial: new Date(),
      dataFinal: new Date(),

      atendimentos: [],

      idAtendenteDesbloquear: null,
      modalDeletar: false,
      idAtendenteDeletar: null,
      modalDesbloquear: false,
      motivosCancelamento: [],
      motivosCancelamentoSelecionado: null,
      modalCancelar: false,
      errors: {
        motivoCancelamento: false,
      },
      idAtendimentoCancelar: null,
      user: {
        nivel: 2,
      },
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    await this.buscarAtendimentos();
    await this.buscarMotivosCancelamento();
    let user = this.props.user();
    this.setState({ user });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/atendimentos-visualizar?id=${id}`);
  }

  handleClickDeletar(id) {
    this.setState({ idAtendenteDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value });
  }

  async buscarAtividades(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-atividades-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.atividades);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }
  async buscarAtendentes(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-atendentes-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.atendentes);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }
  async buscarConsultantes(desc) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/busca-consultantes-desc`,
          { desc },
          this.props.parameters()
        )
        .then((response) => {
          resolve(response.data.consultantes);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
          reject(error);
        });
    });
  }

  promiseOptions(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atividades = await this.buscarAtividades(inputValue);

          atividades = atividades.map((atividade) => {
            atividade.label = atividade.desc;
            atividade.value = atividade.id;
            return atividade;
          });

          this.setState({ atividades });

          resolve(atividades);
        } else {
          if (inputValue.length >= 1) {
            let atividades = await this.buscarAtividades(inputValue);

            atividades = atividades.map((atividade) => {
              atividade.label = atividade.desc;
              atividade.value = atividade.id;
              return atividade;
            });
            this.setState({ atividades });
            resolve(atividades);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  promiseOptionsAtendentes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atendentes = await this.buscarAtendentes(inputValue);

          atendentes = atendentes.map((atendente) => {
            atendente.label = atendente.desc;
            atendente.value = atendente.id;
            return atendente;
          });

          this.setState({ atendentes });

          resolve(atendentes);
        } else {
          if (inputValue.length >= 1) {
            let atendentes = await this.buscarAtendentes(inputValue);

            atendentes = atendentes.map((atendente) => {
              atendente.label = atendente.desc;
              atendente.value = atendente.id;
              return atendente;
            });
            this.setState({ atendentes });
            resolve(atendentes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  promiseOptionsConsultantes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let consultantes = await this.buscarConsultantes(inputValue);

          consultantes = consultantes.map((consultante) => {
            consultante.label = consultante.desc;
            consultante.value = consultante.id;
            return consultante;
          });

          this.setState({ consultantes });

          resolve(consultantes);
        } else {
          if (inputValue.length >= 1) {
            let consultantes = await this.buscarConsultantes(inputValue);

            consultantes = consultantes.map((consultante) => {
              consultante.label = consultante.desc;
              consultante.value = consultante.id;
              return consultante;
            });
            this.setState({ consultantes });
            resolve(consultantes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  limparFiltros() {
    this.setState(
      {
        atendenteSelecionado: null,
        consultanteSelecionado: null,
        atividadeSelecionada: null,
        status: "TODOS",
      },
      () => {
        this.buscarAtendimentos();
      }
    );
  }

  async buscarAtendimentos() {
    let status = "";
    let atendente = "";
    let consultante = "";
    let atividade = "";
    let dataInicial = moment(this.state.dataInicial);
    let dataFinal = moment(this.state.dataFinal);

    if (this.state.atendenteSelecionado !== null) {
      atendente = this.state.atendenteSelecionado.id;
    }
    if (this.state.consultanteSelecionado !== null) {
      consultante = this.state.consultanteSelecionado.id;
    }
    if (this.state.atividadeSelecionada !== null) {
      atividade = this.state.atividadeSelecionada.id;
    }

    status = this.state.statusBuscar;

    if (!dataInicial.isSameOrBefore(dataFinal)) {
      this.notificar("warning", "Data inicial deve ser menor que data final");
      return false;
    } else {
      dataInicial = dataInicial.format("YYYY-MM-DD");
      dataFinal = dataFinal.format("YYYY-MM-DD");
    }
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendimentos?status=${status}&atendente=${atendente}&consultante=${consultante}&atividade=${atividade}&datainicial=${dataInicial}&datafinal=${dataFinal}`,

        this.props.parameters()
      )
      .then((response) => {
        this.setState({ atendimentos: response.data.atendimentos });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarMotivosCancelamento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/motivos-cancelamento`,
        this.props.parameters()
      )
      .then(async (response) => {
        let motivosCancelamento = response.data.motivosCancelamento;

        motivosCancelamento = motivosCancelamento.map((motivo) => {
          motivo.value = motivo.id;
          motivo.label = motivo.desc;
          return motivo;
        });
        this.setState({
          motivosCancelamento,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalCancelar() {
    this.setState({ modalCancelar: !this.state.modalCancelar });
  }

  handleClickCancelar(id) {
    this.setState({ idAtendimentoCancelar: id }, () => {
      this.toggleModalCancelar();
    });
  }

  async handleConfirmCancelar() {
    let motivoCancelamento = this.state.motivosCancelamentoSelecionado;

    if (motivoCancelamento === null) {
      this.notificar("warning", "Selecione o motivo de cancelamento");
      this.setState({
        errors: {
          ...this.state.errors,
          motivoCancelamento: true,
        },
      });
      return false;
    }

    let id = this.state.idAtendimentoCancelar;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendimentos-status/${id}`,
        {
          novoStatus: "CANCELADO",
          idMotivoCancelamento: motivoCancelamento.id,
        },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Atendimento cancelado");
        this.toggleModalCancelar();
        this.buscarAtendimentos();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  abrirWhatsapp(numero) {
    numero = numero.replace(/\D/g, "");
    window
      .open(`https://api.whatsapp.com/send?phone=${numero}`, "_blank")
      .focus();
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atendimentos" />
          <div className="main-panel">
            <NavBar title="Atendimentos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Atendimentos</h4>
                      {this.state.user.nivel === 2 && (
                        <div className="content-btn-novo-item">
                          {" "}
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              this.props.history.push(
                                `/atendimentos-novo?atendente=${this.state.user.id}`
                              );
                            }}
                          >
                            Novo Atendimento
                          </button>
                        </div>
                      )}
                      {this.state.user.nivel !== 2 && (
                        <div className="content-btn-novo-item">
                          {" "}
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              this.props.history.push(`/atendimentos-novo`);
                            }}
                          >
                            Novo Atendimento
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="row float-left w-100">
                        {this.state.user.nivel !== 2 && (
                          <div className="col-md-3 pr-1">
                            <div className="form-group">
                              <label>Atendente</label>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={this.promiseOptionsAtendentes}
                                value={this.state.atendenteSelecionado}
                                onChange={(item) =>
                                  this.handleChangeInput(
                                    item,
                                    "atendenteSelecionado"
                                  )
                                }
                                placeholder="Selecione"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>Paciente</label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptionsConsultantes}
                              value={this.state.consultanteSelecionado}
                              onChange={(item) =>
                                this.handleChangeInput(
                                  item,
                                  "consultanteSelecionado"
                                )
                              }
                              placeholder="Selecione"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Status</label>
                            <select
                              className="form-control form-control-select"
                              value={this.state.statusBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "statusBuscar"
                                )
                              }
                            >
                              <option value="TODOS">TODOS</option>
                              <option value="NOVO">NOVO</option>
                              <option value="PENDENTE">PENDENTE</option>
                              <option value="ATENDENDO">ATENDENDO</option>
                              <option value="CANCELADO">CANCELADO</option>
                              <option value="FINALIZADO">FINALIZADO</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>Atividade</label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={this.promiseOptions}
                              value={this.state.atividadeSelecionada}
                              onChange={(item) =>
                                this.handleChangeInput(
                                  item,
                                  "atividadeSelecionada"
                                )
                              }
                              placeholder="Selecione"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row float-left w-100">
                        <div className="col-md-2 pr-1">
                          <div className="form-group">
                            <label>Data Inicial</label>
                            <DatePicker
                              selected={this.state.dataInicial}
                              //withPortal
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataInicial");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 pr-1">
                          <div className="form-group">
                            <label>Data Final</label>
                            <DatePicker
                              selected={this.state.dataFinal}
                              //withPortal
                              customInput={<input className="form-control" />}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                this.handleChangeInput(date, "dataFinal");
                              }}
                              locale="pt-BR"
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ display: "flex", marginTop: "20px" }}
                        >
                          <div className="form-group" style={{ float: "left" }}>
                            <label></label>
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.buscarAtendimentos()}
                              id={`tooltip-filtro-atendimentos`}
                            >
                              <i
                                className="fa fa-filter"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-filtro-atendimentos`}
                            >
                              Filtrar
                            </UncontrolledTooltip>
                          </div>
                          <div className="form-group">
                            <label></label>
                            <button
                              className="btn btn-danger btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.limparFiltros()}
                              id={`tooltip-limpar-filtro-atendimentos`}
                            >
                              <i
                                className="fa fa-eraser"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-limpar-filtro-atendimentos`}
                            >
                              Limpar filtro
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>Código</th>
                            <th className="text-right">Horário</th>
                            <th className="text-right">Previsão</th>
                            <th className="text-center">Status</th>
                            {this.state.user.nivel !== 2 && <th>Atendente</th>}
                            <th>Paciente</th>
                            <th>Atividade</th>
                            <th className="text-right">Valor</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.atendimentos.length > 0 &&
                              this.state.atendimentos.map(
                                (atendimento, key) => (
                                  <tr key={key}>
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {atendimento.id
                                          .toString()
                                          .padStart(4, "0")}
                                      </span>
                                    </td>
                                    <td className="text-right">
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {atendimento.dataFormatada} <br />{" "}
                                        {atendimento.horario}
                                      </span>
                                    </td>
                                    <td className="text-right">
                                      <span>
                                        {atendimento.previsaoHoraFinal}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <span
                                        className={`tag ${atendimento.status}`}
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {atendimento.status}
                                      </span>
                                    </td>

                                    {this.state.user.nivel !== 2 && (
                                      <td>
                                        <span
                                          onClick={() =>
                                            this.handleClickVisualizar(
                                              atendimento.id
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {atendimento.descAtendente}
                                        </span>
                                      </td>
                                    )}
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {atendimento.descConsultante}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {atendimento.descAtividade}
                                      </span>
                                    </td>
                                    <td className="text-right">
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            atendimento.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        R${" "}
                                        {formatarReal(
                                          atendimento.valorOriginal
                                        )}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justifyContentCenter">
                                        <div
                                          style={{ margin: "0 3px" }}
                                          className="text-center"
                                        >
                                          <button
                                            className="btn btn-primary btn-round"
                                            onClick={() =>
                                              this.handleClickVisualizar(
                                                atendimento.id
                                              )
                                            }
                                            id={`tooltip-editar-atendimentos-${atendimento.id}`}
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-editar-atendimentos-${atendimento.id}`}
                                          >
                                            Editar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-danger btn-round"
                                            onClick={() =>
                                              this.handleClickCancelar(
                                                atendimento.id
                                              )
                                            }
                                            id={`tooltip-deletar-atendimentos-${atendimento.id}`}
                                            disabled={
                                              atendimento.status !== "NOVO"
                                                ? true
                                                : false
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-deletar-atendimentos-${atendimento.id}`}
                                          >
                                            Deletar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-success btn-round"
                                            onClick={() =>
                                              this.abrirWhatsapp(
                                                atendimento.celularConsultante
                                              )
                                            }
                                            id={`tooltip-abrir-whatsapp-${atendimento.id}`}
                                            disabled={
                                              atendimento.celularConsultante ===
                                              ""
                                                ? true
                                                : false
                                            }
                                          >
                                            <i
                                              className="fa fa-whatsapp"
                                              style={{
                                                cursor: "pointer",
                                                color: "#FFF",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-abrir-whatsapp-${atendimento.id}`}
                                          >
                                            Abrir Whatsapp
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalCancelar}
          toggle={this.toggleModalCancelar}
        >
          <ModalHeader toggle={this.toggleModalCancelar}>
            Você tem certeza que deseja cancelar?
          </ModalHeader>
          <ModalBody>
            Informe um motivo de cancelamento
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Motivo</label>
                  <Select
                    options={this.state.motivosCancelamento}
                    value={this.state.motivosCancelamentoSelecionado}
                    placeholder="Selecione"
                    onChange={(item) => {
                      this.handleChangeInput(
                        item,
                        "motivosCancelamentoSelecionado"
                      );
                    }}
                    className={`${
                      this.state.errors.motivoCancelamento ? "select-error" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalCancelar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmCancelar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Atendimento;
