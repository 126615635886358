import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import { SketchPicker } from "react-color";
import storage from '../../storage';

class Configuracao extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleClickSalvarTema = this.handleClickSalvarTema.bind(this);

    this.state = {
      senha: "",
      senhaRepetir: "",
      errors: {
        senha: false,
        senhaRepetir: false,
      },
      tema: props.getTema(),
      hexSidebar: props.getTema().sidebar,
      hexTextoSidebar: props.getTema().textoSidebar,
      hexTextoPrincipal: props.getTema().textoPrincipal,
    };
  }

  async componentDidMount() {}

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(e, tipo) {
    let value = e.target.value;
    this.setState({ [tipo]: value });
  }

  async handleClickCadastrar() {
    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os campos corretamente!");
      return false;
    }

    let senha = this.state.senha;
    let senhaRepetir = this.state.senhaRepetir;

    if (senha !== senhaRepetir) {
      this.notificar("warning", "As senhas não conferem");
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/configuracoes-atualizar-senha`,
        { senha },
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ senha: "", senhaRepetir: "" }, () => {
          this.notificar("success", "Configurações salvas com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleClickSalvarTema() {
    let sidebar = this.state.hexSidebar;
    let textoSidebar = this.state.hexTextoSidebar;
    let textoPrincipal = this.state.hexTextoPrincipal;

    let tema = {
      sidebar,
      textoSidebar,
      textoPrincipal,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atualizar-tema`,
        { tema },
        this.props.parameters()
      )
      .then((response) => {
        storage.setItem("tema", JSON.stringify(tema));
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.senha === "") {
      errors.senha = true;
      valido = false;
    } else {
      errors.senha = false;
    }
    if (this.state.senhaRepetir === "") {
      errors.senhaRepetir = true;
      valido = false;
    } else {
      errors.senhaRepetir = false;
    }

    this.setState({ errors });
    return valido;
  }

  handleColorChange(hex, tipo) {
    this.setState({ [tipo]: hex.hex });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="configuracoes" />
          <div className="main-panel">
            <NavBar title="Configurações" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">Configurações</h5>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-5 pr-1">
                            <div className="form-group">
                              <label>Senha</label>
                              <input
                                type="password"
                                className={`form-control ${
                                  this.state.errors.senha ? "input-error" : ""
                                }`}
                                placeholder="Senha"
                                value={this.state.senha}
                                onChange={(e) =>
                                  this.handleChangeInput(e, "senha")
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-5">
                            <div className="form-group">
                              <label>Repetir senha</label>
                              <input
                                type="password"
                                className={`form-control ${
                                  this.state.errors.senhaRepetir
                                    ? "input-error"
                                    : ""
                                }`}
                                placeholder="Repetir senha"
                                value={this.state.senhaRepetir}
                                onChange={(e) =>
                                  this.handleChangeInput(e, "senhaRepetir")
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 update">
                            <button
                              type="button"
                              className="btn btn-primary btn-round"
                              onClick={this.handleClickCadastrar}
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                      </form>

                      <div
                        className="row d-flex w-100 flex-wrap float-left"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="col-md-4">
                          <div style={{ padding: "10px 0" }}>
                            COR DO BACKGROUND MENU:
                          </div>
                          <SketchPicker
                            color={this.state.hexSidebar}
                            onChangeComplete={(color) =>
                              this.handleColorChange(color, "hexSidebar")
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <div style={{ padding: "10px 0" }}>
                            COR DO TEXTO MENU:
                          </div>
                          <SketchPicker
                            color={this.state.hexTextoSidebar}
                            onChangeComplete={(color) =>
                              this.handleColorChange(color, "hexTextoSidebar")
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <div style={{ padding: "10px 0" }}>
                            COR PRINCIPAL DO TEXTO:
                          </div>
                          <SketchPicker
                            color={this.state.hexTextoPrincipal}
                            onChangeComplete={(color) =>
                              this.handleColorChange(color, "hexTextoPrincipal")
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="row d-flex w-100 flex-wrap float-left"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="col-md-12 update">
                          <button
                            type="button"
                            className="btn btn-primary btn-round"
                            onClick={this.handleClickSalvarTema}
                          >
                            Salvar Tema
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Configuracao;
