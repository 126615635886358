import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, CurrencyInput } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import { formatarReal } from "../../utils";
import queryString from "query-string";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class AtividadeNova extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.buscarAtividade = this.buscarAtividade.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.toggleAba = this.toggleAba.bind(this);
    this.buscarAtendentes = this.buscarAtendentes.bind(this);
    this.onChangeNovosAtendentes = this.onChangeNovosAtendentes.bind(this);
    this.buscarAtendentesVinculados =
      this.buscarAtendentesVinculados.bind(this);
    this.toggleModalVinculo = this.toggleModalVinculo.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);
    this.toggleModalVinculoAtendente =
      this.toggleModalVinculoAtendente.bind(this);
    this.handleClickSalvarVinculo = this.handleClickSalvarVinculo.bind(this);
    this.handleChangeInputVinculo = this.handleChangeInputVinculo.bind(this);
    this.handleConfirmVincular = this.handleConfirmVincular.bind(this);
    this.validarCadastroInputs = this.validarCadastroInputs.bind(this);
    this.selecionarHorarios = this.selecionarHorarios.bind(this);
    this.toggleModalVinculoAtendenteEditar =
      this.toggleModalVinculoAtendenteEditar.bind(this);
    this.handleClickEditarVinculo = this.handleClickEditarVinculo.bind(this);
    this.selecionarHorarios2 = this.selecionarHorarios2.bind(this);
    this.handleChangeInputVinculoEditar =
      this.handleChangeInputVinculoEditar.bind(this);
    this.handleConfirmVincularEditar =
      this.handleConfirmVincularEditar.bind(this);

    this.state = {
      id: null,
      desc: "",
      tempo: "",
      valor: "0,00",
      dataCadastro: moment().format("DD/MM/YYYY"),
      actionPage: "CADASTRAR",
      abaAtiva: "dados", //dados, atendentes
      atendentesVinculados: [],
      atendentes: [],
      atendentesSelecionados: [],
      modalVinculo: false,
      idAtendenteDeletar: null,
      modalVinculoAtendente: false,
      errors: {
        desc: false,
        tempo: false,
        valor: false,
      },
      tema: props.getTema(),
      modalVinculoAtendenteEditar: false,
      atendentesSelecionadosEditar: [],
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      if (this.props.history.location.pathname === "/atividades-nova") {
        this.props.history.push(`/atividades-visualizar?id=${id}`);
      }
      id = parseInt(id);
      await this.buscarAtividade(id);
      await this.buscarAtendentesVinculados(id);
      await this.buscarAtendentes(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(e, tipo) {
    let value = e.target.value;
    this.setState({ [tipo]: value });
  }

  async handleClickCadastrar() {
    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os campos corretamente!");
      return false;
    }

    if (!(await this.validarCadastroInputs())) {
      return false;
    }

    let valor = this.state.valor;
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    let tempo = this.state.tempo;
    let horas = tempo.split(":")[0];
    let minutos = tempo.split(":")[1];
    horas = parseInt(horas);
    minutos = parseInt(minutos);

    if (horas > 0) {
      minutos += horas * 60;
    }

    let atividade = {
      desc: this.state.desc,
      tempo: minutos.toString(),
      valor,
    };

    let idAtendente = null;

    if (queryString.parse(this.props.location.search).atendente !== undefined) {
      idAtendente = parseInt(
        queryString.parse(this.props.location.search).atendente
      );
    }

    if (this.state.actionPage === "CADASTRAR") {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atividades`,
          { atividade, idAtendente },
          this.props.parameters()
        )
        .then((response) => {
          this.setState({ desc: "", tempo: "", valor: "0,00" }, () => {
            this.notificar("success", "Atividade cadastrada com sucesso!");

            let urlReturn = queryString.parse(
              this.props.location.search
            ).return
            ;
            if (urlReturn !== undefined) {
              let consultante = queryString.parse(
                this.props.location.search
              ).consultante;
              let convenio = queryString.parse(
                this.props.location.search
              ).convenio;
              let url = `/atendimentos-novo?atendente=${idAtendente}&atividade=${response.data.idAtividade}`;

              if (consultante !== undefined) {
                url = url + `&consultante=${consultante}`;
              }
              if (convenio !== undefined) {
                url = url + `&convenio=${convenio}`;
              }

              return this.props.history.push(url);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    } else {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atividades/${this.state.id}`,
          atividade,
          this.props.parameters()
        )
        .then((response) => {
          this.notificar("success", "Atividade editada com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    }
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    if (this.state.tempo === "") {
      errors.tempo = true;
      valido = false;
    } else {
      errors.tempo = false;
    }

    let valor = this.state.valor;
    if (valor === "") {
      valor = "0,00";
    }
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    if (valor === 0) {
      valido = false;
      errors.valor = true;
    } else {
      errors.valor = false;
    }

    this.setState({ errors });
    return valido;
  }

  async buscarAtividade(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atividades/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let data = response.data.atividade;

        if (data === null) {
          this.props.history.push("/atividades");
          return false;
        }

        let valor = data.valor;
        valor = valor.toString().replace(".", ",");

        if (valor.search(",") === -1) {
          valor = valor + ",00";
        }

        let horas = 0;
        let minutos = data.tempo;

        if (data.tempo > 59) {
          horas = parseInt(data.tempo / 60);
          minutos = minutos - horas * 60;
        }

        let horaFormatada = `${horas.toString().padStart(2, "0")}:${minutos
          .toString()
          .padStart(2, "0")}`;

        this.setState({
          desc: data.desc,
          dataCadastro: data.dataCadastro,
          tempo: horaFormatada,
          valor: valor,
          actionPage: "EDITAR",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  toggleAba(abaNova) {
    this.setState({ abaAtiva: abaNova });
  }

  async buscarAtendentesVinculados(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendentes-atividades/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let data = response.data.vinculos;

        this.setState({
          atendentesVinculados: data,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarAtendentes() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/atendentes-ativos`,
        this.props.parameters()
      )
      .then((response) => {
        let atendentes = response.data.atendentes;

        for (let atendente of atendentes) {
          atendente.value = atendente.id;
          atendente.label = atendente.desc;
          atendente.valido = true;

          for (let horario of atendente.listaHorarios) {
            horario.label = horario.dia;
            horario.value = horario.id;
          }

          atendente.listaHorariosSelecionados = [];
        }

        let atendentesVinculados = this.state.atendentesVinculados;

        for (let atendenteVinculado of atendentesVinculados) {
          for (let atendente of atendentes) {
            if (atendente.id === atendenteVinculado.idAtendente) {
              atendente.valido = false;
            }
          }
        }

        atendentes = atendentes.filter((atendente) => atendente.valido);

        this.setState({
          atendentes,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  onChangeNovosAtendentes(value) {
    for (let item of value) {
      item.valor = this.state.valor;
    }

    this.setState({ atendentesSelecionados: value });
  }

  toggleModalVinculo() {
    this.setState({ modalVinculo: !this.state.modalVinculo });
  }

  toggleModalVinculoAtendente() {
    this.setState({ modalVinculoAtendente: !this.state.modalVinculoAtendente });
  }

  handleClickDeletar(idAtendente) {
    this.setState({ idAtendenteDeletar: idAtendente }, () => {
      this.toggleModalVinculo();
    });
  }

  async handleConfirmDeletar() {
    let idAtendente = this.state.idAtendenteDeletar;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-atividades-teste`,
        { idAtendente, idAtividade: this.state.id },
        this.props.parameters()
      )
      .then((response) => {
        this.setState(
          {
            idAtendenteDeletar: null,
          },
          async () => {
            await this.buscarAtendentesVinculados(this.state.id);
            await this.buscarAtendentes(this.state.id);
            this.toggleModalVinculo();
            this.notificar("success", "Atendente desvinculado com sucesso!");
          }
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleClickSalvarVinculo() {
    this.toggleModalVinculoAtendente();
  }

  handleChangeInputVinculo(value, id) {
    let atendentes = this.state.atendentesSelecionados;

    for (let atendente of atendentes) {
      if (atendente.id === id) {
        atendente.valor = value;
      }
    }
  }

  handleChangeInputVinculoEditar(value, id) {
    let atendentes = this.state.atendentesSelecionadosEditar;

    for (let atendente of atendentes) {
      if (atendente.id === id) {
        atendente.valor = value;
      }
    }

    this.setState({ atendentesSelecionadosEditar: atendentes });
  }

  async handleConfirmVincular() {
    let atendentes = this.state.atendentesSelecionados;

    for (let atendente of atendentes) {
      let valor = atendente.valor;
      valor = valor.replace("R$", "");
      valor = valor.replace(",", ".");
      valor = parseFloat(valor);
      atendente.valor = valor;
    }

    atendentes = atendentes.map((atendente) => {
      return {
        idAtendente: atendente.id,
        idAtividade: this.state.id,
        valor: atendente.valor,
        listaHorarios: atendente.listaHorariosSelecionados,
      };
    });

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-atividades`,
        { atendentes },
        this.props.parameters()
      )
      .then(async (response) => {
        this.setState({ atendentesSelecionados: [] }, async () => {
          this.notificar("success", "Atendentes vinculados com sucesso!");
          await this.buscarAtendentes(this.state.id);
          await this.buscarAtendentesVinculados(this.state.id);
          this.toggleModalVinculoAtendente();
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleConfirmVincularEditar() {
    let atendentes = this.state.atendentesSelecionadosEditar;

    for (let atendente of atendentes) {
      atendente.valor = atendente.valor.toString();

      let valor = atendente.valor;
      valor = valor.replace("R$", "");
      valor = valor.replace(",", ".");
      valor = parseFloat(valor);
      atendente.valor = valor;
    }

    atendentes = atendentes.map((atendente) => {
      return {
        idAtendente: atendente.idAtendente,
        idAtividade: this.state.id,
        valor: atendente.valor,
        listaHorarios: atendente.listaHorariosSelecionados,
      };
    });

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/atendentes-atividades-editar`,
        { atendentes },
        this.props.parameters()
      )
      .then(async (response) => {
        this.toggleModalVinculoAtendenteEditar();
        this.setState({ atendentesSelecionadosEditar: [] }, async () => {
          this.notificar(
            "success",
            "Edição do vínculo de atendente realizada com sucesso!"
          );
          await this.buscarAtendentes(this.state.id);
          await this.buscarAtendentesVinculados(this.state.id);
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async validarCadastroInputs() {
    let valido = true;

    if (this.state.desc.length > 100) {
      valido = false;
      this.notificar(
        "warning",
        "A Descrição deve ter no máximo 100 caracteres"
      );
    }

    return valido;
  }

  selecionarHorarios(lista, atendente) {
    let atendentes = this.state.atendentesSelecionados;
    atendente.listaHorariosSelecionados = lista;

    for (let item of atendentes) {
      if (item.id === atendente.id) {
        item = atendente;
      }
    }

    this.setState({ atendentesSelecionados: atendentes });
  }

  selecionarHorarios2(lista, atendente) {
    let atendentes = this.state.atendentesSelecionadosEditar;
    atendente.listaHorariosSelecionados = lista;

    for (let item of atendentes) {
      if (item.id === atendente.id) {
        item = atendente;
      }
    }

    this.setState({ atendentesSelecionadosEditar: atendentes });
  }

  toggleModalVinculoAtendenteEditar() {
    this.setState({
      modalVinculoAtendenteEditar: !this.state.modalVinculoAtendenteEditar,
    });
  }

  handleClickEditarVinculo(atendente) {
    atendente.listaHorarios.map((it) => {
      it.label = it.dia;
      it.value = it.dia;
      return it;
    });

    let listaHorariosSelecionados = atendente.listaHorarios.filter(
      (it) => it.ativoAtividade
    );

    atendente.listaHorariosSelecionados = listaHorariosSelecionados;

    this.setState({ atendentesSelecionadosEditar: [{ ...atendente }] }, () => {
      this.toggleModalVinculoAtendenteEditar();
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atividades" />
          <div className="main-panel">
            <NavBar title="Atividades" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Cadastrar"
                          : "Editar"}{" "}
                        Atividades
                      </h5>
                    </div>

                    {this.state.actionPage === "EDITAR" && (
                      <div className="row padding-0-15">
                        <div className="col-md-12 pr-1">
                          <ul class="nav nav-tabs">
                            <li class="nav-item">
                              <div
                                class={`nav-link portal ${
                                  this.state.abaAtiva === "dados"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.toggleAba("dados")}
                                style={{ cursor: "pointer" }}
                              >
                                Dados
                              </div>
                            </li>
                            <li class="nav-item">
                              <div
                                class={`nav-link portal ${
                                  this.state.abaAtiva === "atendentes"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.toggleAba("atendentes")}
                                style={{ cursor: "pointer" }}
                              >
                                Atendentes Vinculados
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {this.state.abaAtiva === "dados" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Descrição</label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    this.state.errors.desc ? "input-error" : ""
                                  }`}
                                  placeholder="Descrição"
                                  value={this.state.desc}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "desc")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label>Tempo</label>
                                <input
                                  type="time"
                                  className={`form-control ${
                                    this.state.errors.tempo ? "input-error" : ""
                                  }`}
                                  placeholder="Tempo"
                                  value={this.state.tempo}
                                  onChange={(e) =>
                                    this.handleChangeInput(e, "tempo")
                                  }
                                  // disabled={
                                  //   this.state.actionPage === "EDITAR"
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Valor</label>

                                <CurrencyInput
                                  placeholder="R$0,00"
                                  type="text"
                                  value={this.state.valor}
                                  onChange={(e) => {
                                    this.handleChangeInput(e, "valor");
                                  }}
                                  onFocus={this.handleFocus}
                                  className={`form-control ${
                                    this.state.errors.valor ? "input-error" : ""
                                  }`}
                                />

                                {/* <input
                                type="text"
                                className={`form-control ${
                                  this.state.errors.valor ? "input-error" : ""
                                }`}
                                placeholder="Valor"
                                value={this.state.desc}
                                onChange={(e) =>
                                  this.handleChangeInput(e, "valor")
                                }
                              /> */}
                              </div>
                            </div>
                            {this.state.actionPage === "EDITAR" && (
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label>Data Cadastro</label>
                                  <input
                                    type="text"
                                    className={`form-control`}
                                    placeholder="Descrição"
                                    value={moment(
                                      this.state.dataCadastro
                                    ).format("DD/MM/YYYY")}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Salvar"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {this.state.abaAtiva === "atendentes" && (
                      <React.Fragment>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 pr-1">
                              <div className="form-group">
                                <label>Vincular novos atendentes</label>
                                <Select
                                  value={this.state.atendentesSelecionados}
                                  onChange={this.onChangeNovosAtendentes}
                                  options={this.state.atendentes}
                                  placeholder="Selecione os atendentes"
                                  isMulti={true}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive1">
                            <table className="table">
                              <thead
                                className="text-primary"
                                ref={(node) => {
                                  if (node) {
                                    node.style.setProperty(
                                      "color",
                                      this.state.tema.textoPrincipal,
                                      "important"
                                    );
                                  }
                                }}
                              >
                                <th style={{ borderTop: "none" }}>Nome</th>
                                <th
                                  style={{
                                    borderTop: "none",
                                    textAlign: "right",
                                  }}
                                >
                                  Valor
                                </th>
                                <th
                                  style={{
                                    borderTop: "none",
                                    textAlign: "center",
                                  }}
                                >
                                  Dias Atendimento
                                </th>
                                <th
                                  style={{
                                    borderTop: "none",
                                    textAlign: "right",
                                  }}
                                >
                                  Data Cadastro
                                </th>
                                <th
                                  style={{
                                    borderTop: "none",
                                    textAlign: "center",
                                  }}
                                >
                                  Ações
                                </th>
                              </thead>
                              <tbody>
                                {this.state.atendentesVinculados.length > 0 &&
                                  this.state.atendentesVinculados.map(
                                    (atendente, key) => (
                                      <tr key={key}>
                                        <td>{atendente.desc}</td>
                                        <td style={{ textAlign: "right" }}>
                                          R$ {formatarReal(atendente.valor)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {atendente.listaDias}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {moment(
                                            atendente.dataCadastro
                                          ).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="text-center">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div style={{ margin: "3px" }}>
                                              <button
                                                className="btn btn-danger btn-round"
                                                onClick={(e) =>
                                                  this.handleClickDeletar(
                                                    atendente.idAtendente
                                                  )
                                                }
                                                id={`tooltip-deletar-vinculo-atividades-${atendente.id}`}
                                              >
                                                <i
                                                  className="fa fa-times"
                                                  style={{ cursor: "pointer" }}
                                                ></i>
                                              </button>

                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`tooltip-deletar-vinculo-atividades-${atendente.id}`}
                                              >
                                                Deletar Vínculo
                                              </UncontrolledTooltip>
                                            </div>
                                            <div style={{ margin: "3px" }}>
                                              <button
                                                className="btn btn-info btn-round"
                                                onClick={(e) =>
                                                  this.handleClickEditarVinculo(
                                                    atendente
                                                  )
                                                }
                                                id={`tooltip-deletar-vinculo-atividades33-${atendente.id}`}
                                              >
                                                <i
                                                  className="fa fa-edit"
                                                  style={{ cursor: "pointer" }}
                                                ></i>
                                              </button>

                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`tooltip-deletar-vinculo-atividades33-${atendente.id}`}
                                              >
                                                Editar Vínculo
                                              </UncontrolledTooltip>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>

                            <div className="row">
                              <div className="col-md-12 update">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-round"
                                  onClick={this.handleClickSalvarVinculo}
                                  disabled={
                                    this.state.atendentesSelecionados.length ===
                                    0
                                      ? true
                                      : false
                                  }
                                >
                                  SALVAR
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalVinculo}
          toggle={this.toggleModalVinculo}
        >
          <ModalHeader toggle={this.toggleModalVinculo}>
            Desvincular Atendente
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja desvincular este atendente?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalVinculo}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalVinculoAtendente}
          toggle={this.toggleModalVinculoAtendente}
          className="modal-atividade"
        >
          <ModalHeader toggle={this.toggleModalVinculoAtendente}>
            Vincular novos atendentes
          </ModalHeader>
          <ModalBody>
            {/* <div className="table-responsive"> */}
            <table className="table">
              <thead
                className="text-primary"
                ref={(node) => {
                  if (node) {
                    node.style.setProperty(
                      "color",
                      this.state.tema.textoPrincipal,
                      "important"
                    );
                  }
                }}
              >
                <th style={{ borderTop: "none" }}>Nome</th>
                <th style={{ borderTop: "none", textAlign: "right" }}>Valor</th>
                <th style={{ borderTop: "none", textAlign: "right" }}>Dias</th>
              </thead>
              <tbody>
                {this.state.atendentesSelecionados.length &&
                  this.state.atendentesSelecionados.map((atendente, key) => (
                    <tr key={key}>
                      <td>{atendente.desc}</td>
                      <td style={{ textAlign: "right" }}>
                        <CurrencyInput
                          placeholder="R$0,00"
                          type="text"
                          value={atendente.valor}
                          onChange={(e) => {
                            this.handleChangeInputVinculo(
                              e.target.value,
                              atendente.id
                            );
                          }}
                          onFocus={this.handleFocus}
                          style={{ textAlign: "right" }}
                        />
                      </td>
                      <td>
                        {/* <MultiSelect
                          options={atendente.listaHorarios}
                          value={atendente.listaHorariosSelecionados}
                          onChange={(lista) =>
                            this.selecionarHorarios(lista, atendente)
                          }
                          labelledBy="Selecione"
                        /> */}

                        <Select
                          options={atendente.listaHorarios}
                          // value={this.state.atividadeSelecionada}
                          placeholder="Selecione"
                          onChange={(lista) => {
                            this.selecionarHorarios2(lista, atendente);
                          }}
                          // className={`${
                          //   this.state.errors.atividade
                          //     ? "select-error"
                          //     : ""
                          // }`}
                          // isDisabled={
                          //   this.state.atendenteSelecionado === null
                          //     ? true
                          //     : false
                          // }
                          isMulti
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* </div> */}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalVinculoAtendente}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmVincular}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalVinculoAtendenteEditar}
          toggle={this.toggleModalVinculoAtendenteEditar}
          className="modal-atividade"
        >
          <ModalHeader toggle={this.toggleModalVinculoAtendenteEditar}>
            Editar Vinculo de Atendente
          </ModalHeader>
          <ModalBody>
            <table className="table">
              <thead
                className="text-primary"
                ref={(node) => {
                  if (node) {
                    node.style.setProperty(
                      "color",
                      this.state.tema.textoPrincipal,
                      "important"
                    );
                  }
                }}
              >
                <th style={{ borderTop: "none" }}>Nome</th>
                <th style={{ borderTop: "none", textAlign: "right" }}>Valor</th>
                <th style={{ borderTop: "none", textAlign: "right" }}>Dias</th>
              </thead>
              <tbody>
                {this.state.atendentesSelecionadosEditar.length &&
                  this.state.atendentesSelecionadosEditar.map(
                    (atendente, key) => (
                      <tr key={key}>
                        <td>{atendente.desc}</td>
                        <td style={{ textAlign: "right" }}>
                          <CurrencyInput
                            placeholder="R$0,00"
                            type="text"
                            value={atendente.valor}
                            onChange={(e) => {
                              this.handleChangeInputVinculoEditar(
                                e.target.value,
                                atendente.id
                              );
                            }}
                            onFocus={this.handleFocus}
                            style={{ textAlign: "right" }}
                          />
                        </td>
                        <td>
                          <Select
                            options={atendente.listaHorarios}
                            value={atendente.listaHorariosSelecionados}
                            placeholder="Selecione"
                            onChange={(lista) => {
                              this.selecionarHorarios(lista, atendente);
                            }}
                            isMulti
                          />
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalVinculoAtendenteEditar}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmVincularEditar}
            >
              Editar
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AtividadeNova;
