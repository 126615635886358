import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError, MdLink } from "react-icons/md";
import "react-circular-progressbar/dist/styles.css";

const FileList = ({ files, onDelete }) => {
  return (
    <ul className="container-file-lista">
      {files.map((uploadedFile) => (
        <li key={uploadedFile.id}>
          <div className="drop-file-info">
            <div
              className="drop-preview"
              style={{ backgroundImage: `url(${uploadedFile.preview})` }}
            >
              {uploadedFile.previewClass !== null && (
                <div
                  style={{
                    fontSize: "30px",
                    position: "relative",
                    top: "-8px",
                    left: "5px",
                  }}
                >
                  <i className={uploadedFile.previewClass}> </i>
                </div>
              )}
            </div>
            <div className="drop-file-info-div">
              <strong>{uploadedFile.name}</strong>
              <span className="drop-file-info-span">
                {uploadedFile.readableSize}{" "}
                {!!uploadedFile.url && (
                  <button
                    className="drop-file-info-button"
                    onClick={() => onDelete(uploadedFile.id)}
                  >
                    Excluir
                  </button>
                )}
              </span>
            </div>
          </div>

          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: "#7159c1" },
                }}
                strokeWidth={10}
                percentage={uploadedFile.progress}
              />
            )}

            {uploadedFile.url && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
              </a>
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="#78e5d5" />
            )}
            {uploadedFile.error && <MdError size={24} color="#e57878" />}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FileList;
