import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getVariaveisTemplateAtendimento } from "../../utils";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class TemplateNovo extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.buscarTemplate = this.buscarTemplate.bind(this);
    this.inserirTexto = this.inserirTexto.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onClickVariavel = this.onClickVariavel.bind(this);
    this.quillRef = React.createRef();

    this.state = {
      id: null,
      tema: props.getTema(),
      actionPage: "CADASTRAR",
      titulo: "",
      value: "",
      texto:
        "Para adicionar variáveis ao template, envolva o nome da variavel com o prefixo <b>${}</b> como por exemplo: <b>${nome}</b> ",
      texto2:
        "Neste exemplo ao imprimir o template solicitará um valor para a variável <b>nome</b>",
      variaveisAtendimento: [],
      modal: false,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;
    let titulo = this.state.titulo;
    let actionPage = this.state.actionPage;

    if (id !== undefined) {
      actionPage = "EDITAR";
    } else {
      id = null;
      titulo = localStorage.getItem("nomeTemplate");

      if (titulo === null) {
        return this.props.history.push("/templates");
      }
    }

    let variaveisAtendimento = getVariaveisTemplateAtendimento();

    this.setState({ id, titulo, actionPage, variaveisAtendimento }, () => {
      if (actionPage === "EDITAR") {
        this.buscarTemplate(id);
      }
    });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(e, tipo) {
    let value = e.target.value;
    this.setState({ [tipo]: value });
  }

  async handleClickCadastrar() {
    function extrairNomes(texto) {
      const regex = /\$\{([^}]+)\}/g;
      const nomes = [];
      let match;

      while ((match = regex.exec(texto)) !== null) {
        nomes.push(match[1]);
      }

      return nomes;
    }

    let html = this.state.value;


    let titulo = this.state.titulo;
    let id = this.state.id;

    let url = `${this.props.getBaseUrlApi()}/templates`;

    let variaveis2 = extrairNomes(html);

    let variaveis = variaveis2.filter((element, index) => {
      return variaveis2.indexOf(element) === index;
    });

    const variaveisAtendimento = getVariaveisTemplateAtendimento();

    let variaveisFormatadas = [];

    for (let variavel of variaveis) {
      if (variaveisAtendimento.find((it) => it === variavel) !== undefined) {
        variaveisFormatadas.push({
          variavel,
          tipo: 0, //variveis de atendimento
        });
      } else {
        variaveisFormatadas.push({
          variavel,
          tipo: 1, //variveis criadas pelo usuário
        });
      }
    }

    await axios
      .post(
        `${url}`,
        { template: { id, titulo, html }, variaveis: variaveisFormatadas },
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ desc: "" }, () => {
          if (this.state.actionPage === "CADASTRAR") {
            this.notificar("success", "Template cadastrado com sucesso!");
          } else {
            this.notificar("success", "Template editado com sucesso!");
          }

          setTimeout(() => {
            return this.props.history.push("/templates");
          }, 1500);
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarTemplate(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/templates/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let data = response.data.template;

        if (data === null) {
          this.props.history.push("/templates");
          return false;
        }

        this.setState({
          titulo: data.titulo,
          value: data.html,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  onClickVariavel(variavel) {
    this.inserirTexto("${" + variavel + "}");
    this.toggleModal();
  }

  inserirTexto(texto) {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, texto);
    }
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="templates" />
          <div className="main-panel">
            <NavBar title="Templates" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Novo"
                          : "Editar"}{" "}
                        Template - {this.state.titulo}
                      </h5>
                      <small style={{ color: "gray" }}>
                        <div
                          dangerouslySetInnerHTML={{ __html: this.state.texto }}
                        ></div>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.texto2,
                          }}
                        ></div>
                      </small>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 text-right">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary btn-round"
                            onClick={() => {
                              this.toggleModal();
                            }}
                          >
                            Variáveis Atendimento
                          </button>
                        </div>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-12">
                            <ReactQuill
                              ref={this.quillRef}
                              theme="snow"
                              value={
                                this.state.value === ""
                                  ? `<br><br><br><br><br><br><br><br><br><br>`
                                  : this.state.value
                              }
                              onChange={(value) => {
                                this.setState({ value });
                              }}
                              modules={{
                                toolbar: [
                                  [{ font: [] }, { size: [] }],
                                  [{ align: [] }, "direction"],
                                  ["bold", "italic", "underline", "strike"],
                                  [{ color: [] }, { background: [] }],
                                  [{ align: [] }],
                                  [{ script: "super" }, { script: "sub" }],
                                  ["blockquote", "code-block"],
                                  [
                                    { list: "ordered" },
                                    { list: "bullet" },
                                    { indent: "-1" },
                                    { indent: "+1" },
                                  ],
                                  ["link", "image", "video"],
                                  ["clean"],
                                ],
                                history: {
                                  delay: 2000,
                                  maxStack: 500,
                                  userOnly: true,
                                },

                                clipboard: {
                                  matchVisual: false,
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 update">
                            <button
                              type="button"
                              className="btn btn-primary btn-round"
                              onClick={this.handleClickCadastrar}
                            >
                              {this.state.actionPage === "CADASTRAR"
                                ? "Cadastrar"
                                : "Editar"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Variáveis do Atendimento
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <table className="table">
                  <thead
                    className="text-primary"
                    ref={(node) => {
                      if (node) {
                        node.style.setProperty(
                          "color",
                          this.state.tema.textoPrincipal,
                          "important"
                        );
                      }
                    }}
                  >
                    <th style={{ borderTop: "none" }}>Nome</th>
                    <th
                      style={{
                        borderTop: "none",
                        textAlign: "center",
                      }}
                    >
                      Ação
                    </th>
                  </thead>
                  <tbody>
                    {this.state.variaveisAtendimento.length > 0 &&
                      this.state.variaveisAtendimento.map((it, key) => (
                        <tr key={key}>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.onClickVariavel(it);
                            }}
                          >
                            {it}
                          </td>
                          <td className="text-center">
                            <div className="d-flex justifyContentCenter">
                              <div
                                style={{ margin: "0 3px" }}
                                className="text-center"
                              >
                                <button
                                  className="btn btn-secondary btn-round"
                                  id={`tooltip-responder-convenios2-${key}`}
                                  onClick={() => {
                                    this.onClickVariavel(it);
                                  }}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`tooltip-responder-convenios2-${key}`}
                                >
                                  Selecionar
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <React.Fragment>
              <Button
                className="btn-modal-recuperar"
                color="secondary"
                onClick={this.toggleModal}
              >
                Fechar
              </Button>
            </React.Fragment>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TemplateNovo;
