import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, CurrencyInput } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import { formatarReal } from "../../utils";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import queryString from "query-string";

class AtendimentoNovo extends Component {
  constructor() {
    super();
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleAba = this.toggleAba.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.buscarConsultantes = this.buscarConsultantes.bind(this);
    this.promiseOptionsConsultantes =
      this.promiseOptionsConsultantes.bind(this);
    this.promiseOptionsAtendentes = this.promiseOptionsAtendentes.bind(this);
    this.buscarAtendentes = this.buscarAtendentes.bind(this);
    this.buscarAtividades = this.buscarAtividades.bind(this);
    this.buscarConvenios = this.buscarConvenios.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.toggle = this.toggle.bind(this);
    this.buscarHorariosDisponiveis = this.buscarHorariosDisponiveis.bind(this);
    this.validarHorario = this.validarHorario.bind(this);
    this.validarCadastroInputs = this.validarCadastroInputs.bind(this);
    this.handleClickNovaAtividade = this.handleClickNovaAtividade.bind(this);
    this.handleClickNovoConsultante =
      this.handleClickNovoConsultante.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      abaAtiva: "dados",
      id: null,
      actionPage: "CADASTRAR",
      errors: {
        consultante: false,
        atendente: false,
        atividade: false,
        data: false,
        tempoAtividade: false
      },
      consultanteSelecionado: null,
      consultantes: [],
      atendenteSelecionado: null,
      atendentes: [],
      atividadeSelecionada: null,
      atividades: [],
      data: new Date(),
      convenioSelecionado: null,
      convenios: [],
      horario: "",
      horariosDisponiveis: [],
      obs: "",
      valorAtividade: "0,00",
      modal: false,
      listaHorariosDisponiveis: [],
      carregarAtividades: true,
      user: {
        nivel: 2,
      },
      isAtendimentoEncaixe: false,
      tempoAtividade: ""
    };
  }

  async componentDidMount() {
    await this.buscarConvenios();

    let user = this.props.user();

    let idUsuario = parseInt(user.id);

    let idAtendente = queryString.parse(this.props.location.search).atendente;

    if (idAtendente !== undefined) {
      idAtendente = parseInt(idAtendente);
    }

    if (user.nivel === 2) {
      if (idAtendente !== idUsuario) {
        this.props.history.push("/home");
        return false;
      }
    }

    this.setState({ user });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  toggleAba(abaNova) {
    this.setState({ abaAtiva: abaNova });
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.consultanteSelecionado === null) {
      errors.consultante = true;
      valido = false;
    } else {
      errors.consultante = false;
    }

    if (this.state.atendenteSelecionado === null) {
      errors.atendente = true;
      valido = false;
    } else {
      errors.atendente = false;
    }

    if (this.state.atividadeSelecionada === null) {
      errors.atividade = true;
      valido = false;
    } else {
      errors.atividade = false;
    }

    // if (this.state.convenioSelecionado === null) {
    //   errors.convenio = true;
    //   valido = false;
    // } else {
    //   errors.convenio = false;
    // }

    if (this.state.horario === "") {
      errors.horario = true;
      valido = false;
    } else {
      errors.horario = false;
    }

    if(this.state.tempoAtividade === ""){
      errors.tempoAtividade = true;
      valido = false;
    }else{
      errors.tempoAtividade = false;
    }

    // if (
    //   moment(this.state.data).startOf("day").isBefore(moment().startOf("day"))
    // ) {
    //   errors.data = true;
    //   valido = false;
    // } else {
    //   errors.data = false;
    // }

    this.setState({ errors });
    return valido;
  }

  async buscarConsultantes(desc, setarConsultanteUrl) {
    return new Promise(async (resolve, reject) => {
      let consultanteCpf = queryString.parse(
        this.props.location.search
      ).consultante;

      if (consultanteCpf !== undefined && setarConsultanteUrl) {
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/busca-consultantes-id`,
            { id: consultanteCpf },
            this.props.parameters()
          )
          .then((response) => {
            resolve(response.data.consultantes);
          })
          .catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
              this.notificar("warning", error.response.data.erro);
              if (error.response.status === 401) {
                this.props.logout();
              }
            }
            reject(error);
          });
      } else {
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/busca-consultantes-desc`,
            { desc },
            this.props.parameters()
          )
          .then((response) => {
            resolve(response.data.consultantes);
          })
          .catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
              this.notificar("warning", error.response.data.erro);
              if (error.response.status === 401) {
                this.props.logout();
              }
            }
            reject(error);
          });
      }
    });
  }
  async buscarAtendentes(desc, setarAtendenteUrl) {
    return new Promise(async (resolve, reject) => {
      let atendente = queryString.parse(this.props.location.search).atendente;

      if (atendente !== undefined && setarAtendenteUrl) {
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/busca-atendentes-id`,
            { id: atendente },
            this.props.parameters()
          )
          .then((response) => {
            resolve(response.data.atendentes);
          })
          .catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
              this.notificar("warning", error.response.data.erro);
              if (error.response.status === 401) {
                this.props.logout();
              }
            }
            reject(error);
          });
      } else {
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/busca-atendentes-desc`,
            { desc },
            this.props.parameters()
          )
          .then((response) => {
            resolve(response.data.atendentes);
          })
          .catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
              this.notificar("warning", error.response.data.erro);
              if (error.response.status === 401) {
                this.props.logout();
              }
            }
            reject(error);
          });
      }
    });
  }

  async buscarAtividades() {
    let idAtendente = null;
    let atendente = this.state.atendenteSelecionado;

    if (atendente !== null) {
      idAtendente = atendente.id;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/busca-atividades-desc-atendente`,
        { desc: "", idAtendente },
        this.props.parameters()
      )
      .then((response) => {
        let atividades = response.data.atividades;
        atividades = atividades.map((atividade) => {
          atividade.label = `${atividade.desc} - ${atividade.tempo}m`;
          atividade.value = atividade.id;
          return atividade;
        });

        let atividade = queryString.parse(this.props.location.search).atividade;

        let atividadeSelecionada = this.state.atividadeSelecionada;
        let valorAtividade = this.state.valorAtividade;

        if (atividade !== undefined && this.state.carregarAtividades) {
          atividade = parseInt(atividade);
          let atividadeFind = atividades.find((ativ) => ativ.id === atividade);
          atividadeSelecionada = atividadeFind;
          valorAtividade = atividadeFind.valor;
        }

        this.setState({
          atividades,
          atividadeSelecionada,
          carregarAtividades: false,
          valorAtividade,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  promiseOptionsConsultantes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let consultantes = await this.buscarConsultantes(inputValue, true);

          consultantes = consultantes.map((consultante) => {
            consultante.label = consultante.desc;
            consultante.value = consultante.id;
            return consultante;
          });

          let consultanteCpf = queryString.parse(
            this.props.location.search
          ).consultante;

          if (consultanteCpf !== undefined) {
            this.setState({
              consultantes,
              consultanteSelecionado: consultantes[0],
            });
          } else {
            this.setState({ consultantes });
          }

          resolve(consultantes);
        } else {
          if (inputValue.length >= 1) {
            let consultantes = await this.buscarConsultantes(inputValue, false);

            consultantes = consultantes.map((consultante) => {
              consultante.label = consultante.desc;
              consultante.value = consultante.id;
              return consultante;
            });
            this.setState({ consultantes });
            resolve(consultantes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }
  promiseOptionsAtendentes(inputValue) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (inputValue === "") {
          let atendentes = await this.buscarAtendentes(inputValue, true);

          atendentes = atendentes.map((atendente) => {
            atendente.label = atendente.desc;
            atendente.value = atendente.id;
            return atendente;
          });

          let atendente = queryString.parse(
            this.props.location.search
          ).atendente;

          if (atendente !== undefined) {
            this.setState(
              {
                atendentes,
                atendenteSelecionado: atendentes[0],
              },
              () => {
                this.buscarAtividades();
              }
            );
          } else {
            this.setState({ atendentes });
          }

          resolve(atendentes);
        } else {
          if (inputValue.length >= 1) {
            let atendentes = await this.buscarAtendentes(inputValue, false);

            atendentes = atendentes.map((atendente) => {
              atendente.label = atendente.desc;
              atendente.value = atendente.id;
              return atendente;
            });
            this.setState({ atendentes });
            resolve(atendentes);
          } else {
            resolve([]);
          }
        }
      }, 100);
    });
  }

  handleChangeInput(value, tipo) {
    this.setState({ [tipo]: value }, () => {
      if (tipo === "atendenteSelecionado") {
        this.setState(
          {
            valorAtividade: 0,
            atividades: [],
            atividadeSelecionada: null,
            listaHorariosDisponiveis: [],
          },
          () => {
            this.buscarAtividades();
            this.buscarHorariosDisponiveis();
          }
        );
      }
      if (tipo === "atividadeSelecionada") {
        let tempo = value.tempo;
        let horas = 0;
        let minutos = tempo;

        if (tempo > 59) {
          horas = parseInt(tempo / 60);
          minutos = minutos - horas * 60;
        }

        let horaFormatada = `${horas.toString().padStart(2, "0")}:${minutos
          .toString()
          .padStart(2, "0")}`;


        this.setState(
          {
            valorAtividade: value.valor,
            listaHorariosDisponiveis: [],
            tempoAtividade: horaFormatada
          },
          () => {
            this.buscarHorariosDisponiveis();
          }
        );
      }
      if (tipo === "data") {
        this.setState({ listaHorariosDisponiveis: [] }, () => {
          this.buscarHorariosDisponiveis();
        });
      }
    });
  }

  async buscarConvenios() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/convenios`, this.props.parameters())
      .then((response) => {
        let convenios = response.data.convenios;
        convenios = convenios.map((convenio) => {
          convenio.label = convenio.desc;
          convenio.value = convenio.id;
          return convenio;
        });

        let convenio = queryString.parse(this.props.location.search).convenio;

        let convenioSelecionado = this.state.convenioSelecionado;

        if (convenio !== undefined) {
          convenio = parseInt(convenio);
          let convenioFind = convenios.find((conv) => conv.id === convenio);
          convenioSelecionado = convenioFind;
        }

        this.setState({ convenios, convenioSelecionado });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async handleClickCadastrar() {
    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os dados corretamente!");
      return false;
    }

    if (!(await this.validarCadastroInputs())) {
      return false;
    }

    let consultante = this.state.consultanteSelecionado;
    let atendente = this.state.atendenteSelecionado;
    let atividade = this.state.atividadeSelecionada;
    let convenio = this.state.convenioSelecionado;
    let data = this.state.data;
    let horario = this.state.horario;
    //data = moment(data).utc().format("YYYY-MM-DD");
    data = moment(data).format("YYYY-MM-DD");
    let obs = this.state.obs;
    let idAtendimentoPai = null;
    let valor = this.state.valorAtividade;
    let tempo = this.state.tempoAtividade;

    let atendimentoPai = queryString.parse(
      this.props.location.search
    ).atendimento;

    if (atendimentoPai !== undefined) {
      idAtendimentoPai = parseInt(atendimentoPai);
    }

    valor = valor.toString();
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    if(valor === ""){
     valor = 0
    }
    valor = parseFloat(valor);

    let horas = tempo.split(":")[0];
    let minutos = tempo.split(":")[1];
    horas = parseInt(horas);
    minutos = parseInt(minutos);

    if (horas > 0) {
      minutos += horas * 60;
    }

    let dataEnvio = {
      idAtendente: atendente.id,
      idAtividade: atividade.id,
      idConsultante: consultante.id,
      idConvenio: convenio === null ? null : convenio.id,
      data,
      horario,
      obs,
      modal: true,
      idAtendimentoPai,
      valor,
      tempo: minutos
    };

    let horarioValido = await this.validarHorario();

    if (horarioValido) {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atendimentos`,
          dataEnvio,
          this.props.parameters()
        )
        .then((response) => {
          this.notificar("success", "Atendimento cadastrado com sucesso");
          setTimeout(() => {
            window.location.href = `${this.props.getDomain()}/atendimentos-novo`;
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    } else {
      this.notificar("warning", "Horário não disponível.");
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async buscarHorariosDisponiveis() {
    let atendente = this.state.atendenteSelecionado;
    let atividade = this.state.atividadeSelecionada;

    if (atendente !== null && atividade !== null) {
      let idAtividade = atividade.id;
      let idAtendente = atendente.id;

      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atendentes-teste`,
          {
            idAtendente,
            idAtividade,
            data: this.state.data,
            isAtendimentoEncaixe: this.state.isAtendimentoEncaixe,
          },
          this.props.parameters()
        )
        .then((response) => {
          this.setState({
            listaHorariosDisponiveis: response.data.listaHorariosDisponiveis,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    }
  }

  async validarHorario() {
    let atendente = this.state.atendenteSelecionado;
    let atividade = this.state.atividadeSelecionada;

    if (atendente !== null && atividade !== null) {
      let idAtividade = atividade.id;
      let idAtendente = atendente.id;
      let valido = false;

      await axios
        .post(
          `${this.props.getBaseUrlApi()}/atendentes-teste1`,
          {
            idAtendente,
            idAtividade,
            horario: this.state.horario,
            data: this.state.data,
            isAtendimentoEncaixe: this.state.isAtendimentoEncaixe,
          },
          this.props.parameters()
        )
        .then((response) => {
          valido = response.data.valido;
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
      return valido;
    } else {
      return false;
    }
  }

  async validarCadastroInputs() {
    let valido = true;

    if (this.state.obs.length > 200) {
      valido = false;
      this.notificar(
        "warning",
        "A observação deve ter no máximo 200 caracteres"
      );
    }

    return valido;
  }

  handleClickNovaAtividade() {
    let atendenteSelecionado = this.state.atendenteSelecionado;
    let consultanteSelecionado = this.state.consultanteSelecionado;
    let convenioSelecionado = this.state.convenioSelecionado;

    if (atendenteSelecionado === null) {
      this.notificar(
        "warning",
        "Favor selecionar um atendente para adicionar a atividade!"
      );
      return false;
    }

    let urlAtendimento = `/atividades-nova?return=atendimentos-novo&atendente=${atendenteSelecionado.id}`;

    if (consultanteSelecionado !== null) {
      urlAtendimento = `${urlAtendimento}&consultante=${consultanteSelecionado.id}`;
    }

    if (convenioSelecionado !== null) {
      urlAtendimento = `${urlAtendimento}&convenio=${convenioSelecionado.id}`;
    }

    return this.props.history.push(urlAtendimento);
  }

  handleClickNovoConsultante() {
    let atendenteSelecionado = this.state.atendenteSelecionado;
    let convenioSelecionado = this.state.convenioSelecionado;
    let atividadeSelecionada = this.state.atividadeSelecionada;

    let urlNovoPaciente = `/consultantes-novo?return=atendimentos-novo`;

    if (atendenteSelecionado !== null) {
      urlNovoPaciente = `${urlNovoPaciente}&atendente=${atendenteSelecionado.id}`;
    }

    if (convenioSelecionado !== null) {
      urlNovoPaciente = `${urlNovoPaciente}&convenio=${convenioSelecionado.id}`;
    }

    if (atividadeSelecionada !== null) {
      urlNovoPaciente = `${urlNovoPaciente}&atividade=${atividadeSelecionada.id}`;
    }

    return this.props.history.push(urlNovoPaciente);
  }

  handleFocus(e) {
    e.target.select();
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="atendimentos" />
          <div className="main-panel">
            <NavBar title="Atendimentos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Cadastrar"
                          : "Editar"}{" "}
                        Atendimento
                      </h5>
                    </div>

                    {/* <div className="row padding-0-15">
                      <div className="col-md-12 pr-1">
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class={`nav-link portal ${
                                this.state.abaAtiva === "dados" ? "active" : ""
                              }`}
                              href="#"
                              onClick={() => this.toggleAba("dados")}
                            >
                              Dados
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class={`nav-link portal ${
                                this.state.abaAtiva === "contatoEndereco"
                                  ? "active"
                                  : ""
                              }`}
                              href="#"
                              onClick={() => this.toggleAba("contatoEndereco")}
                            >
                              Contato e Endereço
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    {this.state.abaAtiva === "dados" && (
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-5 pr-1">
                              <div
                                className="col-md-12 no-padding d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="col-md-11 no-padding">
                                  <div className="form-group">
                                    <label>Paciente</label>
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions
                                      loadOptions={
                                        this.promiseOptionsConsultantes
                                      }
                                      value={this.state.consultanteSelecionado}
                                      onChange={(item) =>
                                        this.handleChangeInput(
                                          item,
                                          "consultanteSelecionado"
                                        )
                                      }
                                      placeholder="Selecione"
                                      className={`${
                                        this.state.errors.consultante
                                          ? "select-error"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-1 no-padding">
                                  <button
                                    type="button"
                                    style={{
                                      marginLeft: "8px",
                                      marginTop: "18px",
                                    }}
                                    id="btn-novo-consultante"
                                    className="btn btn-sm btn-success"
                                    onClick={this.handleClickNovoConsultante}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>

                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`btn-novo-consultante`}
                                  >
                                    Novo Paciente
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 pr-1">
                              <div className="form-group">
                                <label for="exampleInputEmail1">
                                  Atendente
                                </label>
                                <AsyncSelect
                                  cacheOptions
                                  defaultOptions
                                  loadOptions={this.promiseOptionsAtendentes}
                                  value={this.state.atendenteSelecionado}
                                  onChange={(item) =>
                                    this.handleChangeInput(
                                      item,
                                      "atendenteSelecionado"
                                    )
                                  }
                                  placeholder="Selecione"
                                  className={`${
                                    this.state.errors.atendente
                                      ? "select-error"
                                      : ""
                                  }`}
                                  isDisabled={
                                    this.state.user.nivel === 2 ? true : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Convênio</label>
                                <Select
                                  options={this.state.convenios}
                                  value={this.state.convenioSelecionado}
                                  placeholder="Selecione"
                                  onChange={(item) => {
                                    this.handleChangeInput(
                                      item,
                                      "convenioSelecionado"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 pr-1">
                              <div
                                className="col-md-12 no-padding d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="col-md-11 no-padding">
                                  <div className="form-group">
                                    <label>Atividade</label>
                                    <Select
                                      options={this.state.atividades}
                                      value={this.state.atividadeSelecionada}
                                      placeholder="Selecione"
                                      onChange={(item) => {
                                        this.handleChangeInput(
                                          item,
                                          "atividadeSelecionada"
                                        );
                                      }}
                                      className={`${
                                        this.state.errors.atividade
                                          ? "select-error"
                                          : ""
                                      }`}
                                      isDisabled={
                                        this.state.atendenteSelecionado === null
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-1 no-padding">
                                  <button
                                    type="button"
                                    style={{
                                      marginLeft: "8px",
                                      marginTop: "18px",
                                    }}
                                    id="btn-nova-atividade"
                                    className="btn btn-sm btn-success"
                                    onClick={this.handleClickNovaAtividade}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>

                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`btn-nova-atividade`}
                                  >
                                    Nova Atividade
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Tempo</label>
                                <input
                                  type="time"
                                  className={`form-control ${
                                    this.state.errors.tempoAtividade ? "input-error" : ""
                                  }`}
                                  placeholder="Tempo"
                                  value={this.state.tempoAtividade}
                                  onChange={(e) =>
                                    this.handleChangeInput(e.target.value, "tempoAtividade")
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Valor</label>
                                <CurrencyInput
                                  placeholder="R$0,00"
                                  type="text"
                                  value={this.state.valorAtividade}
                                  onChange={(e) => {
                                    this.handleChangeInput(
                                      e.target.value,
                                      "valorAtividade"
                                    );
                                  }}
                                  onFocus={this.handleFocus}
                                  className={`form-control`}
                                />
                              </div>
                            </div>

                            <div className="col-md-2 pr-1">
                              <div className="form-group">
                                <label>Data</label>
                                <DatePicker
                                  selected={this.state.data}
                                  withPortal
                                  customInput={
                                    <input className="form-control font-size-13" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.handleChangeInput(date, "data");
                                  }}
                                  locale="pt-BR"
                                />
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div
                                className="form-group"
                                style={{ position: "relative" }}
                              >
                                <label>Horário</label>
                                <input
                                  type="time"
                                  className={`form-control ${
                                    this.state.errors.horario
                                      ? "input-error"
                                      : ""
                                  }`}
                                  onChange={(e) =>
                                    this.handleChangeInput(
                                      e.target.value,
                                      "horario"
                                    )
                                  }
                                />
                                <small
                                  onClick={() => {
                                    if (
                                      this.state.listaHorariosDisponiveis
                                        .length > 0
                                    ) {
                                      this.toggle();
                                    } else {
                                      if (
                                        this.state.atividadeSelecionada !==
                                          null &&
                                        this.state.atendenteSelecionado !== null
                                      ) {
                                        this.notificar(
                                          "warning",
                                          "O Atendente não possuí horário de atendimento disponível para este dia"
                                        );
                                      } else {
                                        this.notificar(
                                          "warning",
                                          "Favor selecionar atendente e atividade"
                                        );
                                      }
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                  }}
                                >
                                  Horários disponíveis
                                </small>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Encaixe ?</label>
                                <select
                                  className="form-control form-control-select"
                                  value={this.state.isAtendimentoEncaixe}
                                  onChange={(e) => {
                                    this.setState({
                                      isAtendimentoEncaixe:
                                        e.target.value === "true"
                                          ? true
                                          : false,
                                    });
                                  }}
                                >
                                  <option value="false">Não</option>
                                  <option value="true">Sim</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Observação</label>
                                <textarea
                                  className="form-control textarea"
                                  onChange={(e) =>
                                    this.handleChangeInput(
                                      e.target.value,
                                      "obs"
                                    )
                                  }
                                  value={this.state.obs}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Editar"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Horários disponíveis</ModalHeader>
          <ModalBody className="overflow-modal-body">
            <table className="table">
              <tbody>
                {this.state.listaHorariosDisponiveis.length > 0 &&
                  this.state.listaHorariosDisponiveis.map((item, key) => (
                    <React.Fragment>
                      {key === 0 ? (
                        <tr key={key}>
                          <td style={{ borderTopColor: "#FFF" }}>{item}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td> {item}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AtendimentoNovo;
