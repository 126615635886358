import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import moment from "moment";

class MotivoCancelamentoNovo extends Component {
  constructor(props) {
    super(props);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.validarCadastro = this.validarCadastro.bind(this);
    this.buscarMotivoCancelamento = this.buscarMotivoCancelamento.bind(this);
    this.validarCadastroInputs = this.validarCadastroInputs.bind(this);

    this.state = {
      id: null,
      desc: "",
      dataCadastro: moment().format("DD/MM/YYYY"),
      actionPage: "CADASTRAR",
      errors: {
        desc: false,
      },
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      if (
        this.props.history.location.pathname === "/motivos-cancelamento-novo"
      ) {
        this.props.history.push(`/motivos-cancelamento-visualizar?id=${id}`);
      }
      await this.buscarMotivoCancelamento(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(e, tipo) {
    let value = e.target.value;
    this.setState({ [tipo]: value });
  }

  async handleClickCadastrar() {
    if (!(await this.validarCadastro())) {
      this.notificar("warning", "Preencher todos os campos corretamente!");
      return false;
    }

    if (!(await this.validarCadastroInputs())) {
      return false;
    }

    let descMotivo = this.state.desc;

    if (this.state.actionPage === "CADASTRAR") {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/motivos-cancelamento`,
          { descMotivo },
          this.props.parameters()
        )
        .then((response) => {
          this.setState({ desc: "" }, () => {
            this.notificar(
              "success",
              "Motivo de cancelamento cadastrado com sucesso!"
            );
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response !== undefined) {
            this.notificar("warning", error.response.data.erro);
            if (error.response.status === 401) {
              this.props.logout();
            }
          }
        });
    }
  }

  async validarCadastro() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    this.setState({ errors });
    return valido;
  }

  async buscarMotivoCancelamento(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/motivos-cancelamento/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let data = response.data.motivoCancelamento;

        if (data === null) {
          this.props.history.push("/motivos-cancelamento");
          return false;
        }

        this.setState({
          desc: data.desc,
          dataCadastro: data.dataCadastro,
          actionPage: "EDITAR",
          id,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async validarCadastroInputs() {
    let valido = true;

    if (this.state.desc.length > 100) {
      valido = false;
      this.notificar(
        "warning",
        "A Descrição deve ter no máximo 100 caracteres"
      );
    }

    return valido;
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="motivosCancelamento" />"
          <div className="main-panel">
            <NavBar title="Motivos de cancelamento" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header">
                      <h5 className="card-title">
                        {this.state.actionPage === "CADASTRAR"
                          ? "Cadastrar"
                          : "Visualizar"}{" "}
                        Motivo de Cancelamento
                      </h5>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-5 pr-1">
                            <div className="form-group">
                              <label>Descrição</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  this.state.errors.desc ? "input-error" : ""
                                }`}
                                placeholder="Descrição"
                                value={this.state.desc}
                                onChange={(e) =>
                                  this.handleChangeInput(e, "desc")
                                }
                                disabled={
                                  this.state.actionPage === "EDITAR"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          {this.state.actionPage === "EDITAR" && (
                            <div className="col-md-5">
                              <div className="form-group">
                                <label>Data Cadastro</label>
                                <input
                                  type="text"
                                  className={`form-control`}
                                  placeholder="Descrição"
                                  value={moment(this.state.dataCadastro).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {this.state.actionPage === "CADASTRAR" && (
                          <div className="row">
                            <div className="col-md-12 update">
                              <button
                                type="button"
                                className="btn btn-primary btn-round"
                                onClick={this.handleClickCadastrar}
                              >
                                {this.state.actionPage === "CADASTRAR"
                                  ? "Cadastrar"
                                  : "Editar"}
                              </button>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MotivoCancelamentoNovo;
