require("dotenv").config();

const getItem = (item) => {
  let id = process.env.REACT_APP_DEV_ID_EMPRESA;
  return localStorage.getItem(`${item}_${id}`);
};

const setItem = (item, value) => {
  let id = process.env.REACT_APP_DEV_ID_EMPRESA;
  localStorage.setItem(`${item}_${id}`, value);
};

const removeItem = (item) => {
  let id = process.env.REACT_APP_DEV_ID_EMPRESA;
  localStorage.removeItem(`${item}_${id}`);
};

module.exports = { getItem, setItem, removeItem };
