import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Switch from "react-switch";

class FormularioNovo extends Component {
  constructor(props) {
    super(props);

    this.notificar = this.notificar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

    this.buscarFormulario = this.buscarFormulario.bind(this);
    this.toggleModalEditarTitulo = this.toggleModalEditarTitulo.bind(this);
    this.handleClickEditarTitulo = this.handleClickEditarTitulo.bind(this);
    this.apagarPergunta = this.apagarPergunta.bind(this);
    this.toggleModalApagarPergunta = this.toggleModalApagarPergunta.bind(this);
    this.toggleModalPergunta = this.toggleModalPergunta.bind(this);
    this.handleChangeInputModal = this.handleChangeInputModal.bind(this);

    this.handleFocus = this.handleFocus.bind(this);
    this.adicionarOpcaoPergunta = this.adicionarOpcaoPergunta.bind(this);
    this.handleChangeOpcao = this.handleChangeOpcao.bind(this);
    this.cadastrarPergunta = this.cadastrarPergunta.bind(this);
    this.buscarPergunta = this.buscarPergunta.bind(this);
    this.abrirModalEditarPergunta = this.abrirModalEditarPergunta.bind(this);
    this.abrirModalNovaPergunta = this.abrirModalNovaPergunta.bind(this);

    this.editarPergunta = this.editarPergunta.bind(this);
    this.apagarOpcaoPergunta = this.apagarOpcaoPergunta.bind(this);
    this.adicionarOpcaoOutros = this.adicionarOpcaoOutros.bind(this);
    this.handleChangePerguntaObrigatoria =
      this.handleChangePerguntaObrigatoria.bind(this);

    this.state = {
      id: null,
      formulario: null,
      modalEditarTitulo: false,
      nomeEditar: "",
      descricaoEditar: "",
      modalApagarPergunta: false,
      idPerguntaDeletar: null,
      modalPergunta: false,

      perguntaSelecionada: null,

      actionPage: "CADASTRAR",
      errors: {
        desc: false,
      },
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarFormulario(id);
    } else {
      this.props.history.push("/formularios");
      return false;
    }
  }

  async buscarFormulario(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let formulario = response.data.formulario;
        if(formulario === null){
          this.props.history.push('/formularios')
          return false;
        }
        this.setState({ id, formulario });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInput(e, tipo) {
    let value = e.target.value;
    this.setState({ [tipo]: value });
  }

  toggleModalEditarTitulo() {
    this.setState({ modalEditarTitulo: !this.state.modalEditarTitulo });
  }

  async handleClickEditarTitulo() {
    let nome = this.state.nomeEditar;
    let descricao = this.state.descricaoEditar;

    if (nome === "" || descricao === "") {
      this.notificar("warning", "Err");
      return false;
    }

    await axios
      .put(
        `${this.props.getBaseUrlApi()}/formularios/${this.state.id}`,
        { nome, descricao },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Título editado com sucesso!");
        this.buscarFormulario(this.state.id);
        this.toggleModalEditarTitulo();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async apagarPergunta() {
    let id = this.state.idPerguntaDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/formularios-pergunta/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Pergunta apagada com sucesso!");
        this.buscarFormulario(this.state.id);
        this.toggleModalApagarPergunta();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalApagarPergunta() {
    this.setState({ modalApagarPergunta: !this.state.modalApagarPergunta });
  }

  handleClickApagarPergunta(id) {
    this.setState({ idPerguntaDeletar: id }, () => {
      this.toggleModalApagarPergunta();
    });
  }

  toggleModalPergunta() {
    this.setState({ modalPergunta: !this.state.modalPergunta });
  }

  handleChangeInputModal(e, tipo) {
    let perguntaSelecionada = this.state.perguntaSelecionada;
    perguntaSelecionada[tipo] = e.target.value;
    this.setState({ perguntaSelecionada });
  }

  handleFocus(e) {
    e.target.select();
  }

  adicionarOpcaoPergunta() {
    let perguntaSelecionada = { ...this.state.perguntaSelecionada };
    let total = perguntaSelecionada.opcoes.length;
    perguntaSelecionada.opcoes.push({
      id: Math.random(),
      opcao: `Opção ${total + 1}`,
    });

    this.setState({ perguntaSelecionada });
  }

  handleChangeOpcao(e, id) {
    let perguntaSelecionada = { ...this.state.perguntaSelecionada };

    for (let op of perguntaSelecionada.opcoes) {
      if (op.id === id) {
        op.opcao = e.target.value;
      }
    }

    this.setState({ perguntaSelecionada });
  }

  async cadastrarPergunta() {
    let pergunta = this.state.perguntaSelecionada;
    pergunta.tipo = parseInt(pergunta.tipo);

    if (pergunta.titulo === "") {
      this.notificar("warning", "Favor informar um título para a pergunta!");
      return false;
    }

    if (pergunta.titulo.length > 1000) {
      this.notificar("warning", "O título deve ter no máximo 1000 caracteres");
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-pergunta/${this.state.id}`,
        { pergunta: pergunta },
        this.props.parameters()
      )
      .then((response) => {
        this.buscarPergunta(response.data.id);
        this.toggleModalPergunta();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async editarPergunta() {
    let pergunta = this.state.perguntaSelecionada;
    pergunta.tipo = parseInt(pergunta.tipo);

    if (pergunta.titulo === "") {
      this.notificar("warning", "Favor informar um título para a pergunta!");
      return false.valueOf;
    }

    await axios
      .put(
        `${this.props.getBaseUrlApi()}/formularios-pergunta/${this.state.id}`,
        { pergunta: pergunta },
        this.props.parameters()
      )
      .then((response) => {
        this.buscarPergunta(pergunta.id, false);
        this.toggleModalPergunta();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async buscarPergunta(id, perguntaNova = true) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios-pergunta/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let pergunta = response.data.pergunta;
        let formulario = this.state.formulario;

        if (perguntaNova) {
          formulario.perguntas.push(pergunta);
        } else {
          for (let i = 0; i < formulario.perguntas.length; i++) {
            let perg = formulario.perguntas[i];

            if (perg.id === pergunta.id) {
              formulario.perguntas[i] = pergunta;
            }
          }
        }

        this.setState({ formulario }, () => {
          if (perguntaNova) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  abrirModalEditarPergunta(pergunta) {
    let perguntaEditar = {
      dataCadastro: pergunta.dataCadastro,
      id: pergunta.id,
      idEmpresa: pergunta.idEmpresa,
      idForm: pergunta.idForm,
      obrigatoria: pergunta.obrigatoria,
      status: pergunta.status,
      tipo: pergunta.tipo,
      titulo: pergunta.titulo,
      opcoes: null,
    };

    if (perguntaEditar.tipo == 2 || perguntaEditar.tipo == 3) {
      perguntaEditar.opcoes = [];

      for (let op of pergunta.opcoes) {
        perguntaEditar.opcoes.push({
          id: op.id,
          opcao: op.opcao,
          outro: op.outro,
        });
      }
    }

    this.setState({ perguntaSelecionada: perguntaEditar }, () => {
      this.toggleModalPergunta();
    });
  }

  abrirModalNovaPergunta() {
    this.setState(
      {
        perguntaSelecionada: {
          id: null,
          titulo: "",
          obrigatoria: true,
          tipo: "0",
          opcoes: [
            {
              id: Math.random(),
              opcao: "Opção 1",
              outro: false,
            },
          ],
        },
      },
      () => {
        this.toggleModalPergunta();
      }
    );
  }

  apagarOpcaoPergunta(idOpcao) {
    let perguntaSelecionada = this.state.perguntaSelecionada;
    perguntaSelecionada.opcoes = perguntaSelecionada.opcoes.filter(
      (op) => op.id !== idOpcao
    );
    this.setState({ perguntaSelecionada });
  }

  adicionarOpcaoOutros() {
    let pergunta = this.state.perguntaSelecionada;
    pergunta.opcoes.push({
      id: Math.random(),
      opcao: "Outro",
      outro: true,
    });
    this.setState({ perguntaSelecionada: pergunta });
  }

  handleChangePerguntaObrigatoria(check) {
    this.setState({
      perguntaSelecionada: {
        ...this.state.perguntaSelecionada,
        obrigatoria: check,
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="formularios" />
          <div className="main-panel">
            <NavBar title="Formulários Dinâmicos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header card-header-pergunta d-flex">
                      <div className="col-10 no-padding">
                        <h5 className="card-title">
                          {this.state.formulario !== null && (
                            <React.Fragment>
                              <span>{this.state.formulario.nome}</span>
                            </React.Fragment>
                          )}
                        </h5>
                        <div className="">
                          {this.state.formulario !== null && (
                            <div>{this.state.formulario.descricao}</div>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-2 no-padding text-right"
                        style={{ fontSize: "20px" }}
                      >
                        <span style={{ margin: "0 3px" }}>
                          <i
                            className="fa fa-pencil"
                            onClick={() => {
                              this.setState(
                                {
                                  nomeEditar: this.state.formulario.nome,
                                  descricaoEditar:
                                    this.state.formulario.descricao,
                                },
                                () => {
                                  this.toggleModalEditarTitulo();
                                }
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#106ba4",
                            }}
                          ></i>
                        </span>
                        <span style={{ margin: " 0 3px" }}>
                          <i
                            className="fa fa-plus"
                            onClick={(e) => {
                              this.abrirModalNovaPergunta();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#106ba4",
                            }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /// */}

              {this.state.formulario !== null &&
                this.state.formulario.perguntas.map((pergunta) => (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-user card-form">
                          <div
                            className="card-header d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="col-10 no-padding">
                              <h5 className="card-title">
                                {pergunta.titulo}{" "}
                                {pergunta.obrigatoria && (
                                  <span
                                    style={{
                                      color: "#dc0c0c",
                                      fontSize: "18px",
                                    }}
                                  >
                                    *
                                  </span>
                                )}
                              </h5>
                            </div>
                            <div
                              className="col-2 no-padding text-right"
                              style={{ fontSize: "20px" }}
                            >
                              <span style={{ margin: "0 3px" }}>
                                <i
                                  className="fa fa-pencil"
                                  onClick={(e) => {
                                    this.abrirModalEditarPergunta(pergunta);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#106ba4",
                                  }}
                                ></i>
                              </span>
                              <span style={{ margin: " 0 3px" }}>
                                <i
                                  className="fa fa-trash"
                                  onClick={(e) => {
                                    this.handleClickApagarPergunta(pergunta.id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#dc0c0c",
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>

                          <div className="card-body card-form">
                            {pergunta.tipo == 0 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={"Resposta Curta"}
                                  disabled={true}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 1 && (
                              <div className="form-group">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  value={"Resposta Longa"}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 2 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="radio"
                                      value={op.opcao}
                                      disabled={true}
                                    />
                                    &nbsp;&nbsp;
                                    {op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {pergunta.tipo == 3 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={op.opcao}
                                      disabled={true}
                                    />
                                    &nbsp;&nbsp;
                                    {op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

              {/* //// */}
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalEditarTitulo}
          toggle={this.toggleModalEditarTitulo}
        >
          <ModalHeader toggle={this.toggleModalEditarTitulo}>
            Editar título
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Nome</label>
              <input
                type="text"
                className="form-control"
                value={this.state.nomeEditar}
                onChange={(e) => {
                  this.handleChangeInput(e, "nomeEditar");
                }}
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <input
                type="text"
                className="form-control"
                value={this.state.descricaoEditar}
                onChange={(e) => {
                  this.handleChangeInput(e, "descricaoEditar");
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalEditarTitulo}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickEditarTitulo}
            >
              Editar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalApagarPergunta}
          toggle={this.toggleModalApagarPergunta}
        >
          <ModalHeader toggle={this.toggleModalApagarPergunta}>
            Deletar Pergunta
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar esta pergunta?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalApagarPergunta}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.apagarPergunta}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalPergunta}
          toggle={this.toggleModalPergunta}
        >
          {this.state.perguntaSelecionada !== null && (
            <ModalHeader toggle={this.toggleModalPergunta}>
              {this.state.perguntaSelecionada.id === null
                ? "Cadastrar"
                : "Editar"}{" "}
              Pergunta
            </ModalHeader>
          )}
          <ModalBody>
            {this.state.perguntaSelecionada !== null && (
              <React.Fragment>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.perguntaSelecionada.titulo}
                        onChange={(e) => {
                          this.handleChangeInputModal(e, "titulo");
                        }}
                        placeholder="Título da pergunta"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-control"
                      value={this.state.perguntaSelecionada.tipo}
                      onChange={(e) => {
                        this.handleChangeInputModal(e, "tipo");
                      }}
                      disabled={
                        this.state.perguntaSelecionada.id !== null
                          ? true
                          : false
                      }
                    >
                      <option value="0">Resposta curta</option>
                      <option value="1">Resposta longa</option>
                      <option value="2">Escolha</option>
                      <option value="3">Múltipla Escolha</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.state.perguntaSelecionada.tipo == "0" && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={"Texto de resposta curta"}
                          disabled={true}
                        />
                      </div>
                    )}
                    {this.state.perguntaSelecionada.tipo == "1" && (
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control"
                          value={"Texto de resposta longa"}
                          disabled={true}
                        />
                      </div>
                    )}
                    {this.state.perguntaSelecionada.tipo == "2" && (
                      <div className="form-group">
                        <ul style={{ padding: 0 }}>
                          {this.state.perguntaSelecionada.opcoes.map((op) => (
                            <li
                              style={{
                                listStyleType: "none",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="col-8 d-flex no-padding">
                                  <input type="radio" />
                                  &nbsp;
                                  <input
                                    type="text"
                                    className="form-control form-pergunta"
                                    value={op.opcao}
                                    onFocus={(e) => {
                                      if (!op.outro) {
                                        this.handleFocus(e);
                                      }
                                    }}
                                    onChange={(e) => {
                                      this.handleChangeOpcao(e, op.id);
                                    }}
                                    disabled={op.outro ? true : false}
                                  />
                                </div>
                                <div className="col-4 no-padding text-right">
                                  {this.state.perguntaSelecionada.opcoes
                                    .length > 1 && (
                                    <i
                                      className="fa fa-times"
                                      style={{
                                        cursor: "pointer",
                                        color: "#CCC",
                                      }}
                                      onClick={() => {
                                        this.apagarOpcaoPergunta(op.id);
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}

                          <li
                            style={{
                              listStyleType: "none",
                              display: "flex",
                              paddingTop: "5px",
                            }}
                          >
                            <input type="radio"></input>
                            &nbsp;
                            <div>
                              <span
                                style={{
                                  color: "#bfbfd1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.adicionarOpcaoPergunta();
                                }}
                              >
                                &nbsp;Adicionar opção
                              </span>

                              {this.state.perguntaSelecionada.opcoes.filter(
                                (opc) => opc.outro
                              ).length === 0 && (
                                <span
                                  style={{
                                    color: "#bfbfd1",
                                  }}
                                >
                                  &nbsp;ou adicionar{" "}
                                  <span
                                    style={{
                                      color: "#13138c",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.adicionarOpcaoOutros();
                                    }}
                                  >
                                    "Outro"
                                  </span>
                                </span>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                    {this.state.perguntaSelecionada.tipo == "3" && (
                      <div className="form-group">
                        <ul style={{ padding: 0 }}>
                          {this.state.perguntaSelecionada.opcoes.map((op) => (
                            <li
                              style={{
                                listStyleType: "none",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="col-8 d-flex no-padding">
                                  <input type="checkbox" />
                                  &nbsp;
                                  <input
                                    type="text"
                                    className="form-control form-pergunta"
                                    value={op.opcao}
                                    onFocus={(e) => {
                                      if (!op.outro) {
                                        this.handleFocus(e);
                                      }
                                    }}
                                    onChange={(e) => {
                                      this.handleChangeOpcao(e, op.id);
                                    }}
                                    disabled={op.outro ? true : false}
                                  />
                                </div>
                                <div className="col-4 no-padding text-right">
                                  {this.state.perguntaSelecionada.opcoes
                                    .length > 1 && (
                                    <i
                                      className="fa fa-times"
                                      style={{
                                        cursor: "pointer",
                                        color: "#CCC",
                                      }}
                                      onClick={() => {
                                        this.apagarOpcaoPergunta(op.id);
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}

                          <li
                            style={{
                              listStyleType: "none",
                              display: "flex",
                              paddingTop: "5px",
                            }}
                          >
                            <input type="checkbox"></input>
                            &nbsp;
                            <div>
                              <span
                                style={{
                                  color: "#bfbfd1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.adicionarOpcaoPergunta();
                                }}
                              >
                                &nbsp;Adicionar opção
                              </span>

                              {this.state.perguntaSelecionada.opcoes.filter(
                                (opc) => opc.outro
                              ).length === 0 && (
                                <span
                                  style={{
                                    color: "#bfbfd1",
                                  }}
                                >
                                  &nbsp;ou adicionar{" "}
                                  <span
                                    style={{
                                      color: "#13138c",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.adicionarOpcaoOutros();
                                    }}
                                  >
                                    "Outro"
                                  </span>
                                </span>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </ModalBody>
          <div className="modal-footer modal-footer-pergunta">
            {this.state.perguntaSelecionada !== null && (
              <React.Fragment>
                <div className="d-flex w-100">
                  <div className="col-4 no-padding">
                    <div
                      style={{
                        color: "#888",
                        fontSize: "14px",
                      }}
                    >
                      Obrigatória
                    </div>
                    <div className="item-switch">
                      <Switch
                        checked={this.state.perguntaSelecionada.obrigatoria}
                        onChange={this.handleChangePerguntaObrigatoria}
                        onColor="#f0e8fc"
                        onHandleColor="#4c4f57"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={16}
                        width={48}
                        //                        disabled={props.disabled}
                      />
                    </div>
                  </div>
                  <div className="col-8 no-padding">
                    <div className="d-flex" style={{ justifyContent: "end" }}>
                      <Button
                        className="btn-modal-recuperar"
                        color="secondary"
                        onClick={this.toggleModalPergunta}
                      >
                        Cancelar
                      </Button>
                      <Button
                        className="btn-modal-recuperar"
                        color="primary"
                        onClick={() => {
                          if (this.state.perguntaSelecionada !== null) {
                            if (this.state.perguntaSelecionada.id === null) {
                              this.cadastrarPergunta();
                            } else {
                              this.editarPergunta();
                            }
                          }
                        }}
                      >
                        {this.state.perguntaSelecionada.id === null
                          ? "Cadastrar"
                          : "Editar"}
                      </Button>{" "}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FormularioNovo;
