import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class Formulario extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);
    this.toggleModalCadastro = this.toggleModalCadastro.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickCadastrar = this.handleClickCadastrar.bind(this);
    this.handleClickResponder = this.handleClickResponder.bind(this);
    this.toggleModalGerarLinkFormulario =
      this.toggleModalGerarLinkFormulario.bind(this);
    this.gerarLinkFormularioExterno =
      this.gerarLinkFormularioExterno.bind(this);

    this.state = {
      formularios: [],
      modalDeletar: false,
      idFormularioDeletar: null,
      tema: props.getTema(),

      modalCadastro: false,
      nome: "",
      descricao: "",
      modalGerarLinkFormulario: false,
      url: "",
    };
  }

  async componentDidMount() {
    this.load();
  }
  async load() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/formularios`, this.props.parameters())
      .then((response) => {
        this.setState({ formularios: response.data.formularios });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/formularios-visualizar?id=${id}`);
  }

  handleClickResponder(id) {
    this.props.history.push(`/formularios-responder?id=${id}`);
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  handleClickDeletar(id) {
    this.setState({ idFormularioDeletar: id }, () => {
      this.toggleModalDeletar();
    });
  }

  async handleConfirmDeletar() {
    let id = this.state.idFormularioDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/formularios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ idFormularioDeletar: null }, async () => {
          this.toggleModalDeletar();
          await this.load();
          this.notificar("success", "Formulário deletado com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalCadastro() {
    this.setState({ modalCadastro: !this.state.modalCadastro });
  }

  handleChangeInput(e, tipo) {
    this.setState({ [tipo]: e.target.value });
  }

  async handleClickCadastrar() {
    let nome = this.state.nome;
    let desc = this.state.descricao;

    if (nome === "") {
      this.notificar("warning", "Nome do formulário é obrigatório!");
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios`,
        { nome, desc },
        this.props.parameters()
      )
      .then((response) => {
        let id = response.data.id;

        this.notificar("success", "Formulário cadastrado com sucesso!");

        setTimeout(() => {
          this.props.history.push(`/formularios-visualizar?id=${id}`);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  async gerarLinkFormularioExterno(formulario) {
    let url = window.location.href;
    let urlSemParametros = url.split("?")[0];
    let partesUrl = urlSemParametros.split("/");
    partesUrl.pop(); // Remove a última parte da URL

    let urlFinal = partesUrl.join("/");

    let data = {
      idForm: formulario.id,
      idAtendimento: null,
      idConsultante: null,
      url: urlFinal,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-externo`,
        data,
        this.props.parameters()
      )
      .then(async (response) => {
        let url = response.data.url;
        this.setState({ url }, () => {
          this.toggleModalGerarLinkFormulario();
          this.notificar("success", "Link gerado com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  toggleModalGerarLinkFormulario() {
    this.setState({
      modalGerarLinkFormulario: !this.state.modalGerarLinkFormulario,
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="formularios" />
          <div className="main-panel">
            <NavBar title="Formularios" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Formulários</h4>
                      <div className="content-btn-novo-item">
                        {" "}
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.toggleModalCadastro();
                          }}
                        >
                          Novo Formulário
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th className="text-center">Data Cadastro</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.formularios.length > 0 &&
                              this.state.formularios.map((form, key) => (
                                <tr key={key}>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {form.id}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {form.nome}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {form.descricao}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      onClick={() =>
                                        this.handleClickVisualizar(form.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {moment(form.dataCadastro).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justifyContentCenter">
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-primary btn-round"
                                          onClick={() =>
                                            this.handleClickVisualizar(form.id)
                                          }
                                          id={`tooltip-editar-convenios-${form.id}`}
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-editar-convenios-${form.id}`}
                                        >
                                          Editar
                                        </UncontrolledTooltip>
                                      </div>
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-secondary btn-round"
                                          onClick={() =>
                                            this.handleClickResponder(form.id)
                                          }
                                          id={`tooltip-responder-convenios-${form.id}`}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-responder-convenios-${form.id}`}
                                        >
                                          Responder
                                        </UncontrolledTooltip>
                                      </div>
                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-warning btn-round"
                                          onClick={() => {
                                            this.props.history.push(
                                              `/formularios-lista-resposta?id=${form.id}`
                                            );
                                          }}
                                          id={`tooltip-visualizar-convenios-${form.id}`}
                                          style={{ color: "white" }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-visualizar-convenios-${form.id}`}
                                        >
                                          Visualizar Respostas
                                        </UncontrolledTooltip>
                                      </div>

                                      <div
                                        style={{ margin: "0 3px" }}
                                        className="text-center"
                                      >
                                        <button
                                          className="btn btn-success btn-round"
                                          onClick={() =>
                                            this.gerarLinkFormularioExterno(
                                              form
                                            )
                                          }
                                          id={`tooltip-link-formularios-${form.id}`}
                                        >
                                          <i className="fa fa-external-link"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-link-formularios-${form.id}`}
                                        >
                                          Gerar Link para resposta externa
                                        </UncontrolledTooltip>
                                      </div>

                                      <div style={{ margin: "0 3px" }}>
                                        <button
                                          className="btn btn-danger btn-round"
                                          onClick={() =>
                                            this.handleClickDeletar(form.id)
                                          }
                                          id={`tooltip-deletar-convenios-${form.id}`}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`tooltip-deletar-convenios-${form.id}`}
                                        >
                                          Deletar
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalDeletar}
          toggle={this.toggleModalDeletar}
        >
          <ModalHeader toggle={this.toggleModalDeletar}>
            Deletar Convênio
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este formulário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalCadastro}
          toggle={this.toggleModalCadastro}
        >
          <ModalHeader toggle={this.toggleModalCadastro}>
            Cadastrar Formulário
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Nome</label>
              <input
                type="text"
                className="form-control"
                value={this.state.nome}
                onChange={(e) => {
                  this.handleChangeInput(e, "nome");
                }}
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <input
                type="text"
                className="form-control"
                value={this.state.descricao}
                onChange={(e) => {
                  this.handleChangeInput(e, "descricao");
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalCadastro}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickCadastrar}
            >
              Cadastrar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalGerarLinkFormulario}
          toggle={this.toggleModalGerarLinkFormulario}
          className="modal-consulta"
        >
          <ModalHeader toggle={this.toggleModalDeletarArquivo}>
            Link Formulário
          </ModalHeader>
          <ModalBody>
            Link para responder externamente o formulário
            <div style={{ paddingTop: "15px" }}>
              URL:{" "}
              <React.Fragment>
                <a
                  href={this.state.url}
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: "#000",
                  }}
                  target="_blank"
                >
                  {this.state.url}
                </a>
                &nbsp;&nbsp;
                <span>
                  <i
                    id="link-formulario"
                    className="fa fa-clipboard"
                    style={{
                      fontSize: "24px",
                      color: "#d1a111",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      navigator.clipboard.writeText(this.state.url);
                      this.notificar("success", "Link copiado com sucesso!");
                    }}
                  />
                </span>
                <UncontrolledTooltip placement="top" target={`link-formulario`}>
                  Copiar Link
                </UncontrolledTooltip>
              </React.Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalGerarLinkFormulario}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Formulario;
