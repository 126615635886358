const storage = require('./storage')
require("dotenv").config();

const isAuthenticated = () => {
  // return true;
  if (getToken()) {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  storage.removeItem("token");
  storage.removeItem("user");
  storage.removeItem("imagem");
  window.location.href = `${process.env.REACT_APP_DEV_DOMAIN}/login`;
};

const login = (token) => {
  storage.setItem("token", token);
};

const getToken = () => {
  return storage.getItem("token");
};

const getUser = () => {
  return JSON.parse(storage.getItem("user"));
};

const isMaster = () => {
  let user = getUser();
  if (user.nivel === 2) {
    return false;
  } else {
    return true;
  }
};

module.exports = { isAuthenticated, logout, login, getToken, getUser, isMaster };
