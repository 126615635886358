import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import storage from '../../storage';

const recaptchaRef = React.createRef();
require("dotenv").config();

class Login extends Component {
  constructor() {
    super();
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      login: "",
      password: "",
      error: false,
    };
  }

  async componentDidMount() {
    let ultimoLoginStr = storage.getItem("ultimoLogin");

    if (ultimoLoginStr !== null && ultimoLoginStr !== "") {
      let ultimoLogin = JSON.parse(ultimoLoginStr);
      this.setState({ login: ultimoLogin.login });
    }
  }

  handleChangeInput(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let localLogin = this.state.login;
    let toSend = {
      login: localLogin,
      password: this.state.password,
    };

    await axios
      .post(`${this.props.getBaseUrlApi()}/login`, toSend, this.props.parameters())
      .then(async (res) => {
        storage.setItem("token", res.data.token);
        storage.setItem("user", JSON.stringify(res.data.user));
        storage.setItem("tema", JSON.stringify(res.data.tema));
        this.props.history.push("/atendimentos");
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.notificar("warning", error.response.data.erro);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        {/* <ReCAPTCHA
          sitekey="6LeVZlIdAAAAADrYUVCJfQZrT9ObP4b40xJTT2V1"
          onChange={(teste) => console.log("aaa", teste)}
          size="invisible"
          ref={recaptchaRef}
          theme="dark"
        /> */}

        <React.Fragment>
          <div
            id="login_container"
            style={{ backgroundImage: `url("./images/inicial.jpg")` }}
          >
            <div
              className="filter"
              style={{
                position: "absolute",
                backgroundColor: "black",
                width: "100%",
                height: "100%",
                opacity: "0.8",
              }}
            ></div>
            <div className="container">
              <div
                className="card-login card-container-login background-card"
                style={{ backgroundColor: "transparent" }}
              >
                <center>
                  <div style={{ height: "75px" }}></div>
                </center>
                <p id="profile-name" className="profile-name-card"></p>
                <form
                  className="form-signin"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.handleSubmit();
                  }}
                >
                  <input
                    type="text"
                    id="login"
                    className="form-control"
                    placeholder="LOGIN"
                    onChange={(e) => this.handleChangeInput(e)}
                    value={this.state.login}
                    required
                    autofocus
                    style={{ backgroundColor: "transparent" }}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                  />
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="SENHA"
                    onChange={(e) => this.handleChangeInput(e)}
                    value={this.state.password}
                    required
                    style={{ backgroundColor: "transparent" }}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                  />
                  <button
                    className="btn btn-signin-sac btn-lg btn-primary btn-block btn-signin"
                    style={{
                      "background-color": "#d40e0e!important",
                      borderRadius: "0",
                      "background-image":
                        "linear-gradient(to right,#ef8157, #f06c3a, #ff5716)",
                      cursor: "pointer",
                      height: "50px",
                      color: "#333",
                    }}
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    ACESSAR
                  </button>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Login;
