import React, { Component } from "react";
import { SideBar, NavBar } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { cpfMask } from "../../mask";

class Consultante extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.notificar = this.notificar.bind(this);
    this.handleClickVisualizar = this.handleClickVisualizar.bind(this);
    this.toggleModalDeletar = this.toggleModalDeletar.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleConfirmDeletar = this.handleConfirmDeletar.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.buscarConsultantes = this.buscarConsultantes.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.abrirWhatsapp = this.abrirWhatsapp.bind(this);

    this.state = {
      consultantes: [],
      modalDeletar: false,
      idConsultanteDeletar: null,
      descBuscar: "",
      cpfBuscar: "",
      tema: props.getTema(),
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/consultantes`,
        this.props.parameters()
      )
      .then((response) => {
        let consultantes = response.data.consultantes;
        for (let consultante of consultantes) {
          consultante.celular = consultante.celular.replace(/\D/g, "");
        }

        this.setState({ consultantes });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickVisualizar(id) {
    this.props.history.push(`/consultantes-visualizar?id=${id}`);
  }

  toggleModalDeletar() {
    this.setState({ modalDeletar: !this.state.modalDeletar });
  }

  handleClickDeletar(id) {
    this.setState(
      {
        idConsultanteDeletar: id,
      },
      () => {
        this.toggleModalDeletar();
      }
    );
  }

  async handleConfirmDeletar() {
    let id = this.state.idConsultanteDeletar;

    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/consultantes/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ idConsultanteDeletar: null }, async () => {
          this.toggleModalDeletar();
          await this.load();
          this.notificar("success", "Paciente deletado com sucesso!");
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  handleChangeInput(value, tipo) {
    if (tipo === "cpfBuscar") {
      value = cpfMask(value);
    }

    this.setState({ [tipo]: value });
  }

  async buscarConsultantes() {
    let desc = this.state.descBuscar;
    let cpf = this.state.cpfBuscar;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/consultantes-buscar`,
        { desc, cpf },
        this.props.parameters()
      )
      .then((response) => {
        let consultantes = response.data.consultantes;
        for (let consultante of consultantes) {
          consultante.celular = consultante.celular.replace(/\D/g, "");
        }
        this.setState({ consultantes });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  limparFiltros() {
    this.setState(
      {
        descBuscar: "",
        cpfBuscar: "",
      },
      () => {
        this.buscarConsultantes();
      }
    );
  }

  abrirWhatsapp(numero) {
    if (numero !== "") {
      window
        .open(`https://api.whatsapp.com/send?phone=${numero}`, "_blank")
        .focus();
    } else {
      this.notificar(
        "warning",
        "Favor cadastrar um celular para o consultante!"
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="consultantes" />
          <div className="main-panel">
            <NavBar title="Pacientes" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{ position: "relative" }}
                    >
                      <h4 className="card-title"> Lista de Pacientes</h4>
                      <div className="content-btn-novo-item">
                        {" "}
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.props.history.push("/consultantes-novo");
                          }}
                        >
                          Novo Paciente
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row float-left w-100">
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>Nome</label>
                            <input
                              type="text"
                              className={`form-control`}
                              placeholder="Descrição"
                              value={this.state.descBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "descBuscar"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3 pr-1">
                          <div className="form-group">
                            <label>CPF</label>
                            <input
                              type="text"
                              className={`form-control`}
                              placeholder="CPF"
                              value={this.state.cpfBuscar}
                              onChange={(e) =>
                                this.handleChangeInput(
                                  e.target.value,
                                  "cpfBuscar"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-3 pr-1"
                          style={{ display: "flex", marginTop: "20px" }}
                        >
                          <div className="form-group" style={{ float: "left" }}>
                            <label></label>
                            <button
                              className="btn btn-secondary btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.buscarConsultantes()}
                              id={`tooltip-filtrar-consultante`}
                            >
                              <i
                                className="fa fa-filter"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-filtrar-consultante`}
                            >
                              Filtrar
                            </UncontrolledTooltip>
                          </div>
                          <div className="form-group">
                            <label></label>
                            <button
                              className="btn btn-danger btn-sm"
                              style={{
                                padding: "0.275rem 0.55rem",
                                margin: "10px 5px 0px",
                              }}
                              onClick={() => this.limparFiltros()}
                              id={`tooltip-limpar-filtro-consultante`}
                            >
                              <i
                                className="fa fa-eraser"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-limpar-filtro-consultante`}
                            >
                              Limpar filtro
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table">
                          <thead
                            className="text-primary"
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  "color",
                                  this.state.tema.textoPrincipal,
                                  "important"
                                );
                              }
                            }}
                          >
                            <th>Nome</th>
                            <th>Email</th>
                            <th>CPF</th>
                            <th className="text-center">Ações</th>
                          </thead>
                          <tbody>
                            {this.state.consultantes.length > 0 &&
                              this.state.consultantes.map(
                                (consultante, key) => (
                                  <tr key={key}>
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            consultante.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {consultante.desc}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            consultante.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {consultante.email}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          this.handleClickVisualizar(
                                            consultante.id
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {consultante.cpf}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justifyContentCenter">
                                        <div
                                          style={{ margin: "0 3px" }}
                                          className="text-center"
                                        >
                                          <button
                                            className="btn btn-primary btn-round"
                                            onClick={() =>
                                              this.handleClickVisualizar(
                                                consultante.id
                                              )
                                            }
                                            id={`tooltip-editar-consultante-${consultante.id}`}
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-editar-consultante-${consultante.id}`}
                                          >
                                            Editar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-success btn-round"
                                            onClick={() =>
                                              this.abrirWhatsapp(
                                                consultante.celular
                                              )
                                            }
                                            id={`tooltip-mensagem-consultante-${consultante.id}`}
                                          >
                                            <i className="fa fa-whatsapp"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-mensagem-consultante-${consultante.id}`}
                                          >
                                            Enviar mensagem
                                          </UncontrolledTooltip>
                                        </div>
                                        <div style={{ margin: "0 3px" }}>
                                          <button
                                            className="btn btn-danger btn-round"
                                            onClick={() =>
                                              this.handleClickDeletar(
                                                consultante.id
                                              )
                                            }
                                            id={`tooltip-deletar-consultante-${consultante.id}`}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-deletar-consultante-${consultante.id}`}
                                          >
                                            Deletar
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalDeletar}
          toggle={this.toggleModalDeletar}
        >
          <ModalHeader toggle={this.toggleModalDeletar}>
            Deletar Paciente
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este paciente?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalDeletar}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Consultante;
