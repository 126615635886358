import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {
  Login,
  Home,
  Atendente,
  AtendenteNovo,
  FormaPagamento,
  FormaPagamentoNovo,
  MotivoCancelamentoNovo,
  MotivoCancelamento,
  ConsultanteNovo,
  Consultante,
  Atividade,
  AtividadeNova,
  ConvenioNovo,
  Convenio,
  Configuracao,
  AtendimentoNovo,
  Atendimento,
  AtendimentoVisualizar,
  Dashboard,
  Formulario,
  FormularioNovo,
  FormularioResponder,
  FormularioResposta,
  FormularioListaResposta,
  RelatorioFinanceiro,
  Template,
  TemplateNovo,
  TemplateImpressao,
  TemplateListaResposta,
  FormularioResponderEditar,
  FormularioResponderExterno
} from "./pages";
import { isAuthenticated, getToken, logout, getUser, isMaster } from "./auth";
import { ToastProvider } from "react-awesome-toasts";
import storage from "./storage";
require("dotenv").config();

const getParametersApi = () => {
  return {
    headers: {
      "x-access-token": getToken(),
      "x-access-comp": process.env.REACT_APP_DEV_ID_EMPRESA,
    },
  };
};

const getBaseUrlApi = () => {
  let urlAtual = window.location.origin;

  if (urlAtual.search("localhost") !== -1) {
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  }
  let urlEnv = process.env.REACT_APP_DEV_API_URL.split(":")[1];
  urlEnv = urlEnv.replace("//", "").trim();

  if (urlAtual.search(urlEnv) !== -1) {
    return process.env.REACT_APP_DEV_API_URL;
  } else {
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  }
};

const detectIE = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    return true;
  }
  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    return true;
  }
  return false;
};

const PrivateRoute = ({
  component: Component,
  itemActive,
  titulo,
  icone,
  ...rest
}) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          isMaster() ? (
            <div>
              <Component
                {...props}
                parameters={getParametersApi}
                user={getUser}
                getBaseUrlApi={() => getBaseUrlApi()}
                getTema={() => {
                  return JSON.parse(storage.getItem("tema"));
                }}
                logout={logout}
                getDomain={() => process.env.REACT_APP_DEV_DOMAIN}

              />
            </div>
          ) : (
            <div>
              <center>
                <h3>
                  404! <br />
                  essa página não existe
                </h3>
              </center>
            </div>
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
const PrivateRoute1 = ({
  component: Component,
  itemActive,
  titulo,
  icone,
  ...rest
}) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          true ? (
            <div>
              <Component
                {...props}
                parameters={getParametersApi}
                user={getUser}
                getBaseUrlApi={() => getBaseUrlApi()}
                getTema={() => {
                  return JSON.parse(storage.getItem("tema"));
                }}
                logout={logout}
                getDomain={() => process.env.REACT_APP_DEV_DOMAIN}
              />
            </div>
          ) : (
            <div>
              <center>
                <h3>
                  404! <br />
                  essa página não existe
                </h3>
              </center>
            </div>
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
const PrivateRoute2 = ({
  component: Component,
  itemActive,
  titulo,
  icone,
  ...rest
}) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        true ? (
          true ? (
            <div>
              <Component
                {...props}
                parameters={getParametersApi}
                user={getUser}
                getBaseUrlApi={() => getBaseUrlApi()}
                getTema={() => {
                  return JSON.parse(storage.getItem("tema"));
                }}
                logout={logout}
                getDomain={() => process.env.REACT_APP_DEV_DOMAIN}
              />
            </div>
          ) : (
            <div>
              <center>
                <h3>
                  404! <br />
                  essa página não existe
                </h3>
              </center>
            </div>
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
                rel="noopener noreferrer"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        ) : (
          <Component
            {...props}
            parameters={getParametersApi}
            getBaseUrlApi={getBaseUrlApi}
          />
        )
      }
    />
  );
};

const LogoutRoute = ({ component: Component, ...rest }) => {
  logout();
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )}
    />
  );
};

const NoMatch = ({ location }) => (
  <div>
    <center>
      <h3>
        404! <br />
        essa página não existe
      </h3>
    </center>
  </div>
);

const App = (props) => {
  return (
    <ToastProvider timeout={3000} position={"bottom-left"}>
      <BrowserRouter basename={process.env.REACT_APP_DEV_DOMAIN}>
        <Switch>
          <LoginRoute path="/login" exact component={Login} />
          <LogoutRoute path="/logout" exact component={Login} />
          <PrivateRoute path="/" exact component={Home} />
          <PrivateRoute path="/atendentes" exact component={Atendente} />

          <PrivateRoute
            path="/atendentes-novo"
            exact
            component={AtendenteNovo}
          />

          <PrivateRoute
            path="/atendentes-visualizar"
            exact
            component={AtendenteNovo}
          />

          <PrivateRoute
            path="/formas-pagamento"
            exact
            component={FormaPagamento}
          />

          <PrivateRoute
            path="/formas-pagamento-nova"
            exact
            component={FormaPagamentoNovo}
          />

          <PrivateRoute
            path="/formas-pagamento-visualizar"
            exact
            component={FormaPagamentoNovo}
          />

          <PrivateRoute
            path="/motivos-cancelamento-novo"
            exact
            component={MotivoCancelamentoNovo}
          />

          <PrivateRoute
            path="/motivos-cancelamento-visualizar"
            exact
            component={MotivoCancelamentoNovo}
          />

          <PrivateRoute
            path="/motivos-cancelamento"
            exact
            component={MotivoCancelamento}
          />

          <PrivateRoute path="/consultantes" exact component={Consultante} />

          <PrivateRoute path="/atividades" exact component={Atividade} />

          <PrivateRoute
            path="/consultantes-novo"
            exact
            component={ConsultanteNovo}
          />

          <PrivateRoute
            path="/consultantes-visualizar"
            exact
            component={ConsultanteNovo}
          />

          <PrivateRoute
            path="/atividades-nova"
            exact
            component={AtividadeNova}
          />

          <PrivateRoute
            path="/atividades-visualizar"
            exact
            component={AtividadeNova}
          />

          <PrivateRoute path="/convenios-novo" exact component={ConvenioNovo} />
          <PrivateRoute
            path="/convenios-visualizar"
            exact
            component={ConvenioNovo}
          />
          <PrivateRoute path="/convenios" exact component={Convenio} />

          <PrivateRoute1 path="/configuracoes" exact component={Configuracao} />

          <PrivateRoute1
            path="/atendimentos-novo"
            exact
            component={AtendimentoNovo}
          />
          <PrivateRoute1 path="/atendimentos" exact component={Atendimento} />
          <PrivateRoute1
            path="/atendimentos-visualizar"
            exact
            component={AtendimentoVisualizar}
          />

          <PrivateRoute1 path="/home" exact component={Home} />
          <PrivateRoute1 path="/dashboard" exact component={Dashboard} />

          <PrivateRoute1 path="/formularios" exact component={Formulario} />
          <PrivateRoute1 path="/formularios-visualizar" exact component={FormularioNovo} />

          <PrivateRoute1 path="/formularios-responder" exact component={FormularioResponder} />
          <PrivateRoute1 path="/formularios-responder-editar" exact component={FormularioResponderEditar} />
          <PrivateRoute1 path="/formularios-resposta" exact component={FormularioResposta} />

          <PrivateRoute1 path="/formularios-lista-resposta" exact component={FormularioListaResposta} />

          <PrivateRoute1 path="/templates-lista-resposta" exact component={TemplateListaResposta} />


          <PrivateRoute1 path="/relatorios-financeiro" exact component={RelatorioFinanceiro} />

          <PrivateRoute1 path="/templates" exact component={Template} />
          
          <PrivateRoute1 path="/templates-novo" exact component={TemplateNovo} />

          <PrivateRoute1 path="/templates-impressao" exact component={TemplateImpressao} />

          <PrivateRoute2 path="/responder-formulario" exact component={FormularioResponderExterno} />


          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
