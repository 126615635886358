import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";

class FormularioResponderEditar extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.buscarFormulario = this.buscarFormulario.bind(this);
    this.handleChangeInputTexto = this.handleChangeInputTexto.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeInputRadio = this.handleChangeInputRadio.bind(this);
    this.cadastrarResposta = this.cadastrarResposta.bind(this);

    this.state = {
      id: null,
      idResposta: null,
      formulario: null,
      actionPage: "CADASTRAR",
      idAtendimento: null,
      idConsultante: null,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;
    let idResposta = queryString.parse(this.props.location.search).resposta;
    let idAtendimento = queryString.parse(
      this.props.location.search
    ).atendimento;
    let idConsultante = queryString.parse(
      this.props.location.search
    ).consultante;


    if (id !== undefined || idResposta === undefined) {
      await this.buscarFormulario(id, idResposta);

      if (idAtendimento !== undefined && idConsultante !== undefined) {
        this.setState({
          idAtendimento,
          idConsultante,
        });
      }
    } else {
      this.props.history.push("/formularios");
      return false;
    }
  }

  handleChangeInputTexto(idPergunta, e) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas)
      if (pergunta.id === idPergunta) {
        pergunta.resposta.resposta = e.target.value;
      }

    this.setState({ formulario });
  }

  handleCheck(idPergunta, idOpcao) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas) {
      if (pergunta.id === idPergunta) {
        for (let opcao of pergunta.opcoes) {
          if (opcao.id === idOpcao) {
            opcao.selecionado = !opcao.selecionado;
          }
        }
      }
    }

    this.setState({ formulario });
  }

  handleChangeInputRadio(idOpcao, idPergunta) {
    let formulario = this.state.formulario;

    for (let pergunta of formulario.perguntas) {
      if (pergunta.id === idPergunta) {
        pergunta.resposta = {
          id: Math.random(),
          idOpcao: idOpcao,
          resposta: "",
        };
      }
    }

    this.setState({ formulario });
  }

  async buscarFormulario(id, idResposta) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/buscar-formulario-resposta`,
        {id, idResposta},
        this.props.parameters()
      )
      .then((response) => {
        let formulario = response.data.formulario;
        let listaRespostas = response.data.listaRespostas;
        if (formulario === null) {
          return this.props.history.push("/formularios");
        }

        for (let pergunta of formulario.perguntas) {
          let respostaNova = null;

          if (pergunta.tipo === 0 || pergunta.tipo === 1) {
            respostaNova = {
              id: Math.random(),
              idOpcao: null,
              resposta: "",
            };

            pergunta.resposta = respostaNova;
          } else {
            if (pergunta.tipo === 2) {
              respostaNova = {
                id: Math.random(),
                idOpcao: null,
                resposta: "",
              };

              pergunta.resposta = respostaNova;
            }
            if (pergunta.tipo === 3) {
              for (let opcao of pergunta.opcoes) {
                opcao.selecionado = false;
              }
            }
          }
        }

        //AQUI PARTE NOVA
        for (let pergunta of formulario.perguntas) {
          let respostaFind = listaRespostas.find(
            (it) => it.idPergunta === pergunta.id
          );
          if (respostaFind !== undefined) {
            if (pergunta.tipo === 0 || pergunta.tipo === 1) {
              pergunta.resposta.resposta = respostaFind.resposta;
            } else if (pergunta.tipo === 2) {
              pergunta.resposta.idOpcao = respostaFind.idOpcao;
            } else if (pergunta.tipo === 3) {
              for (let opcao of pergunta.opcoes) {
                if (respostaFind.idOpcao.includes(opcao.id)) {
                  opcao.selecionado = true;
                }
              }
            }
          }
        }

        this.setState({ id, formulario, idResposta });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  async cadastrarResposta() {
    let formulario = this.state.formulario;
    let perguntas = formulario.perguntas;

    for (let pergunta of perguntas) {
      if (pergunta.tipo === 0 || pergunta.tipo === 1) {
        if (pergunta.obrigatoria) {
          if (pergunta.resposta.resposta === "") {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }

          if (pergunta.resposta.resposta.length > 1000) {
            this.notificar(
              "warning",
              "A resposta deve ter no máximo 1000 caracteres"
            );
            return false;
          }
        }
      } else if (pergunta.tipo === 2) {
        if (pergunta.obrigatoria) {
          if (pergunta.resposta.idOpcao === null) {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }
        }
      } else if (pergunta.tipo === 3) {
        if (pergunta.obrigatoria) {
          if (pergunta.opcoes.filter((op) => op.selecionado).length === 0) {
            this.notificar("warning", "Preencha todos os campos obrigatórios!");
            return false;
          }
        }
      }
    }

    let idAtendimento = this.state.idAtendimento;
    let idConsultante = this.state.idConsultante;

    let data = {
      idForm: formulario.id,
      perguntas,
      idAtendimento,
      idConsultante,
      idResposta: this.state.idResposta
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/formularios-resposta`,
        data,
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Resposta salva com sucesso!");
        setTimeout(() => {
          if (idAtendimento !== null) {
            this.props.history.push(
              `/atendimentos-visualizar?id=${idAtendimento}`
            );
          } else {
            this.props.history.push(
              `/formularios-resposta?id=${response.data.idResposta}`
            );
          }
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="formularios" />
          <div className="main-panel">
            <NavBar title="Formulários Dinâmicos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header card-header-pergunta d-flex">
                      <div className="col-12 no-padding">
                        <h5 className="card-title">
                          {this.state.formulario !== null && (
                            <React.Fragment>
                              <span>{this.state.formulario.nome}</span>
                            </React.Fragment>
                          )}
                        </h5>
                        <div className="">
                          {this.state.formulario !== null && (
                            <div>{this.state.formulario.descricao}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /// */}

              {this.state.formulario !== null &&
                this.state.formulario.perguntas.map((pergunta) => (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-user card-form">
                          <div
                            className="card-header d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="col-12 no-padding">
                              <h5 className="card-title">
                                {pergunta.titulo}{" "}
                                {pergunta.obrigatoria && (
                                  <span
                                    style={{
                                      color: "#dc0c0c",
                                      fontSize: "18px",
                                    }}
                                  >
                                    *
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>

                          <div className="card-body card-form">
                            {pergunta.tipo == 0 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  onChange={(e) => {
                                    this.handleChangeInputTexto(pergunta.id, e);
                                  }}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 1 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  onChange={(e) => {
                                    this.handleChangeInputTexto(pergunta.id, e);
                                  }}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 2 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="radio"
                                      value={op.id}
                                      // disabled={true}
                                      checked={
                                        pergunta.resposta.idOpcao === op.id
                                      }
                                      name={`radio-${pergunta.id}`}
                                      onChange={(e) => {
                                        this.handleChangeInputRadio(
                                          op.id,
                                          pergunta.id
                                        );
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    {op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {pergunta.tipo == 3 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={op.opcao}
                                      checked={op.selecionado}
                                      onChange={() => {
                                        this.handleCheck(pergunta.id, op.id);
                                      }}
                                    />
                                    &nbsp;&nbsp;{op.opcao}
                                    {/* {" "}
                                    {op.outro && (
                                      <React.Fragment>
                                        <div className="col-2">
                                          &nbsp;&nbsp;
                                          <input
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                      </React.Fragment>
                                    )} */}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

              <div>
                <button
                  className="btn btn-info"
                  onClick={() => {
                    this.cadastrarResposta();
                  }}
                >
                  RESPONDER
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioResponderEditar;
