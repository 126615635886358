import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";

class FormularioResposta extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.buscarFormulario = this.buscarFormulario.bind(this);

    this.state = {
      id: null,
      formulario: null,
      actionPage: "CADASTRAR",
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarFormulario(id);
    } else {
      this.props.history.push("/formularios");
      return false;
    }
  }

  async buscarFormulario(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/formularios-resposta/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let formulario = response.data.formulario;
        if(formulario === null){
          return this.props.history.push('/formularios')
        }
        this.setState({ id, formulario });
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
          if (error.response.status === 401) {
            this.props.logout();
          }
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="wrapper">
          <SideBar itemActive="formularios" />
          <div className="main-panel">
            <NavBar title="Formulários Dinâmicos" />

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-user">
                    <div className="card-header card-header-pergunta d-flex">
                      <div className="col-12 no-padding">
                        <h5 className="card-title">
                          {this.state.formulario !== null && (
                            <React.Fragment>
                              <span>{this.state.formulario.nome}</span>
                            </React.Fragment>
                          )}
                        </h5>
                        <div className="">
                          {this.state.formulario !== null && (
                            <div>{this.state.formulario.descricao}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.formulario !== null &&
                this.state.formulario.perguntas.map((pergunta) => (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-user card-form">
                          <div
                            className="card-header d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <div className="col-12 no-padding">
                              <h5 className="card-title">
                                {pergunta.titulo}{" "}
                                {pergunta.obrigatoria && (
                                  <span
                                    style={{
                                      color: "#dc0c0c",
                                      fontSize: "18px",
                                    }}
                                  >
                                    *
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>

                          <div className="card-body card-form">
                            {pergunta.tipo == 0 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  disabled={true}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 1 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={pergunta.resposta.resposta}
                                  disabled={true}
                                />
                              </div>
                            )}
                            {pergunta.tipo == 2 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="radio"
                                      value={op.id}
                                      disabled={true}
                                      checked={
                                        pergunta.resposta.idOpcao === op.id
                                      }
                                      name={`radio-${pergunta.id}`}
                                    />
                                    &nbsp;&nbsp;
                                    {op.opcao}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {pergunta.tipo == 3 && (
                              <ul style={{ padding: 0 }}>
                                {pergunta.opcoes.map((op) => (
                                  <li
                                    key={op.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      type="checkbox"
                                      value={op.opcao}
                                      checked={op.selecionado}
                                      disabled={true}
                                    />
                                    &nbsp;&nbsp;{op.opcao}
                                    {/* {" "}
                                    {op.outro && (
                                      <React.Fragment>
                                        <div className="col-2">
                                          &nbsp;&nbsp;
                                          <input
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                      </React.Fragment>
                                    )} */}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioResposta;
