import React, { Component } from "react";

class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }

  async componentDidMount() {
    this.props.history.push("/atendimentos");
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default Home;
